<div class="planning-container">
  <!-- Header -->
  <header class="header">
    <div class="title">
      <h1>Werfplanning {{company.name}}</h1>
      <span class="date">datum: {{dagWerfPlanning.isoDate | date:'dd/MM/yyyy'}}</span>
    </div>
    <div class="summary">
      <p>Totaal: {{dagWerfPlanning.totalSelectedArbeiders + dagWerfPlanning.totalSelectedChauffeurs + dagWerfPlanning.totalSelectedOnderaannemers}}</p>
      <p>Totaal Arbeiders: {{dagWerfPlanning.totalSelectedArbeiders}}</p>
      <p>Totaal Chauffeurs: {{dagWerfPlanning.totalSelectedChauffeurs}}</p>
      <p>Totaal Onderaannemers: {{dagWerfPlanning.totalSelectedOnderaannemers}}</p>
    </div>
  </header>

  <!-- Absent Workers Banner -->
  <div class="absent-banner" *ngIf="dagWerfPlanning.verlofArbeiders?.length">
    <div class="arbeiders-counter">
      <i style="color:darkorange !important;" class="fas fa-user"></i>
      <span style="color:darkorange !important;">{{dagWerfPlanning.verlofArbeiders.length}}</span>
    </div>
    <strong style="  align-self: flex-start;">Verlof:</strong>
    {{convertUsersToUserNames(dagWerfPlanning.verlofArbeiders)}}
  </div>

  <!-- Construction Sites Grid -->
  <div class="werf-grid">
    <div *ngFor="let planning of dagWerfPlanning.werfPlanningen" class="werf-card">
      <div class="werf-header">
        <h4 style="color:white;margin-bottom: 0">{{functionService.displayWerf(planning.werf) }}</h4>
        <div class="arbeiders-counter">
          <i class="fas fa-user"></i>
          <span>{{planning.selectedArbeiders.length}}</span>
        </div>
      </div>

      <div class="werf-content">
        <div class="workers">
          <div class="tag-container">
            <span *ngFor="let arbeider of planning.selectedArbeiders" class="tag worker-tag">
              {{arbeider.name}} {{arbeider.firmaNaam? '(' + arbeider.firmaNaam + ')' : ''}}
            </span>
          </div>
        </div>

        <div class="instructions" *ngIf="planning.opmerkingAanArbeiders || planning.specialeInstructies">
          <div *ngIf="planning.opmerkingAanArbeiders" class="instruction-box">
            {{planning.opmerkingAanArbeiders}}
          </div>
          <div *ngIf="planning.specialeInstructies" class="instruction-box special">
            {{planning.specialeInstructies}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer>
    Gegenereerd op {{currentDate | date:'dd/MM/yyyy HH:mm'}}
  </footer>
</div>
