import {APP_INITIALIZER, enableProdMode, ErrorHandler, Injector, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbMenuModule,
  NbToastrModule,
  NbDialogModule,
  NbDatepickerModule,
  NbAutocompleteModule,
  NbInputModule,
  NbOptionModule,
  NbSelectModule,
  NbTabsetModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { HttpResponse, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy, NbPasswordAuthStrategyOptions} from "@nebular/auth";
import {environment} from "../environments/environment";
import {PagesModule} from "./pages/pages.module";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import {NbMomentDateModule} from "@nebular/moment";
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {QRCodeModule} from "angularx-qrcode";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {NieuweCountService} from "./services/NieuweCountService";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular";
export let AppInjector: Injector;

registerLocaleData(localeNl);

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Company} from "./models/company";

export interface AuthData {
  company: Company;
  role: string;
  userId: string;
  userName: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
  private readonly STORAGE_KEY = 'auth_data';
  private authDataSubject = new BehaviorSubject<AuthData | null>(this.loadInitialData());

  authData$ = this.authDataSubject.asObservable();

  private loadInitialData(): AuthData | null {
    const storedData = localStorage.getItem(this.STORAGE_KEY);
    return storedData ? JSON.parse(storedData) : null;
  }

  setAuthData(data: AuthData) {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
    this.authDataSubject.next(data);
  }

  getAuthData(): AuthData | null {
    return this.authDataSubject.getValue();
  }

  clearAuthData() {
    localStorage.removeItem(this.STORAGE_KEY);
    this.authDataSubject.next(null);
  }
}


@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatDividerModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireStorageModule,
        NbThemeModule.forRoot({ name: 'default' }),
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NbLayoutModule,
        NbEvaIconsModule,
        NbToastrModule.forRoot(),
        NbDialogModule.forRoot(),
        NbDatepickerModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        NbMomentDateModule,
        NbAuthModule.forRoot({
            strategies: [
                NbPasswordAuthStrategy.setup({
                    name: 'email',
                    token: {
                        class: NbAuthJWTToken,
                        key: 'token',
                      getter(module: string, res: HttpResponse<Object>, options: NbPasswordAuthStrategyOptions) {
                        // Use the app injector to get the service
                        try {
                          const authDataService = AppInjector.get(AuthDataService);

                          const authData = {
                            company: res.body['company_id'],
                            role: res.body['role'],
                            userId: res.body['userid'],
                            userName: res.body['username'],
                            token: res.body['token']['access_token']
                          };
                          authDataService.setAuthData(authData);
                          // Return the token
                        } catch (e) {
                          console.error('Error getting auth data', e);
                        }
                        return res.body['token']['access_token'];
                      },
                    },
                    baseEndpoint: environment.apiURL,
                    login: {
                      endpoint: 'auth.login',
                      method: 'post',
                      // Use empty strings instead of booleans
                      redirect: {
                        success: '/pages',
                        failure: '',
                      },
                      defaultErrors: ['Login failed, please try again.'],
                      defaultMessages: ['Login succcesvol!'],
                    },
                }),
            ],
            forms: {},
        }),
        PagesModule,
        MatButtonModule,
        MatFormFieldModule,
        NbAutocompleteModule,
        NbInputModule,
        NbOptionModule,
        NbSelectModule,
        NbTabsetModule,
        QRCodeModule], providers: [{ provide: LOCALE_ID, useValue: 'nl-NL' },
        NieuweCountService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        }, provideHttpClient(withInterceptorsFromDi())] })



export class AppModule {
  constructor(private injector: Injector) {
  AppInjector = injector;
}
}


