import { Injectable } from '@angular/core';
import {Werf} from "../models/werf";

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionService {

  displayWerf(werf: Werf): string {
    return werf?.naam;
  }
}
