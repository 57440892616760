import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormGroup, FormArray } from '@angular/forms';
import moment from 'moment';
import {CompanyAdmin} from "../../models/companyAdmin";
import {ApiService} from "../../services/api.service";
import {Comment} from "../../models/comment";
import {Router} from "@angular/router";
import {FormService} from "../../services/form.service";
import {Company} from "../../models/company";
import {NbToastrService} from "@nebular/theme";
@Component({
  selector: 'ngx-admin',
  templateUrl: './admin.component.html',
  styleUrls: [ './admin.component.scss']
})

export class AdminComponent implements OnInit {
  loginAccounts: CompanyAdmin[] = [new CompanyAdmin('113707516865cd044e87ef01', 'admindemo'),
    new CompanyAdmin('113707516865cd044e87ef02', 'bjornadminadams'),new CompanyAdmin('113707516865cd044e87ef03', 'bjornadminvermetten'),
    new CompanyAdmin('113707516865cd044e87ef04', 'bjornadminambaro'),new CompanyAdmin('113707516865cd044e87ef05', 'bjornadminhermans'),
    new CompanyAdmin('113707516865cd044e87ef06', 'bjornadminhouwelyckx'),new CompanyAdmin('113707516865cd044e87ef07', 'bjornadminvandenbuverie')]
  public companyList: CompanyAdmin[] = [];
  public isAdmin: boolean = false;
  public fromDate: Date;
  public toDate: Date;
  public isLoaded: boolean = false;
  public totaalPrijs: number = 0;
  public last10Comments: Comment[] = [];
  accountDeletionRequestString: string = '';
  maandSelector: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private router: Router,
    private formService: FormService,
    private toastrService: NbToastrService
  ) {
    this.isAdmin = this.apiService.isAdministratorSelux;
    let start = moment().startOf('month').toDate().toString();
    let end = moment().endOf('month').toDate().toString();
    this.fromDate = new Date(start);
    this.toDate = new Date(end);
    this.maandSelector = this.fromDate.getMonth().toString();

    if(this.isAdmin){
      this.apiService.getCompanysAdmin(this.fromDate.toString(), this.toDate.toString()).subscribe(x => {
        this.companyList = x as CompanyAdmin[];
        for(let company of this.companyList){
          company.totaalPrijs = 0;
          company.gebruikers = company.gebruikers.filter(x => x.name !== 'bjorn massoels');
          company.grondwerkerCount = company.gebruikers.filter(x => x.functieNaam === 'Ploegbaas' || x.functieNaam === 'Arbeider' ).length;
          company.chauffeurCount = company.gebruikers.filter(x => x.functieNaam === 'Chauffeur').length;
          company.onderhoudsTechniekerCount = company.gebruikers.filter(x => x.functieNaam === 'Onderhoudstechnieker').length;
          company.magazijnierCount = company.gebruikers.filter(x => x.functieNaam === 'Magazijnier').length;
          if(company.company?.prijsInstellingen != null){
            if(company.company.prijsInstellingen?.combinatieDagrapportenEnDagboekenModulePrijsPerArbeider !== 0){
              company.totaalPrijs += company.company.prijsInstellingen.combinatieDagrapportenEnDagboekenModulePrijsPerArbeider * company.grondwerkerCount;
            } else {
              if(company.company.prijsInstellingen.dagrapportenModulePrijsPerArbeider !== 0){
                company.totaalPrijs += company.company.prijsInstellingen.dagrapportenModulePrijsPerArbeider * company.grondwerkerCount;
              }
              if(company.company.prijsInstellingen.dagboekenModulePrijsPerArbeider !== 0){
                company.totaalPrijs += company.company.prijsInstellingen.dagboekenModulePrijsPerArbeider * company.grondwerkerCount;
              }
            }
            if(company.company.prijsInstellingen?.gewoneTransportModulePrijsPerChauffeur !== 0){
              company.totaalPrijs += company.company.prijsInstellingen.gewoneTransportModulePrijsPerChauffeur * company.chauffeurCount;
            }
            if(company.company.prijsInstellingen?.reparatieModulePrijsPerOnderhoudstechnieker !== 0){
              company.totaalPrijs += company.company.prijsInstellingen.reparatieModulePrijsPerOnderhoudstechnieker * company.onderhoudsTechniekerCount;
            }
            if(company.company.prijsInstellingen?.magazijnierModulePrijsPerMagazijnier !== 0){
              company.totaalPrijs += company.company.prijsInstellingen.magazijnierModulePrijsPerMagazijnier * company.magazijnierCount;
            }
            if(company.company.prijsInstellingen?.verlofAanvragenModulePrijsPerGebruiker !== 0){
              company.totaalPrijs += company.company.prijsInstellingen.verlofAanvragenModulePrijsPerGebruiker *
                (company.grondwerkerCount + company.chauffeurCount);
            }
            if(company.company.prijsInstellingen.ovamModulePrijsPerIdentificatieFormulier !== 0){
              company.totaalPrijs += company.company.prijsInstellingen.ovamModulePrijsPerIdentificatieFormulier *
                company.edigdsOfMonthCount;
            }
            company.company.grondwerkerCount = company.grondwerkerCount;
            company.company.chauffeurCount = company.chauffeurCount;
            company.company.onderhoudsTechniekerCount = company.onderhoudsTechniekerCount;
            company.company.magazijnierCount = company.magazijnierCount;
            company.company.edigdsOfMonthCount = company.edigdsOfMonthCount;
            this.totaalPrijs += company.totaalPrijs;
          }
        }
        this.accountDeletionRequestString = this.companyList.find(x => x.company._id === '113707516865cd044e87ef01').company.accountDeletionRequests.join(' , ');
        this.isLoaded = true;
      });
      this.apiService.getCommentsByWorkerOnCompany().subscribe(x => {
        this.last10Comments = x as Comment[];
        console.log(this.last10Comments);
        for(let comment of this.last10Comments){
          comment.createdDate = new Date(comment.createdDate);
        }
      });
    }
  }

  async ngOnInit() {
  }

  async sendInvoice(company: CompanyAdmin) {
    if(this.maandSelector.value !== this.fromDate.getMonth().toString()){
      this.fromDate.setMonth(+this.maandSelector);
      let start = moment(this.fromDate.toDateString()).startOf('month').toDate().toString();
      let end = moment(this.fromDate.toDateString()).endOf('month').toDate().toString();
      this.fromDate = new Date(start);
      this.toDate = new Date(end);
    }
    if(company.company.email != null){
      await this.apiService.sendInvoice(company.company, this.fromDate.toString(), this.toDate.toString()).subscribe(x => {

      });
    } else {
      this.toastrService.show('Er is geen email adres gekend voor deze klant ingesteld !!', 'Email adres onbekend', {status: 'warning'});
    }
  }

  login(companyAdmin: CompanyAdmin){ /*
    let companyLogin = this.loginAccounts.find(x => x.companyId === companyAdmin.company._id);
    this.authService.logout('email');
    this.authService.authenticate('email', {email: companyLogin.loginName, password: 'test1234'})
    this.authService.refreshToken('email');
    this.router.navigate(['/pages/dagrapporten']*/
  }

  NullToEmptyStr(input: number) {
    if(input !== 0){
      return input;
    } else {
      return '';
    }
  }

  goToEditCompany(company: Company) {
    this.formService.company = company;
    this.router.navigate(['/pages/admin/edit-company']);
  }
}
