import {environment} from "../../environments/environment";
import {HostListener, Injectable, OnDestroy} from "@angular/core";
import {io, Socket} from "socket.io-client";
import {BehaviorSubject} from "rxjs";
import {Machine} from "../models/machine";
import {Werf} from "../models/werf";
import {Materiaal} from "../models/materiaal";
import {User} from "../models/user";
import {Werkzaamheid} from "../models/werkzaamheid";
import {Company} from "../models/company";
import {FormService} from "./form.service";
@Injectable({
  providedIn: 'root'
})
export class DataSyncService implements OnDestroy {
  private socket: Socket;
  public lastFetchAllDataTimestamp;
  private lastSyncTimestamp;
  public machinesSubject = new BehaviorSubject<Machine[]>([]);
  public wervenSubject = new BehaviorSubject<Werf[]>([]);
  public materialenSubject = new BehaviorSubject<Materiaal[]>([]);
  public laadEnLosplaatsenSubject: BehaviorSubject<any[]> = new BehaviorSubject<Werf[]>([]);
  public arbeidersSubject = new BehaviorSubject<User[]>([]);
  public ploegbazenSubject = new BehaviorSubject<User[]>([]);
  public onderaannemersSubject = new BehaviorSubject<User[]>([]);
  public werkzaamhedenStringSubject = new BehaviorSubject<string[]>([]);
  public realWerkzaamhedenSubject = new BehaviorSubject<Werkzaamheid[]>([]);
  public companySubject = new BehaviorSubject<Company>(null);
  bearerToken: string;
  companyId: string;
  userId: string;
  role: string;

  // Observable streams
  machines$ = this.machinesSubject.asObservable();
  werven$ = this.wervenSubject.asObservable();
  materialen$ = this.materialenSubject.asObservable();
  laadEnLosplaatsen$ = this.laadEnLosplaatsenSubject.asObservable();
  arbeiders$ = this.arbeidersSubject.asObservable();
  ploegbazen$ = this.ploegbazenSubject.asObservable();
  onderaannemers$ = this.onderaannemersSubject.asObservable
  werkzaamhedenString$ = this.werkzaamhedenStringSubject.asObservable();
  realWerkzaamheden$ = this.realWerkzaamhedenSubject.asObservable();
  company$ = this.companySubject.asObservable();

  backendUrl = environment.apiURL.replace('/v1/', '');
  //latest used 5 objecten van alle en 6 van losplaatsen en laadplaatsen

  lastUsedObjects =
    { lastChosenWerven: [],
      lastChosenMachines: [],
      lastChosenMaterialen: [],
      lastChosenLaadEnLosplaatsen: [],
      lastChosenArbeiders: [],
      lastChosenOnderaannemers: [],
      lastChosenWerkzaamheden: []
    };
  constructor( private formService: FormService) {
    this.initializeSocket();
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
  }
  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
    this.socket?.disconnect();
  }

  // Handle app going to background
  @HostListener('window:pause', ['$event'])
  onPause(): void {
    this.lastSyncTimestamp = new Date();
    this.lastSyncTimestamp.setSeconds(this.lastSyncTimestamp.getSeconds() - 1);
    console.log('App paused' + this.lastSyncTimestamp.toDateString());
    this.socket?.disconnect();
  }

  // Handle app returning to foreground
  @HostListener('window:resume', ['$event'])
  onResume(): void {
    this.socket?.connect();
    console.log('App resumed');
  }

  // Handle tab/window visibility changes
  private handleVisibilityChange(): void {
    if (document.hidden) {
      this.onPause();
    } else {
      this.onResume();
    }
  }

  setSocketVariables(userId: string, role: string, companyId: string, bearerToken: string) {
    this.userId = userId;
    this.role = role;
    this.companyId = companyId;
    this.bearerToken = bearerToken;
  }

  public async initializeSocket() {

    //normaal niet nodig
    while(this.companyId == null){
      await this.delay(50);
    }


    this.socket = io(this.backendUrl, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 10000,
      // Add these options for better mobile handling
      forceNew: true,
      autoConnect: true
    });

    this.setupSocketListeners();

    this.socket.on('connect', async () => {
      console.log('in socket connected')
      if (this.companyId) {
        this.joinCompanyRoom();
      }
      //first init socket check
      if(this.lastSyncTimestamp == null || this.lastFetchAllDataTimestamp == null){
        this.lastSyncTimestamp = new Date();
        this.lastFetchAllDataTimestamp = new Date();
      } else {
        // ontgrendelen van de gsm
        console.log('data fetch all date on reconnect' + this.lastFetchAllDataTimestamp.toDateString());

        // na 48 uur alle objecten binnenhalen
        if(new Date().getTime() - this.lastFetchAllDataTimestamp.getTime() > 1000 * 60 * 60 * 24 * 2){
          this.fetchInitialData();
        } else {
          this.checkMissedUpdates();
        }
      }
    });
  }
  private joinCompanyRoom() {
    if (this.socket && this.companyId) {
      this.socket.emit('join-company', this.companyId);
      console.log(`Joined company room: ${this.companyId}`);
    }
  }

  private setupSocketListeners() {
   this.socket.on('objects-update', (update) => {
      console.log('Received socket update:', update);
      this.handleUpdate(update);
    })

    this.socket.on('objects-update-company-web', (update) => {
      console.log('Received socket update company:', update);
      this.updateCompany(this.companySubject, update);
    })

    this.socket.on('reconnect', () => {
      console.log('Socket reconnected');
      if (this.companyId) {
        this.joinCompanyRoom();
      }
      console.log('voor missed updates')
    });
  }

  private handleUpdate(update: { type: string; action: string; data: any }) {
    //COMPANY GEBEURD APRT
    switch (update.type) {
      case 'machines':
        this.updateCollection(this.machinesSubject, update);
        break;
      case 'werven':
        this.updateCollection(this.wervenSubject, update);
        this.updateCollection(this.laadEnLosplaatsenSubject, {action: update.action, data: update.data, type: 'laadEnLosplaatsen'});
        break;
      case 'materialen':
        this.updateCollection(this.materialenSubject, update);
        break;
      case 'laadEnLosplaatsen':
        this.updateCollection(this.laadEnLosplaatsenSubject, update);
        break;
      case 'arbeiders':
        this.updateCollection(this.arbeidersSubject, update);
        if(update?.data?.voorkeurArbeiders && update.data.voorkeurArbeiders.length > 0){
          this.updateCollection(this.ploegbazenSubject, update);
        }
        /*if(update?.data?._id === this.authService.userId){
          let previousVoorkeurArbeiders = this.formService.voorkeurArbeiders || [];
          this.formService.currentUser = update.data;
          this.formService.currentUser.voorkeurArbeiders = previousVoorkeurArbeiders;
        }*/
        break;
      case 'onderaannemers':
        this.updateCollection(this.onderaannemersSubject, update);
        break;
      case 'werkzaamheden':
        this.updateCollection(this.realWerkzaamhedenSubject, update);
        this.werkzaamhedenStringSubject.next(this.processWerkzaamheden(this.realWerkzaamhedenSubject.value))
        break;
    }
  }

  private updateCompany<T extends { _id: string }>(
    subject: BehaviorSubject<Company>,
    update: { action: string; data: Company; type: string }
  ): void {
    const updatedCompany = update.data

    subject.next(updatedCompany);
  }


  private updateCollection<T extends { _id: string, role: string, functieNaam: string }>(
    subject: BehaviorSubject<T[]>,
    update: { action: string; data: T, type: string }
  ) {
    const currentItems = subject.value;
    let updatedItems: T[];

    switch (update.action) {
      case 'create':
        if(update.data?.role && (update.data.role === '59cf78e883680012b0438508')) {
          update.data.functieNaam = 'Werfleider'; //administrator is ook werfleider
        }
        updatedItems = [...currentItems, update.data];
        break;
      case 'update':
        if(update.data?.role && (update.data.role === '59cf78e883680012b0438508')) {
          update.data.functieNaam = 'Werfleider'; //administrator is ook werfleider
        }
        updatedItems = currentItems.map(item =>
          item._id === update.data._id ? update.data : item
        );
        break;
      case 'delete':
        updatedItems = currentItems.filter(item => item._id !== update.data._id);
        break;
      default:
        return;
    }
    const sortedItems = this.sortByLastChosen(updatedItems, update.type);

    subject.next(sortedItems);
  }

  async checkMissedUpdates() {
    try {
      if (!this.lastSyncTimestamp || !this.companyId || !this.role) {
        console.warn('Missing required data for missed updates check');
        return;
      }
      console.log(this.lastSyncTimestamp.toString())
      const response = await fetch(
        `${this.backendUrl}/v1/auth.getMissingSocketUpdates`,
        {
          headers: {
            'Authorization': `Bearer ${this.bearerToken}`,
            'companyid': this.companyId,
            'Content-Type': 'application/json',
            'rolename': this.role,
            'since': this.lastSyncTimestamp.toISOString()
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const  updates = await response.json();
      console.log('Received missed updates:');
      console.log(updates);
      if (updates && Array.isArray(updates)) {
        updates.forEach(update => {
          if (update?.data?.company_id === this.companyId) {
            this.handleUpdate(update);
          }
        });
      }

      this.lastSyncTimestamp = new Date();
    } catch (error) {
      console.error('Error checking missed updates:', error);
      // Optionally implement retry logic here
    }
  }

  async resetAllDataWithNewFetchedData(users: User[], machines: Machine[], materialen: Materiaal[], werven: Werf[], werkzaamheden: Werkzaamheid[], company: Company){
    //eerst USERS PROCESS
    if(users?.length > 0 && this.role !== 'onderhoudstechnieker' && this.role !== 'magazijnier'){
      await this.processUsers(users);
    }
    //THEN REST
    this.machinesSubject.next(this.sortByLastChosen(machines, 'machines'));
    this.materialenSubject.next(this.sortByLastChosen(materialen, 'materialen'));
    this.wervenSubject.next(this.sortByLastChosen(werven.filter(x => x.isLaadLosPlaats == null || x.isLaadLosPlaats === false), 'werven'));
    if(this.role === 'werfleider' || this.role === 'administrator'){
      this.laadEnLosplaatsenSubject.next(this.sortByLastChosen(werven, 'laadEnLosplaatsen'));
    } else if(this.role === 'werfleider' || this.role === 'administrator'){
      this.realWerkzaamhedenSubject.next(this.sortByLastChosen(werkzaamheden, 'werkzaamheden'));
      this.werkzaamhedenStringSubject.next(this.processWerkzaamheden(this.realWerkzaamhedenSubject.value));
    }

    if(company?._id == null) company._id = company.id;

    console.log(company)
    console.log('reset initial data success');
    this.companySubject.next(company);
  }
  async fetchInitialData() {
    try {
      if (!this.lastFetchAllDataTimestamp || !this.companyId || !this.role) {
        console.warn('Missing required data for missed updates check');
        return;
      }
      console.log(this.lastFetchAllDataTimestamp.toString())
      const response = await fetch(
        `${this.backendUrl}/v1/auth.fetchInitialData`,
        {
          headers: {
            'Authorization': `Bearer ${this.bearerToken}`,
            'companyid': this.companyId,
            'Content-Type': 'application/json',
            'rolename': this.role,
            'ismobile': 'false'
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Received initial data:');
      const  data = await response.json();
      let {users, machines, materialen, werven, werkzaamheden} = data;
      data.users = null;
      data.machines = null;
      data.materialen = null;
      data.werven = null;
      data.werkzaamheden = null;
      await this.resetAllDataWithNewFetchedData(users, machines, materialen, werven, werkzaamheden, data);

      this.lastFetchAllDataTimestamp = new Date();
    } catch (error) {
      console.error('Error checking missed updates:', error);
      // Optionally implement retry logic here
    }
  }


  sortByLastChosen<T extends { _id: string }>(items: any[], type: string): any[] {
    let lastChosenIds: any[] = [];

    // Get the appropriate lastChosen array based on type
    switch (type) {
      case 'machines':
        lastChosenIds = this.lastUsedObjects?.lastChosenMachines || [];
        break;
      case 'werven':
        lastChosenIds = this.lastUsedObjects?.lastChosenWerven || [];
        break;
      case 'materialen':
        lastChosenIds = this.lastUsedObjects?.lastChosenMaterialen || [];
        break;
      case 'laadEnLosplaatsen':
        lastChosenIds = this.lastUsedObjects?.lastChosenLaadEnLosplaatsen || [];
        break;
      case 'arbeiders':
        lastChosenIds = this.lastUsedObjects?.lastChosenArbeiders || [];
        break;
      case 'onderaannemers':
        lastChosenIds = this.lastUsedObjects?.lastChosenOnderaannemers || [];
        break;
      case 'werkzaamheden':
        lastChosenIds = this.lastUsedObjects?.lastChosenWerkzaamheden || [];
        break;
    }
    let sortedItems = [...items];

    const lastChosenMap = new Map(lastChosenIds.map((id, index) => [id, index]));

    // Handle werkzaamheden
    if (type === 'werkzaamheden') {
      return sortedItems.sort((a, b) => {
        const aIndex = lastChosenMap.has(a) ? lastChosenMap.get(a) : Number.MAX_SAFE_INTEGER;
        const bIndex = lastChosenMap.has(b) ? lastChosenMap.get(b) : Number.MAX_SAFE_INTEGER;
        return aIndex === bIndex ? (a.naam < b.naam ? -1 : 1) : aIndex - bIndex;
      });
    }

    // Materialen - Other roles
    if (type === 'materialen') {
      return sortedItems.sort((a, b) => {
        if (a.naam === 'Geen') return -1;
        if (b.naam === 'Geen') return 1;
        const aIndex = lastChosenMap.get(a._id);
        const bIndex = lastChosenMap.get(b._id);
        if (aIndex !== undefined && bIndex === undefined) return -1;
        if (bIndex !== undefined && aIndex === undefined) return 1;
        if (aIndex === undefined && bIndex === undefined) return a.naam?.localeCompare(b.naam);
        return aIndex - bIndex;
      });
    }

    // Arbeiders
    if (type === 'arbeiders') {
      const lastChosenSet = new Set(lastChosenIds);

      return sortedItems.sort((a, b) => {

        const getPriority = user => {
          if (user.isVoorkeurArbeider) return 2;
          if (lastChosenSet.has(user._id)) return 3;
          if (user.functieNaam === 'Werfleider') return 6;
          if (user.role === '59cf78e883680012b0438502') return 5;
          return 4;
        };

        const aPriority = getPriority(a);
        const bPriority = getPriority(b);
        return aPriority !== bPriority ? aPriority - bPriority : (a.name || '').localeCompare(b.name || '');
      });
    }

    // Handle onderaannemers
    if (type === 'onderaannemers') {
      return sortedItems.sort((a, b) => {
        const aIndex = lastChosenMap.get(a._id);
        const bIndex = lastChosenMap.get(b._id);
        return (aIndex === undefined && bIndex === undefined)
          ? a.name.localeCompare(b.name)
          : (aIndex ?? Number.MAX_SAFE_INTEGER) - (bIndex ?? Number.MAX_SAFE_INTEGER);
      });
    }
    if (type === 'machines') {
      return sortedItems.sort((a, b) => {
        if (a.naam === 'Geen') return -1;
        if (b.naam === 'Geen') return 1;
        const aIndex = lastChosenMap.get(a._id);
        const bIndex = lastChosenMap.get(b._id);
        if (aIndex !== undefined && bIndex === undefined) return -1;
        if (bIndex !== undefined && aIndex === undefined) return 1;
        if (aIndex === undefined && bIndex === undefined) return a.naam?.localeCompare(b.naam);
        return aIndex - bIndex;
      });
    }
    //werven
    if(type === 'werven' || type === 'laadEnLosplaatsen'){
      return sortedItems.sort((a, b) => {
        const aIndex = lastChosenMap.has(a._id) ? lastChosenMap.get(a._id) : Number.MAX_SAFE_INTEGER;
        const bIndex = lastChosenMap.has(b._id) ? lastChosenMap.get(b._id) : Number.MAX_SAFE_INTEGER;
        return aIndex === bIndex ? (a.naam < b.naam ? -1 : 1) : aIndex - bIndex;
      });
    }

  }

  disconnect() {
    if (this.socket) {
      if (this.companyId) {
        this.socket.emit('leave-company', this.companyId);
      }
      this.socket.disconnect();
    }
  }
  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(() => resolve(ms))).then(() => {});
  }
  // Method to manually trigger updates (if needed)
  emitUpdate(type: string, action: string, data: any) {
    this.socket.emit('client-update', { type, action, data });
  }

  async processUsers(users: User[]) {
    let handwerkers = [];
    let werfleiders = [];
    let onderaannemers = [];
    let chauffeurs = [];
    users = users.filter(x => x.name !== 'bjorn massoels');
    for (let user of users) {
        if (user.role === '59cf78e883680012b0438501' || user.role === '59cf78e883680012b0438504') {
          if(user.functieNaam === 'Onderaannemer'){
            onderaannemers.push(user);
          } else {
            handwerkers.push(user);
          }
        } else if (user.role === '59cf78e883680012b0438503' || user.role === '59cf78e883680012b0438508') {
          user.functieNaam = 'Werfleider';
          werfleiders.push(user);
        } else if(user.role === '59cf78e883680012b0438502'){
          chauffeurs.push(user);
        }
    }
    // Combine all workers
    let allWorkers = [
      ...handwerkers,
      ...chauffeurs,
      ...werfleiders
    ];

    this.arbeidersSubject.next(this.sortByLastChosen(allWorkers, 'arbeiders'));
    this.onderaannemersSubject.next(this.sortByLastChosen(onderaannemers, 'onderaannemers'));
    this.ploegbazenSubject.next(this.sortByLastChosen(allWorkers.filter(x => x.voorkeurArbeiders && x.voorkeurArbeiders.length > 0), 'arbeiders'));
  }

  processWerkzaamheden(werkzaamheden: Werkzaamheid[]) {
    let objects = [];
    for(const werkzaamheid of werkzaamheden){
      let hasSubactiviteit = false;
      //omdat subactiviteit1 op null wordt gezet als checker op heeftsubactiviteiten!!!
      if(werkzaamheid.subActiviteit1 != null && werkzaamheid.subActiviteit1 !== '') {
        objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit1);
        hasSubactiviteit = true;
        if(werkzaamheid.subActiviteit2 != null && werkzaamheid.subActiviteit2 !== '') {
          objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit2);
          hasSubactiviteit = true;
        }
        if(werkzaamheid.subActiviteit3 != null && werkzaamheid.subActiviteit3 !== '') {
          objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit3);
          hasSubactiviteit = true;
        }
        if(werkzaamheid.subActiviteit4 != null && werkzaamheid.subActiviteit4 !== '') {
          objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit4);
          hasSubactiviteit = true;
        }
        if(werkzaamheid.subActiviteit5 != null && werkzaamheid.subActiviteit5 !== '') {
          objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit5);
          hasSubactiviteit = true;
        }
        if (werkzaamheid.subActiviteit6 != null && werkzaamheid.subActiviteit6 !== '') {
          objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit6);
          hasSubactiviteit = true;
        }

        if (werkzaamheid.subActiviteit7 != null && werkzaamheid.subActiviteit7 !== '') {
          objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit7);
          hasSubactiviteit = true;
        }

        if (werkzaamheid.subActiviteit8 != null && werkzaamheid.subActiviteit8 !== '') {
          objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit8);
          hasSubactiviteit = true;
        }

        if (werkzaamheid.subActiviteit9 != null && werkzaamheid.subActiviteit9 !== '') {
          objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit9);
          hasSubactiviteit = true;
        }

        if (werkzaamheid.subActiviteit10 != null && werkzaamheid.subActiviteit10 !== '') {
          objects.push(werkzaamheid.naam + ' | ' + werkzaamheid.subActiviteit10);
          hasSubactiviteit = true;
        }
      }
      if(!hasSubactiviteit){
        objects.push(werkzaamheid.naam);
      }
    }
    return objects;
  }

  public cleanup() {
    // 1. Disconnect socket
    if (this.socket) {
      if (this.companyId) {
        this.socket.emit('leave-company', this.companyId);
      }
      this.socket.disconnect();
      this.socket.removeAllListeners();
    }

    // 2. Reset all subjects to initial state
    this.machinesSubject.next([]);
    this.wervenSubject.next([]);
    this.materialenSubject.next([]);
    this.laadEnLosplaatsenSubject.next([]);
    this.arbeidersSubject.next([]);
    this.onderaannemersSubject.next([]);
    this.werkzaamhedenStringSubject.next([]);
    this.realWerkzaamhedenSubject.next([]);
    this.companySubject.next(null);

    // 3. Reset service properties
    this.bearerToken = null;
    this.companyId = null;
    this.lastSyncTimestamp = null;

    // 4. Reset lastUsedObjects
    this.lastUsedObjects = {
      lastChosenWerven: [],
      lastChosenMachines: [],
      lastChosenMaterialen: [],
      lastChosenLaadEnLosplaatsen: [],
      lastChosenArbeiders: [],
      lastChosenOnderaannemers: [],
      lastChosenWerkzaamheden: []
    };

    // 5. Clear FormService data if needed
    if (this.formService) {
      this.formService.handwerkers = [];
      /*
      this.formService.grondwerkers = [];
      this.formService.werfleiders = [];
      this.formService.onderaannemers = [];
      this.formService.voorkeurArbeiders = [];
      this.formService.currentUser = null;*/
    }
  }

  testIncreaseLastSyncTimestamp() {
    this.lastSyncTimestamp = new Date(this.lastSyncTimestamp.getTime() - 1000 * 60 * 60 * 24 * 2);
    if(new Date().getTime() - this.lastSyncTimestamp.getTime() > 1000 * 60 * 60 * 24 * 2){
      this.fetchInitialData();
    }
  }
}
