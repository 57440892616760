import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {Company} from "../../models/company";
import {finalize} from "rxjs";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {User} from "../../models/user";
import {faFloppyDisk} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/solid";

@Component({
  selector: 'ngx-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  settingsForm: UntypedFormGroup;
  myCompany: Company;
  isLoaded: boolean;
  uren: number[] = [5,6,7,8,9,10,11,12,13,14,15,16,17,18];
  minuten: number[] = [0,15,30,45];
  popupUren: number[] = [15,16,17,18];
  popupMinuten: number[] = [0,30];
  imageChangedEvent: any = '';
  selectedPhoto = false;
  chosenImageList: any[] = [];
  logo: string;
  private companyId: string;
  public imagePath;
  users: User[];
  isSaving: boolean = false;
  hasChangedValue: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private toastrService: NbToastrService,
    private formService: FormService,
    private router: Router,
    private storage: AngularFireStorage
  ) {
  }
  async ngOnInit() {
    this.isLoaded = false;
    this.isSaving = false;
    this.hasChangedValue = false;
    this.apiService.getCompany().subscribe(x => {
        this.myCompany = x as Company;
        if(this.myCompany._id == null){
          this.myCompany._id = this.myCompany.id;
        }
      this.companyId = this.myCompany._id;
      this.formService.company = this.myCompany;
      this.buildForm();
      this.isLoaded = true;
      this.settingsForm.valueChanges.subscribe(x => {
        this.hasChangedValue = true;
      });
    })

  }

  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  public buildForm() {
    let startUur;
    let startMinuten;
    let eindUur;
    let eindMinuten;
    if(this.myCompany.startUur != null){
      startUur = +this.myCompany.startUur.substr(0,2);
      startMinuten = +this.myCompany.startUur.substr(3,2);
    } else {
      startUur = 7;
      startMinuten = 0;
    }
    if(this.myCompany.eindUur != null){
      eindUur = +this.myCompany.eindUur.substr(0,2);
      eindMinuten = +this.myCompany.eindUur.substr(3,2);
    } else {
      eindUur = 15;
      eindMinuten = 45;
    }
    let startUurChauffeurs;
    let startMinutenChauffeurs;
    let eindUurChauffeurs;
    let eindMinutenChauffeurs;
    if(this.myCompany.startUurChauffeurs != null){
      startUurChauffeurs = +this.myCompany.startUurChauffeurs.substr(0,2);
      startMinutenChauffeurs = +this.myCompany.startUurChauffeurs.substr(3,2);
    } else {
      startUurChauffeurs = 7;
      startMinutenChauffeurs = 0;
    }
    if(this.myCompany.eindUurChauffeurs != null){
      eindUurChauffeurs = +this.myCompany.eindUurChauffeurs.substr(0,2);
      eindMinutenChauffeurs = +this.myCompany.eindUurChauffeurs.substr(3,2);
    } else {
      eindUurChauffeurs = 15;
      eindMinutenChauffeurs = 45;
    }
    let popupUur =  +this.myCompany.popupUur.substr(0,2);
    let popupMinuten =  +this.myCompany.popupUur.substr(3,2);

    this.logo = this.myCompany.logoURL;
    this.settingsForm = this.formBuilder.group({
      startUur: startUur.toString(),
      startMinuten: startMinuten.toString(),
      eindUur: eindUur.toString(),
      eindMinuten: eindMinuten.toString(),
      startUurChauffeurs: startUurChauffeurs.toString(),
      startMinutenChauffeurs: startMinutenChauffeurs.toString(),
      eindUurChauffeurs: eindUurChauffeurs.toString(),
      eindMinutenChauffeurs: eindMinutenChauffeurs.toString(),
      eenheden: [this.myCompany.eenheden],
      popupUur: popupUur.toString(),
      popupMinuten: popupMinuten.toString(),
      extraWerkFromPreviousEindUur: this.myCompany.extraWerkFromPreviousEindUur == null ? true :this.myCompany.extraWerkFromPreviousEindUur,
      heeftBeiindigOverzichtDagrapporten: this.myCompany.heeftBeiindigOverzichtDagrapporten,
      file: [''],
      uurLoonChauffeur: this.myCompany.uurLoonChauffeur,
      savedPhotosAlbum: this.myCompany.savedPhotosAlbum,
    });
  }
  onSubmit() {
    if(!this.isSaving){
      this.isSaving = true;
      let settings = this.settingsForm.value;
      this.myCompany.startUur = ("0"+(settings.startUur)).slice(-2) + ':' +("0"+(settings.startMinuten)).slice(-2);
      this.myCompany.eindUur = ("0"+(settings.eindUur)).slice(-2) + ':' +("0"+(settings.eindMinuten)).slice(-2);
      this.myCompany.startUurChauffeurs = ("0"+(settings.startUurChauffeurs)).slice(-2) + ':' +("0"+(settings.startMinutenChauffeurs)).slice(-2);
      this.myCompany.eindUurChauffeurs = ("0"+(settings.eindUurChauffeurs)).slice(-2) + ':' +("0"+(settings.eindMinutenChauffeurs)).slice(-2);
      this.myCompany.popupUur = ("0"+(settings.popupUur)).slice(-2) + ':' +("0"+(settings.popupMinuten)).slice(-2);
      this.myCompany.eenheden = settings.eenheden;
      this.myCompany.extraWerkFromPreviousEindUur = settings.extraWerkFromPreviousEindUur;
      this.myCompany.heeftBeiindigOverzichtDagrapporten = settings.heeftBeiindigOverzichtDagrapporten;
      this.myCompany.uurLoonChauffeur = settings.uurLoonChauffeur;
      this.myCompany.savedPhotosAlbum = settings.savedPhotosAlbum;
      if(this.chosenImageList.length > 0){
        this.uploadImages();
      } else {
        this.apiService.updateCompany(this.myCompany).subscribe(x => {
          let newCompany = x as Company;
          this.hasChangedValue = false;
          this.isSaving = false;
          this.toastrService.success('De instellingen zijn bijgewerkt', 'Succes!');
        }, error => {
          this.isSaving = false;
          this.failedToast('Er is iets fout gegaan bij het opslaan van de instellingen');
        });
      }
    }
  }
  onFileSelect(event) {
    var file;

    this.selectedPhoto = true;
    this.imageChangedEvent = event;
    if (event.target.files.length > 0) {
      file = event.target.files[0];
      this.settingsForm.get('file').setValue(file);
    }
    var reader = new FileReader();
    this.imagePath = file;
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.chosenImageList.push(reader.result);
    }
  }

  async uploadImages() {
    const fileToUpload = this.settingsForm.get('file').value;


    for(let i=0 ; i < this.chosenImageList.length; i++){
      // Convert to Blob and check size before upload
      const imgDataUrl = this.chosenImageList[i];
      const blob = await (await fetch(imgDataUrl)).blob();

      let compressedImage = imgDataUrl;

      // If size > 250KB, compress
      if (blob.size > 250 * 1024) {
        compressedImage = await this.compressImage(imgDataUrl, 0.5);  // Adjust the quality as needed
      }

      const fileRef = this.storage.ref(this.generateRandomName());
      const task = fileRef.putString(compressedImage, 'data_url');

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe(async (url) => {
              if (url) {
                this.myCompany.logoURL = url;
                this.apiService.updateCompany(this.myCompany).subscribe(x => {
                  this.chosenImageList = [];
                  this.logo = url;
                  this.isSaving = false;
                  this.hasChangedValue = false;
                  this.toastrService.success('De instellingen zijn bijgewerkt', 'Succes!');
                }, error => {
                  this.isSaving = false;
                  this.failedToast('Er is iets fout gegaan bij het opslaan van de instellingen');
                });
              }
            });
          })
        )
        .subscribe();
    }
  }
  async compressImage(base64Str, quality = 0.5) {
    const img = new Image();
    img.src = base64Str;
    return new Promise((resolve) => {
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        resolve(canvas.toDataURL("image/jpeg", quality));
      };
    });
  }
  failedToast(text: string) {
    this.toastrService.warning(text, 'Oops!');
  }

  generateRandomName(): string {
    const timestamp = Date.now();
    const random = Math.floor(100000000 + Math.random() * 900000000);
    const name = `fotos/${this.companyId}/${timestamp}-${random}`;
    return name;
  }
  toastBadForm() {
    this.toastrService.warning('Probeer het opnieuw', 'Oops!');
  }

  goToVerlofdagenInstellen() {
    this.router.navigate(['/pages/verlofdagen-instellen']);
  }

  protected readonly faFloppyDisk = faFloppyDisk;
}
