<h1 class="custom-title"><i class="fa-solid fa-file-download bigFaIcon"></i>Genereer dagrapport PDF of dagrapport Excel met kostenberekeningen</h1>
<nb-spinner *ngIf="isGenerating" class="generating-spinner" size="giant" [message]="'Even geduld..'" status="info"></nb-spinner>

<nb-card *ngIf="isLoaded" class="overzichtCard" size="large">
  <nb-card-body>
    <div class="bigRow">
    <div class="col">
      <h3 style="text-decoration:underline">Filter op datum en/of (werf, ploegbaas of chauffeur)</h3>
      <div style="margin-left: 56px;" class="smallRow">
        <p style="width:85px !important;" class="smallTitle"><i class="fa-solid fa-calendar-days"></i> Datum</p>
        <input class="selectBox" nbInput placeholder="Filter op datum.." [(ngModel)]="myDate" [nbDatepicker]="datePicker">
        <nb-rangepicker #datePicker></nb-rangepicker>
        <button class="closeButton" [ngClass]="myDate == null || myDate === '' ? 'hideButton' : ''"
                nbButton status="warning" (click)="clearDatum()">
          <nb-icon style="font-size: 22px" pack="fa" icon="xmark"></nb-icon>
        </button>
      </div>
      <p style="height:25px;margin-top:25px;margin-bottom:25px" class="ofText">EN/OF</p>

      <div class="ofColumn">
        <div class="smallRow">
          <p class="smallTitle"><i class="fa-solid fa-map-location-dot"></i> Werf</p>
          <app-werf-selector [width]="300"  [isLoaded]="isLoaded" [withLabelOnTop]="false"
                             [werven]="wervenObservable$"  [selectedWerfId]="chosenWerfId"
                             (werfSelected)="onSelectionChangeWerf($event)" >
          </app-werf-selector>
        </div>
        <p *ngIf="company.heeftTransportModule" class="ofText">OF</p>
        <div *ngIf="company.heeftTransportModule" class="smallRow">
          <p class="smallTitle"><i class="fa-solid fa-truck-fast"></i> Chauffeur</p>
          <input [(ngModel)]="chauffeurFilter" #autoInput2
                 nbInput
                 type="text"
                 (input)="onChangeChauffeur()"
                 placeholder="Filter op chauffeur.."
                 [nbAutocomplete]="auto2" class="selectBox" />
          <nb-autocomplete #auto2 (selectedChange)="onSelectionChangeChauffeur($event)">
            <nb-option *ngFor="let option of observableFilteredChauffeurs$ | async" [value]="option">
              {{ option }}
            </nb-option>
          </nb-autocomplete>
          <button class="closeButton" [ngClass]="autoInput2.value == null || autoInput2.value === '' ? 'hideButton' : ''"
                  nbButton status="warning" (click)="clearAutocompleteChauffeur()">
            <nb-icon style="font-size: 22px" pack="fa" icon="xmark"></nb-icon>
          </button>
        </div>
        <p *ngIf="company.heeftDagraportenModule" class="ofText">OF</p>
        <div *ngIf="company.heeftDagraportenModule" class="smallRow">
          <p class="smallTitle"><i class="fa-solid fa-user"></i> Ploegbaas</p>
          <input [(ngModel)]="arbeiderFilter" #autoInput3
                 nbInput
                 type="text"
                 (input)="onChangeArbeider()"
                 placeholder="Filter op ploegbaas.."
                 [nbAutocomplete]="auto3" class="selectBox" />
          <nb-autocomplete #auto3 (selectedChange)="onSelectionChangeArbeider($event)">
            <nb-option *ngFor="let option of observableFilteredArbeiders$ | async" [value]="option">
              {{ option }}
            </nb-option>
          </nb-autocomplete>
          <button class="closeButton" [ngClass]="autoInput3.value == null || autoInput3.value === '' ? 'hideButton' : ''"
                  nbButton status="warning" (click)="clearAutocompleteArbeider()">
            <nb-icon style="font-size: 22px" pack="fa" icon="xmark"></nb-icon>
          </button>
        </div>
      </div>
    </div>
      <div style="display:flex;flex-direction:column;margin-left:90px;margin-top:100px">
        <button mat-raised-button (click)="generateDagrapport(datePicker, chosenWerfId, chosenChauffeurId, chosenArbeiderId)" color="white" class="add-button1">
          <div class="divInsideButton"><i style="font-size: 32px; margin-right: 12px" class="fa fa-file-pdf"></i> Dagrapporten<br>download</div></button>
        <!--<button mat-raised-button (click)="generateWerfDagrapportExcel()" color="green" class="add-button-green">Download excel dagrapport</button>-->
        <button mat-raised-button (click)="generateNieuweKostenBerekening(datePicker, chosenWerfId, chosenChauffeurId, chosenArbeiderId)" color="green" class="add-button-green2">
          <div class="divInsideButton"><i style="font-size: 30px" class="fa fa-file-excel"></i>Kostenberekeningen & overzichten excel download </div></button>
      </div>
    </div>
    <!--
    <div *ngIf="heeftKostenBerekening" class="row2">
      <p class="title">Kostenberekening kost<br> per verbruikt materiaal:</p>
      <div class="kostCol">
        <div>
          <nb-select class="selectBox" (selectedChange)="checkSubActiviteiten()" [(ngModel)]="chosenWerkzaamheidId">
            <nb-option *ngFor="let werkzaamheid of allWerkzaamheden" value="{{ werkzaamheid._id }}">{{ werkzaamheid.naam }}
            </nb-option>
          </nb-select>
        </div>
        <div style="display:flex;flex-direction: row;justify-content: center;align-items: center; width:400px; margin-top: 15px !important;">
          <p *ngIf="currentWerkzaamheidHasSubActiviteiten" class="title">Subactiviteit</p>
          <nb-select class="selectBox" style="margin-right:20px" *ngIf="currentWerkzaamheidHasSubActiviteiten" [(ngModel)]="chosenSubActiviteit">
            <nb-option *ngFor="let subactiviteit of currentSubActiviteiten" value="{{ subactiviteit.naam }}">{{ subactiviteit.naam }}
            </nb-option>
          </nb-select>
        </div>
      </div>
      <button mat-raised-button style="max-height:62px !important; width:340px !important;" (click)="generateWerkzaamheidCost()" color="green" class="add-button-green1"><img class="downloadImage" src="assets/file-arrow-down-solid.svg"> kostenberekening</button>
    </div>-->

  </nb-card-body>
</nb-card>
