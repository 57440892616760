import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import * as html2pdf from 'html2pdf.js';
import {NbToastrService} from "@nebular/theme";
import {ApiService} from "../../services/api.service";
import {faPrint} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/regular";

@Component({
  selector: 'app-handleiding-popup',
  styles: [`
    :host {
      display: block;
      height: 100%;
    }

    .dialog-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .dialog-content {
      flex: 1;
      padding: 0 24px;
      font-family: Arial, sans-serif;
      height: calc(100vh - 150px); /* Adjust the value based on your header height */
      overflow-y: auto;
    }

    .dialog-title {
      position: sticky;
      top: 0;
      background: white;
      padding:0 0 0 25px;
      margin: 0;
      color: black;
      font-size: 24px;
      border-bottom: 2px solid #e0e0e0;
      z-index: 10;
    }
     nb-tab {
      height: calc(100vh - 145px); /* Adjust based on your header + tabs height */
      overflow-y: auto;

      /* Scrollbar styling */
      &::-webkit-scrollbar {
        width: 20px;  /* Make the scrollbar wider */
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
        border: 2px solid #f1f1f1; /* Creates padding effect */

        &:hover {
          background: #666;
        }
      }
    }
    .section {
      margin: 22px 0 15px 0;
      padding:12px 20px 20px 20px;
      background: #f8f9fa;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.05);
      border: 1px solid gray;
    }

    .section-title {
      color: blue;
      margin: 0 0 8px;
      font-size: 22px;
      font-weight: 500;
    }

    .subsection {
      margin: 12px 0;
      padding: 16px;
      background: white;
      border-radius: 6px;
      border: 1px solid #e0e0e0;
      box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    }

    .subsection-title {
      color: #34495e;
      margin: 0 0 16px;
      font-size: 18px;
      font-weight: 500;
    }

    .info-text {
      font-size: 14px;
      line-height: 1.5;
      color: #2c3e50;
      margin-bottom: 16px;
    }

    .info-list {
      padding-left: 20px;
      margin: 12px 0;

      li {
        margin: 8px 0;
        color: #34495e;
      }
    }

    .images-container {
      display: flex;
      gap: 16px;
      margin: 16px 0;
      padding: 16px;
      background: white;
      border-radius: 6px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #bbb;
        border-radius: 4px;

        &:hover {
          background: #999;
        }
      }
    }

    .image-wrapper {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .image-caption {
      font-size: 14px;
      color: #666;
      text-align: center;
    }

    .small-image {
      max-width: 200px;
      height: auto;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
    }

    .wide-image {
      max-width: 400px;
      height: auto;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
    }
    .figma-image {
      max-width: 100%;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
    }

    .final-note {
      margin: 32px 0;
      padding: 16px;
      background: #e3f2fd;
      border-radius: 6px;
      color: #1976d2;
      font-weight: 500;
    }
    .rowFlex{
      display: flex;
       flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap:40px;
      padding-left: 45px;
    }
    .arrowIcon{
      font-size: 20px;
      color: #1976d2;
    }
    .printHandleidingButton {
      font-size: 18px;
      display:block;
      height: 44px;
      margin:0 auto;
      background-color: dodgerblue;
    }
  `],
  template: `
    <div class="dialog-container">
      <nb-spinner *ngIf="isLoading" class="opslaan-spinner" size="giant" [message]="'Opslaan..'" status="info"></nb-spinner>
      <h1 mat-dialog-title class="dialog-title">Handleiding Afvalstofformulieren OVAM</h1>

        <nb-tabset style=" position: sticky;
  top: 0;
  z-index: 100;">
          <nb-tab tabTitle="1. Basis setup">
            <div class="section">
              <h2 class="section-title">1. Basis setup</h2>
              <div class="info-text">
                <ul class="info-list">
                  <li>
                    <strong>Vul uw bedrijfsgegevens in:</strong>
                    <ul>
                      <li>Gebruik uw BTW-nummer voor automatische aanvulling</li>
                      <li>Stel het e-mailadres in voor ontvangst van externe formulieren</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Voor verwerkers die een QR-code willen gebruiken/ophangen:</strong>
                    <ul>
                      <li>Voeg eerst een handtekening toe aan uw account bij 'Account instellingen'</li>
                      <li>Koppel daarna uw account aan uw eigen bedrijfsinstellingen</li>
                      <li>Download hierna op deze pagina de QR-code met de 'Download QR-code knop'</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="images-container">
                <div class="image-wrapper">
                  <img src="../../../assets/edigid-handleiding/accountInstellingen.png"
                       alt="Account instellingen knop"
                       class="small-image">
                  <i class="fas fa-arrow-down arrowIcon"></i>
                    <nb-icon style="font-size: 28px" icon="signature" class="button-icon"></nb-icon>
                    <i class="fas fa-arrow-down arrowIcon"></i>
                  <img src="../../../assets/edigid-handleiding/afvalstofside.png"
                       alt="Afvalstofformulieren knop"
                       class="small-image">
                  <i class="fas fa-arrow-down arrowIcon"></i>
                  <div class="rowFlex">
                    <img  src="../../../assets/edigid-handleiding/eigenBedrijfsInstellingenButton.png"
                          alt="Instellingen knop"
                          class="small-image">
                    <i class="fas fa-arrow-right arrowIcon"></i>

                  </div>
                </div>
                <div class="image-wrapper">
                  <img style="margin-left: 50px" src="../../../assets/edigid-handleiding/eigenBedrijfsInstellingen.png"
                       alt="Bedrijfsgegevens scherm"
                       class="wide-image">
                  <span class="image-caption">Bedrijfsgegevens invulscherm</span>
                </div>

              </div>
            </div>
          </nb-tab>
          <nb-tab *ngIf="!isOvamGebruiker"  tabTitle="2. Voorbereidingen aanmaken formulier">
            <div class="section">
              <h2 class="section-title">2. Vereiste instellingen vóór u een afvalstofformulier kan aanmaken.</h2>

              <div class="subsection">
                <h3 class="subsection-title">2.1 Afvalstoffen instellen</h3>
                <div class="info-text">
                  Configureer de afvalstoffen die uw bedrijf vervoert:
                  <ul class="info-list">
                    <li>Zoek op EURAL-code - de officiële omschrijving wordt automatisch aangevuld</li>
                    <li>Koppel eventueel een materiaal uit de materialenbibliotheek om de genormaliseerde naam aan chauffeurs te tonen</li>
                  </ul>
                </div>
                <div class="images-container">
                  <div class="image-wrapper">
                    <img src="../../../assets/edigid-handleiding/afvalstofFormulierenKnop.png"
                         alt="Afvalstofformulieren knop"
                         class="small-image">
                    <i class="fas fa-arrow-down arrowIcon"></i>
                    <img  src="../../../assets/edigid-handleiding/afvalstoffenKnop.png"
                          alt="Afvalstoffen knop"
                          class="small-image">
                    <i class="fas fa-arrow-down arrowIcon"></i>
                    <div class="rowFlex">
                      <img  src="../../../assets/edigid-handleiding/afvalstofKnop.png"
                            alt="Afvalstoffen toevoegen"
                            class="small-image">
                      <i class="fas fa-arrow-right arrowIcon"></i>
                    </div>

                  </div>
                  <div class="image-wrapper">
                    <img style="max-width: 600px !important; margin-left: 50px" src="../../../assets/edigid-handleiding/afvalstofToevoegen.png"
                         alt="Afvalstoffen overzicht"
                         class="wide-image">
                    <span class="image-caption">Afvalstof toevoegen</span>
                  </div>
                </div>
              </div>

              <div class="subsection">
                <h3 class="subsection-title">2.2 Klantenbeheer</h3>
                <div class="info-text">
                  Voeg verwerkers en andere vervoerders toe aan uw klantenbestand. Vul alle gegevens in met behulp van het BTW-nummer van de klant.
                </div>
                <div class="images-container">
                  <div class="image-wrapper">
                    <img src="../../../assets/edigid-handleiding/klantenKnop.png"
                         alt="Klant knop"
                         class="small-image">
                    <i class="fas fa-arrow-down arrowIcon"></i>
                    <div class="rowFlex">
                      <img  src="../../../assets/edigid-handleiding/nieuweKlantKnop.png"
                            alt="Klanten knop"
                            class="small-image">
                      <i class="fas fa-arrow-right arrowIcon"></i>

                    </div>
                  </div>
                  <div class="image-wrapper">
                    <img style="max-width: 600px !important; margin-left: 50px" src="../../../assets/edigid-handleiding/klantToevoegen.png"
                         alt="Klant toevoegen"
                         class="wide-image">
                    <span class="image-caption">Nieuwe klant toevoegen</span>
                  </div>
                </div>
              </div>

              <div class="subsection">
                <h3 class="subsection-title">2.3 Werfbeheer</h3>
                <div class="info-text">
                  Stel werven in waar afvalstoffen worden opgehaald:
                  <ul class="info-list">
                    <li>Maak een nieuwe werf aan en stel het adres in</li>
                    <li>Bij werven zonder huisnummer: verplaats de marker in Google Maps voor een correcte locatiebepaling</li>
                  </ul>
                </div>
                <div class="images-container">
                  <div class="image-wrapper">
                    <img src="../../../assets/edigid-handleiding/materieelEnWervenknop.png"
                         alt="werf knop"
                         class="small-image">
                    <i class="fas fa-arrow-down arrowIcon"></i>
                    <img  src="../../../assets/edigid-handleiding/wervenKnop.png"
                          alt="werf knop"
                          class="small-image">
                    <i class="fas fa-arrow-down arrowIcon"></i>
                    <div class="rowFlex">
                      <img  src="../../../assets/edigid-handleiding/werfToevoegKnop.png"
                            alt="werf toevoegen"
                            class="small-image">
                      <i class="fas fa-arrow-right arrowIcon"></i>
                    </div>
                  </div>
                  <div class="image-wrapper">
                    <img style="margin-left: 50px" src="../../../assets/edigid-handleiding/werfToevoegen.png"
                         alt="Nieuwe werf toevoegen"
                         class="wide-image">
                    <span class="image-caption">Nieuwe werf toevoegen</span>
                  </div>
                </div>
              </div>

              <div class="subsection">
                <h3 class="subsection-title">2.4 Handtekening instellen</h3>
                <div class="info-text">
                  Stel uw handtekening in op uw eigen account om afvalstofformulieren digitaal te kunnen aanmaken/ondertekenen.
                </div>
                <div class="images-container">
                  <div class="image-wrapper">
                    <div class="rowFlex">
                      <img src="../../../assets/edigid-handleiding/personeelKnop.png"
                           alt="Personeel knop"
                           class="small-image">
                      <i class="fas fa-arrow-right arrowIcon"></i>
                    </div>
                  </div>
                  <div class="image-wrapper">
                    <img src="../../../assets/edigid-handleiding/handtekeningInstellen.png"
                         alt="Handtekening instellen"
                         class="wide-image">
                    <span class="image-caption">Handtekening instellen</span>
                  </div>
                </div>
              </div>
            </div>
          </nb-tab>
          <nb-tab *ngIf="!isOvamGebruiker"  tabTitle="3. Nieuw afvalstofformulier aanmaken">
            <div style="border:2px solid lightblue" class="section">
              <h2   class="section-title">3. Nieuw afvalstofformulier aanmaken</h2>
              <div  class="subsection">

                <h2 style="text-decoration: underline;margin-left: 10px;">Betrokken Partijen</h2>
                <div style="padding-left: 8px !important;gap:0 !important;" class="rowFlex">
                  <div class="section">
                    <div class="party">
                      <strong>1. Vestiging afvalproducent</strong>
                      <p>Partij die het afval produceert</p>
                    </div>

                    <div class="party">
                      <strong>2. Vestiging verwerker</strong>
                      <p>Partij die het afval verwerkt</p>
                    </div>

                    <div class="party">
                      <strong>3. IHM (Inzamelaar/Handelaar/Makelaar)</strong>
                      <p>Tussenpersoon inzamelen afvalstof (leeg laten indien afvalproducent = inzamelaar)</p>
                    </div>

                    <div class="party">
                      <strong>4. Hoofdvestiging vervoerder</strong>
                      <p>Partij die het afval vervoert</p>
                    </div>
                    <div class="party">
                      <strong>5. Adres van verzending/werf</strong>
                      <p>Plaats vanwaar het afval vervoerd wordt</p>
                    </div>
                    <div class="party">
                      <strong>6. Geplande datum</strong>
                      <p>Datum vanaf wanneer de afvalstofrit zichtbaar wordt voor de chauffeur</p>
                    </div>
                    <div class="party">
                      <strong>7. Aantal ritten</strong>
                      <p>Aantal ritten die klaar worden gezet voor de chauffeur van dit formulier</p>
                    </div>
                  </div>
                  <img style="max-width: 600px !important; margin-left: 10px" src="../../../assets/edigid-handleiding/betrokkenPartijen.png"
                       alt="Betrokken partijen"
                       class="wide-image">
                </div>

                <div class="code-explanation">
                  <h2  style="text-decoration: underline;margin-left: 10px;">R- en D-codes</h2>
                  <p>Deze codes geven aan hoe het afval wordt verwerkt</p>
                </div>

                <div class="info-text">
                  Maak uw eerste formulier aan. Het systeem zal u automatisch waarschuwen indien bepaalde velden niet of onjuist zijn ingevuld.
                </div>
                <div class="images-container">
                  <div class="image-wrapper">
                    <img src="../../../assets/edigid-handleiding/afvalstofFormulierenKnop.png"
                         alt="Afvalstofformulieren knop"
                         class="small-image">
                    <i class="fas fa-arrow-down arrowIcon"></i>
                    <div class="rowFlex">
                      <img  src="../../../assets/edigid-handleiding/nieuwFormulierKnop.png"
                            alt="Instellingen knop"
                            class="small-image">
                      <i class="fas fa-arrow-right arrowIcon"></i>

                    </div>
                  </div>
                  <div class="image-wrapper">
                    <img style="max-width: 600px !important; margin-left: 50px" src="../../../assets/edigid-handleiding/nieuwFormulier.png"
                         alt="nieuw formulier"
                         class="wide-image">
                    <span class="image-caption">Nieuw formulier aanmaken</span>
                  </div>
                </div>
              </div>
            </div>
          </nb-tab>
          <nb-tab *ngIf="!isOvamGebruiker" tabTitle="4. Handleiding voor chauffeurs">
            <button class="printHandleidingButton" mat-raised-button color="primary" (click)="printChauffeurHandleiding()">
              <fa-icon [icon]="faPrint" class="button-icon"></fa-icon>
              Download handleiding voor chauffeurs
            </button>
            <div #printableSection id="printableSection" >
              <div class="section">
                <h2 class="section-title">4. Handleiding voor chauffeurs </h2>
                <div class="subsection">
                  <h3 class="subsection-title">4.1 Nieuw dagrapport aanmaken</h3>
                  <div class="info-text">
                    Voor u de afvalstofritten kan bezichten, dient u eerst een transportdagrapport aan te maken.<br>
                    Na het starten van een dagrapport kan u rechtsboven de knop afvalstoffen vinden.
                  </div>
                  <div class="images-container">
                      <img src="../../../assets/edigid-handleiding/nieuwTransportDagrapportFlow.png"
                           alt="Nieuw transportdagrapport"
                           class="figma-image" >
                  </div>
                </div>
              </div>
              <div class="section">
                <div class="subsection">
                  <h3 class="subsection-title">4.2 Afvalstofrit openen</h3>
                  <div class="images-container">
                        <img src="../../../assets/edigid-handleiding/InformatieAfvalstoffenOverzicht.png"
                             alt="Nieuw transportdagrapport"
                            class="figma-image" >
                  </div>
                </div>
              </div>
              <div class="section">
                <div class="subsection">
                  <h3 class="subsection-title">4.3 Afvalstof vervoeren</h3>
                  <div class="images-container">
                    <img src="../../../assets/edigid-handleiding/afvalstofRitFlow1.png"
                         alt="Nieuw transportdagrapport"
                         class="figma-image" >
                  </div>
                </div>
              </div>
              <div class="section">
                <div class="subsection">
                  <h3 class="subsection-title">4.4 Afvalstof vervoeren</h3>
                  <div class="images-container">
                    <img src="../../../assets/edigid-handleiding/afvalstofRitFlow2.png"
                         alt="Nieuw transportdagrapport"
                         class="figma-image" >
                  </div>
                </div>
              </div>
            </div>
          </nb-tab>
        </nb-tabset>
    </div>
  `
})
export class EdigidHandleidingPopup {
  protected isLoading: boolean;
  isOvamGebruiker: boolean = false;
  constructor(public dialogRef: MatDialogRef<EdigidHandleidingPopup>,
              private toastrService: NbToastrService,private apiService: ApiService) {
    this.isOvamGebruiker = this.apiService.isOvamGebruiker;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  async printChauffeurHandleiding() {
    const fileName = 'HandleidingComponent OVAM afvalstofmodule chauffeurs.pdf';

    const options = {
      filename: fileName,
      image: {
        type: 'jpeg',
        quality: 1.0  // Maximum quality
      },
      html2canvas: {
        scale: 5,     // Higher scale for better quality
        useCORS: true
      },
      jsPDF: {
        orientation: 'landscape',
        format: 'a4',
        compressPdf: false,
        unit: 'pt',     // Use points for more precise sizing
        hotfixes: ["px_scaling"],  // Fix potential scaling issues
      },
      margin: [20, 20, 20, 20],  // Margins in points
      pagebreak: {
        mode: 'avoid-all',
        avoid: '.pageBreak',
        before: '.before-break',
        after: '.after-break'
      }
    };

    try {
      const element = document.getElementById('printableSection');

      // Optional: Add loading indicator
      this.isLoading = true;  // Add this property to your component

      await html2pdf()
        .from(element)
        .set(options)
        .save();

      this.toastrService.success('De handleiding is gedownload.', 'Succes!');
    } catch (error) {
      this.toastrService.danger('Er is een fout opgetreden bij het downloaden.', 'Fout');
      console.error('Print error:', error);
    } finally {
      this.isLoading = false;
    }
  }

  protected readonly faPrint = faPrint;
}
