import { Injectable, ElementRef } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import {ApiService} from "./api.service";
import {Dagrapport} from "../models/dagrapport";
import {NbToastrService} from "@nebular/theme";
import {Transportdagrapport} from "../models/transport-dagrapport";
import Borders from "exceljs/index";
import FillPattern from "exceljs/index";
import  ValueType from "exceljs/index";
import {delay} from "rxjs";
import {Werf} from "../models/werf";
import {DagboekDerWerken} from "../models/dagboek-der-werken";
import {StaatDerVerlet} from "../models/staatDerVerlet";
import {FormService} from "./form.service";

@Injectable()
export class DagboekExcelService {

  constructor(private apiService: ApiService, private toastrService: NbToastrService,
              private formService: FormService) {}
  async getBase64ImageFromUrl(imageUrl) {
    let res = await fetch(imageUrl);
    let blob = await res.blob();

    return new Promise((resolve, reject) => {
      let reader  = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }





  async generateStaatDerVerletExcel(companyName: string, logoURL: string, chosenWerf, date: Date, daysOfMonth, dagboeken, previousStaatDerVerlet,hasAllDagboeken, userId, addressLine1: string , addressLine2 : string) {
    let workbook = new Workbook();
    let days: string[] = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];

    let worksheet = workbook.addWorksheet('Overzicht maand');
    let emptyRow = worksheet.addRow(['']);
    emptyRow = worksheet.addRow(['']);
    let headerRow = worksheet.addRow(['', '', 'dagboekbladnummer', 'werkdag','verletdag','weerverlet','terrein ont.','schorsing']);
    headerRow.font =  this.fontSize(10);
    for (let col = 1; col <= 8; col++) {
      if(col >= 3 && col <= 8){
        headerRow.getCell(col).border =  {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
      }
    }

    headerRow.alignment = {vertical: 'middle', horizontal: 'center'};
    let vorigeRow = worksheet.addRow(['Vorige maand', '', previousStaatDerVerlet.dagboekBladNummer, previousStaatDerVerlet.totaleWerkDagen,previousStaatDerVerlet.totaleVerletDagen,previousStaatDerVerlet.totaleWeerverletDagen,
      previousStaatDerVerlet.totaleOntoegankelijkheidDagen,previousStaatDerVerlet.totaleSchorsingsDagen]);
    worksheet.mergeCells('A4:B4');
    for (let col = 1; col <= 8; col++) { // 11 corresponds to column K
      if(col !== 2){
        vorigeRow.getCell(col).border =  {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
      }
      if(col >= 3 && col <= 8){
        vorigeRow.getCell(col).font = this.fontSize(9, '0070C0');
      }
    }
    vorigeRow.alignment = {vertical: 'middle', horizontal: 'left'};

    let huidigeRow = worksheet.addRow(['Huidige maand', '', '', '', '', '', '', '']);
    worksheet.mergeCells('A5:B5');
    huidigeRow.getCell('C').value = { formula: "=MAX(C8:C38)", date1904: false };
    huidigeRow.getCell('D').value = { formula: "=MAX(D8:D38)", date1904: false };
    huidigeRow.getCell('E').value = { formula: "=MAX(E8:E38)", date1904: false };

    huidigeRow.getCell('F').value = { formula: '=COUNTIF(F8:F38, "W") + F4', date1904: false };
    huidigeRow.getCell('G').value = { formula: '=COUNTIF(F8:F38, "T") + G4', date1904: false };
    huidigeRow.getCell('H').value = { formula: '=COUNTIF(F8:F38, "S") + H4', date1904: false };


    for (let col = 1; col <= 8; col++) {
      if(col !== 2){
        huidigeRow.getCell(col).border =  {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
      }
      if(col >= 3 && col <= 8){
        huidigeRow.getCell(col).font = this.fontSize(9, '0070C0');
      }
    }
    huidigeRow.alignment = {vertical: 'middle', horizontal: 'left'};
    let separatorRow = worksheet.addRow(['']);
    separatorRow.getCell(1).border = {bottom: {style: 'thin'}};

    let dataHeaderRow = worksheet.addRow(['datum', 'dag', 'dagboekbladnummer', 'werkdag', 'verletdag', 'code dag']);
    for (let col = 1; col <= 6; col++) {
      dataHeaderRow.getCell(col).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    }
    const year = date.getFullYear();
    const month = date.getMonth(); // July
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    let totaalHuidigeOntoeganklijkDagen = 0;
    let totaalHuidigeSchorsingsdagen = 0;
    let totaalHuidigeWeerverletDagen = 0;
    for (let day = 1; day <= daysInMonth; day++) {

      let dayRow;
      if(day <= daysOfMonth.length){
        const date = new Date(year, month, day);
        const dayName = days[date.getDay()];
        if(!chosenWerf.heeftKalenderDagen && (dayName === 'Zaterdag' || dayName === 'Zondag')){
          dayRow = worksheet.addRow([`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${day}`, dayName, '', '', '', 'z']);
          dayRow.getCell(2).fill = this.fillColor('90EE90');
          daysOfMonth.splice(day - 1, 0, {date: date, empty: true});
        } else {
          let soort;
          switch(daysOfMonth[day-1]?.dagboek?.soortVerlet){
            case 'werkdag':
              soort = '_';
              break;
            case 'weerverlet':
              totaalHuidigeWeerverletDagen++;
              soort = 'W';
              break;
            case 'verlofdag':
              soort = 'V';
              break;
            case 'ontoegankelijk':
              soort = 'T';
              totaalHuidigeOntoeganklijkDagen++;
              break;
            case 'schorsing':
              soort = 'S';
              totaalHuidigeSchorsingsdagen++;
              break;
            case 'feestdag':
              soort = 'F';
              break;
            default:
              soort = '';
              break;
          }
          dayRow = worksheet.addRow([`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${day}`, dayName, this.nullToEmptyString(daysOfMonth[day - 1]?.dagboek?.bladNummer),
             this.nullToEmptyString(daysOfMonth[day - 1]?.dagboek?.werkDagNummer), this.nullToEmptyString(daysOfMonth[day - 1]?.dagboek?.verletDagNummer), soort]);
        }

        for (let col = 1; col <= 6; col++) {
          dayRow.getCell(col).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
          dayRow.getCell(col).font = this.fontSize(9, '0070C0');
        }
      } else {
        let olderDate = new Date(date.getFullYear(), date.getMonth() , (day-1));
        const dayName = days[olderDate.getDay()];
        if(!chosenWerf.heeftKalenderDagen && (dayName === 'Zaterdag' || dayName === 'Zondag')){
          dayRow = worksheet.addRow([`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${day}`, dayName, '', '', '', 'z']);
          dayRow.getCell(2).fill = this.fillColor('90EE90');
        } else {
          dayRow = worksheet.addRow([`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${day}`, dayName, '', '', '', '']);
        }
        for (let col = 1; col <= 6; col++) {
          dayRow.getCell(col).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
          dayRow.getCell(col).font = this.fontSize(9, '0070C0');
        }
      }
    }
    let extraInfo = [
      'W = weerverlet regen/vorst',
      'T = terrein ontoegankelijk',
      'V = verlofdagen',
      'F = feestdagen',
      'S = schorsing der werken',
      'Z = zaterdag/zondag',
      '_ = werkdag'
    ];
    for (let i = 0; i <= extraInfo.length; i++) {
      let extraInfoRow = worksheet.addRow(['','','','','',extraInfo[i]]);
      extraInfoRow.getCell(6).font = this.fontSize(9);
    }

    worksheet.columns.forEach(function (column, i) {
      let maxLength = 0;
      column['eachCell']({includeEmpty: true}, function (cell) {
        let columnLength = cell.value?.toString().length;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = 10;
      if(i === 2){
        column.width = 22;
      } else if(i === 3 || i === 4 || i === 7){
        column.width = 9;
      } else if(i === 5 || i === 6){
        column.width = 13;
      }
    });
    worksheet.pageSetup = {
      fitToPage: true,
      fitToHeight: 1, // 0 is auto
      fitToWidth: 1,  // 0 is auto
    };
    if(logoURL != null && logoURL !== ''){
      await this.addImageToWorksheet(workbook,worksheet, logoURL, 'A1:B3');
    }

    let monthArray  = ['Januari','Februari','Maart','April','Mei','Juni','Juli','Augustus','September','Oktober','November','December'];

    let worksheet2 = workbook.addWorksheet('Termijnoverzicht');
    emptyRow;
    for(let i = 0; i < 6; i++){
      emptyRow = worksheet2.addRow(['']);
    }
    let titleRow = worksheet2.addRow(['Staat der verletdagen']);
    titleRow.getCell(1).font = this.fontSizeBold(18);
    worksheet2.mergeCells('A7:AF7')
    titleRow.getCell(1).border = {bottom: {style: 'double'}, left: {style: 'double'}, right: {style: 'double'}, top: {style: 'double'}};


    let projectRow = worksheet2.addRow(['Projectnr. : ',chosenWerf.nummer]);
    worksheet2.mergeCells('B8:F8');
    worksheet2.mergeCells('G8:I8');
    worksheet2.mergeCells('J8:AF8');
    projectRow.font = this.fontSize(16);
    projectRow.getCell(7).value = 'Projectnaam :';
    projectRow.getCell(10).value = chosenWerf.naam;
    projectRow.getCell(10).alignment = {vertical: 'middle', horizontal: 'center'};
    for(let i = 1; i < 11; i++){
      if(i === 1){
        projectRow.getCell(i).border = {bottom: {style: 'double'}, left: {style: 'double'}, top: {style: 'double'}};
      } else if(i === 10){
        projectRow.getCell(i).border = {bottom: {style: 'double'}, right: {style: 'double'}, top: {style: 'double'}};
      } else {
        projectRow.getCell(i).border = {bottom: {style: 'double'}, top: {style: 'double'}};
      }
    }
    let omschrijvingRow = worksheet2.addRow(['']);
    worksheet2.mergeCells('H9:T9');
    worksheet2.mergeCells('U9:AF9');
    omschrijvingRow.getCell('H').border = {left: {style: 'thin'}};
    omschrijvingRow.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}};
    omschrijvingRow.getCell('U').value = 'Omschrijving verletdagen';
    omschrijvingRow.getCell('U').font = this.fontSize(12);
    omschrijvingRow.height = 20;
    omschrijvingRow.alignment = {vertical: 'middle', horizontal: 'center'};

    let naamRow = worksheet2.addRow([companyName]);
    worksheet2.mergeCells('A10:C10');
    naamRow.getCell('A').font = this.fontSize(15);
    worksheet2.mergeCells('H10:L10');
    naamRow.getCell('H').value = 'Vorderingstaatnr. :'
    naamRow.getCell('H').border = {left: {style: 'thin'}};
    naamRow.getCell('H').font = this.fontSize(16);
    let vorderStaatNr = 1;
    if( previousStaatDerVerlet.vorderingStaatNr) {
      vorderStaatNr =  previousStaatDerVerlet.vorderingStaatNr + 1;
    }
    naamRow.getCell('M').value = vorderStaatNr;
    worksheet2.mergeCells('U10:AF10');
    naamRow.getCell('M').font = this.fontSize(16);
    naamRow.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}};
    naamRow.getCell('U').font = this.fontSize(12);
    naamRow.getCell('U').value = 'W = weerverlet regen/vorst';

    let adresRow = worksheet2.addRow([addressLine1]);
    worksheet2.mergeCells('A11:D11');
    adresRow.getCell('A').font = this.fontSize(15);
    adresRow.getCell('H').border = {left: {style: 'thin'}};
    worksheet2.mergeCells('U11:AF11');
    adresRow.getCell('M').font = this.fontSize(16);
    adresRow.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}};
    adresRow.getCell('U').font = this.fontSize(12);
    adresRow.getCell('U').value = 'T = terrein ontoegankelijk';

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    let periodeRow = worksheet2.addRow([addressLine2, '','','','','','','Periode :','',
      'van', 1, monthArray[date.getMonth()] , '', date.getFullYear(), 'tot', lastDay.getDate(), monthArray[date.getMonth()],'' , date.getFullYear() ,'', 'V = verlofdagen']);
    periodeRow.font = this.fontSize(16);
    worksheet2.mergeCells('A12:C12');
    periodeRow.getCell('A').font = this.fontSize(15);
    worksheet2.mergeCells('H12:I12');
    worksheet2.mergeCells('L12:M12');
    worksheet2.mergeCells('Q12:R12');
    worksheet2.mergeCells('U12:AF12');
    periodeRow.getCell('H').border = {left: {style: 'thin'}};
    periodeRow.getCell('L').font = this.fontSize(13);
    periodeRow.getCell('Q').font = this.fontSize(13);
    periodeRow.getCell('N').font = this.fontSize(13);
    periodeRow.getCell('S').font = this.fontSize(13);
    periodeRow.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}};
    periodeRow.getCell('U').font = this.fontSize(12);

    let feestRow = worksheet2.addRow(['']);
    worksheet2.mergeCells('U13:AF13');
    feestRow.getCell('H').border = {left: {style: 'thin'}};
    feestRow.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}};
    feestRow.getCell('U').value = 'F = feestdagen';
    feestRow.getCell('U').font = this.fontSize(12);
    feestRow.height = 20;

    let aannemerRow = worksheet2.addRow(['']);
    aannemerRow.font = this.fontSize(16);
    worksheet2.mergeCells('H14:K14');
    aannemerRow.getCell('K').value = 'Aannemer :';
    aannemerRow.getCell('L').value = companyName;
    worksheet2.mergeCells('L14:Q14');
    worksheet2.mergeCells('U14:AF14');
    aannemerRow.getCell('H').border = {left: {style: 'thin'}};
    aannemerRow.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}};
    aannemerRow.getCell('U').font = this.fontSize(12);
    aannemerRow.getCell('U').value = 'S = schorsing der werken';

    let tempDate = new Date(chosenWerf.startDatum);
    let startText = tempDate.getDate() + ' ' + monthArray[tempDate.getMonth()] + ' ' + tempDate.getFullYear();
    let bevelRow = worksheet2.addRow(['BEVEL VAN AANVANG:', '', '',startText]);
    worksheet2.mergeCells('A15:C15');
    worksheet2.mergeCells('D15:G15');
    worksheet2.mergeCells('U15:AF15');
    bevelRow.getCell('A').font = this.fontSize(12);
    bevelRow.getCell('D').font = this.fontSize(15);
    bevelRow.getCell('H').border = {left: {style: 'thin'}};
    bevelRow.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}};
    bevelRow.getCell('U').value = 'Z = zaterdag/zondag';
    bevelRow.getCell('U').font = this.fontSize(12);
    bevelRow.height = 20;

    let werkDagRow = worksheet2.addRow(['']);
    worksheet2.mergeCells('U16:AF16');
    werkDagRow.getCell('H').border = {left: {style: 'thin'}};
    werkDagRow.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}};
    werkDagRow.getCell('U').value = ' _  = werkdag';
    werkDagRow.getCell('U').font = this.fontSize(12);
    werkDagRow.height = 20;

   let allDays = [''];

    for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
      allDays.push(day.getDate().toString());
    }
    let latestDay = lastDay.getDate();
    for(latestDay; latestDay <= 31; latestDay++){
      allDays.push('');
    }

    let daysRow = worksheet2.addRow(allDays);
    daysRow.font = this.fontSize(16);
    for(let i = 1; i < 33; i++){
      daysRow.getCell(i).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    }
    daysRow.height = 15;
    daysRow.alignment = {vertical: 'middle', horizontal: 'center'};


    omschrijvingRow = worksheet2.addRow(['Omschrijving verletdag']);
    omschrijvingRow.height = 36;
    omschrijvingRow.font = this.fontSize(18);
    omschrijvingRow.alignment = {vertical: 'middle', horizontal: 'center'};
    for (let day = 1; day <= daysInMonth; day++) {

      const date = new Date(year, month, day);
      if(!chosenWerf.heeftKalenderDagen && (date.getDay() === 6 || date.getDay() === 0)){
        omschrijvingRow.getCell(day + 1).fill = this.fillColor('90EE90');
        omschrijvingRow.getCell(day + 1).value =  'Z';
      } else {
        omschrijvingRow.getCell(day+1).value = {formula: "='Overzicht maand'!F"+ (day+7).toString(), sharedFormula: "='Overzicht maand'!F"+ (day+7).toString(), date1904: false };
      }
    }
    for (let col = 1; col <= 32; col++) {
      omschrijvingRow.getCell(col).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    }
    omschrijvingRow.getCell('A').font = this.fontSize(14);
    omschrijvingRow.getCell('A').alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };


    let verletdagRow = worksheet2.addRow(['Nummer verletdag']);
    verletdagRow.height = 36;
    verletdagRow.font = this.fontSize(18);
    verletdagRow.alignment = {vertical: 'middle', horizontal: 'center'};
    for (let day = 1; day <= daysInMonth; day++) {
      date = new Date(year, month, day);
      if(!chosenWerf.heeftKalenderDagen && (date.getDay() === 6 || date.getDay() === 0)){
        verletdagRow.getCell(day + 1).fill = this.fillColor('90EE90');
      } else {
        verletdagRow.getCell(day+1).value = {formula: "='Overzicht maand'!E"+ (day+7).toString(), sharedFormula: "='Overzicht maand'!E"+ (day+7).toString(), date1904: false };
      }
    }
    for (let col = 1; col <= 32; col++) {
      verletdagRow.getCell(col).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    }
    verletdagRow.getCell('A').font = this.fontSize(14);
    verletdagRow.getCell('A').alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };

    let werkdagRow = worksheet2.addRow(['Nummer werkdag']);
    werkdagRow.height = 36;
    werkdagRow.font = this.fontSize(18);
    werkdagRow.alignment = {vertical: 'middle', horizontal: 'center'};
    for (let day = 1; day <= daysInMonth; day++) {
      date = new Date(year, month, day);
      if(!chosenWerf.heeftKalenderDagen && (date.getDay() === 6 || date.getDay() === 0)){
        werkdagRow.getCell(day + 1).fill = this.fillColor('90EE90');
      } else {
        werkdagRow.getCell(day+1).value = {formula: "='Overzicht maand'!D"+ (day+7).toString(), sharedFormula: "='Overzicht maand'!D"+ (day+7).toString(), date1904: false };
      }
    }
    for (let col = 1; col <= 32; col++) {
      werkdagRow.getCell(col).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    }
    werkdagRow.getCell('A').font = this.fontSize(14);
    werkdagRow.getCell('A').alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };

    let headersRow = worksheet2.addRow(['AANTAL DAGEN']);
    headersRow.font = this.fontSize(10);
    headersRow.height = 15;
    headersRow.alignment = {vertical: 'middle', horizontal: 'center'};
    worksheet2.mergeCells('A21:B21');
    worksheet2.mergeCells('C21:F21');
    worksheet2.mergeCells('G21:J21');
    worksheet2.mergeCells('K21:N21');
    worksheet2.mergeCells('O21:R21');
    worksheet2.mergeCells('S21:V21');
    worksheet2.mergeCells('W21:Z21');
    headersRow.getCell('C').value = 'WERKDAGEN';
    headersRow.getCell('G').value = 'WEERVERLETDAGEN';
    headersRow.getCell('K').value = 'TERREIN ONTOEGANKELIJK';
    headersRow.getCell('O').value = 'VERLOFDAGEN';
    headersRow.getCell('S').value = 'FEESTDAGEN';
    headersRow.getCell('W').value = 'SCHORSINGSDAGEN';

    for (let col = 1; col <= 32; col++) {
      headersRow.getCell(col).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    }

    let totalCountRow = worksheet2.addRow(['']);
    worksheet2.getCell('A22').value = { formula: '=COUNTA(B17:AF17)', sharedFormula: '=COUNTA(B17:AF17)', date1904: false };
    totalCountRow.font = this.fontSize(18);
    totalCountRow.height = 28;
    totalCountRow.alignment = {vertical: 'middle', horizontal: 'center'};
    worksheet2.mergeCells('A22:B23');
    worksheet2.mergeCells('C22:F23');
    worksheet2.mergeCells('G22:J23');
    worksheet2.mergeCells('K22:N23');
    worksheet2.mergeCells('O22:R23');
    worksheet2.mergeCells('S22:V23');
    worksheet2.mergeCells('W22:Z23');
    worksheet2.getCell('C22').value = { formula: '=COUNTIF(B18:AF18,"_")', sharedFormula: '=COUNTIF(B18:AF18,"_")', date1904: false };
    worksheet2.getCell('G22').value = { formula: '=COUNTIF(B18:AF18,"W")', sharedFormula: '=COUNTIF(B18:AF18,"W")', date1904: false };
    worksheet2.getCell('K22').value = { formula: '=COUNTIF(B18:AF18,"T")', sharedFormula: '=COUNTIF(B18:AF18,"T")', date1904: false };
    worksheet2.getCell('O22').value = { formula: '=COUNTIF(B18:AF18,"V")', sharedFormula: '=COUNTIF(B18:AF18,"V")', date1904: false };
    worksheet2.getCell('S22').value = { formula: '=COUNTIF(B18:AF18,"F")', sharedFormula: '=COUNTIF(B18:AF18,"F")', date1904: false };
    worksheet2.getCell('W22').value = { formula: '=COUNTIF(B18:AF18,"S")', sharedFormula: '=COUNTIF(B18:AF18,"S")', date1904: false };

    //ZATERDAG EN ZONDAG TOTALEN INVOEGEN INDIEN ZONDER KALENDERDAG
    if(!chosenWerf.heeftKalenderDagen){
      worksheet2.mergeCells('AA21:AF21');
      headersRow.getCell('AA').value = 'ZATERDAGEN/ZONDAGEN';
      worksheet2.mergeCells('AA22:AF23');
      worksheet2.getCell('AA22').value = { formula: '=COUNTIF(B18:AF18,"Z")', sharedFormula: '=COUNTIF(B18:AF18,"Z")', date1904: false };
    } else {
      worksheet2.mergeCells('AA21:AF23');
    }



    for (let col = 1; col <= 32; col++) {
      totalCountRow.getCell(col).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    }

    let uitvoeringsTermijnRow = worksheet2.addRow(['UITVOERINGSTERMIJN:', '', '','' ,chosenWerf.uitvoeringsTermijn, 'werkdagen']);
    worksheet2.mergeCells('A24:B24');
    worksheet2.mergeCells('F24:H24');
    worksheet2.mergeCells('I24:T24');
    worksheet2.mergeCells('U24:AF24');
    uitvoeringsTermijnRow.alignment = {vertical: 'middle', horizontal: 'left'};
    uitvoeringsTermijnRow.getCell('A').font = this.fontSizeBold(8);
    uitvoeringsTermijnRow.getCell('E').font = this.fontSize(14);
    uitvoeringsTermijnRow.getCell('F').font = this.fontSize(14);
    uitvoeringsTermijnRow.getCell('I').value = 'OPMERKINGEN';
    uitvoeringsTermijnRow.getCell('I').font = this.fontSize(10);
    uitvoeringsTermijnRow.getCell('I').alignment = {vertical: 'middle', horizontal: 'center'};
    uitvoeringsTermijnRow.getCell('I').border ={top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    uitvoeringsTermijnRow.getCell('U').value = 'VOOR AKKOORD';
    uitvoeringsTermijnRow.getCell('U').font = this.fontSize(10);
    uitvoeringsTermijnRow.getCell('U').alignment = {vertical: 'middle', horizontal: 'center'};
    uitvoeringsTermijnRow.getCell('U').border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};

    //weerverlet
    let vorigeWeerVerlet = worksheet2.addRow(['WEERVERLETDAGEN VORIGE PERIODES :']);
    worksheet2.mergeCells('A25:D25');
    worksheet2.mergeCells('F25:H25');
    vorigeWeerVerlet.getCell('A').font = this.fontSize(8);
    vorigeWeerVerlet.alignment = {vertical: 'middle', horizontal: 'left'};
    vorigeWeerVerlet.getCell('E').font = this.fontSize(14);
    worksheet2.getCell('E25').value = { formula: "='Overzicht maand'!F4", sharedFormula: "='Overzicht maand'!F4", date1904: false };
    vorigeWeerVerlet.getCell('I').border = {left: {style: 'thin'}};
    let huidigeWeerVerlet = worksheet2.addRow(['WEERVERLETDAGEN HUIDIGE PERIODES :']);
    worksheet2.mergeCells('A26:D26');
    worksheet2.mergeCells('F26:H26');
    huidigeWeerVerlet.getCell('A').font = this.fontSize(8);
    huidigeWeerVerlet.getCell('E').font = this.fontSize(14);
    huidigeWeerVerlet.alignment = {vertical: 'middle', horizontal: 'left'};
    worksheet2.getCell('E26').value = { formula: "=G22", sharedFormula: "=G22", date1904: false };
    huidigeWeerVerlet.getCell('I').border = {left: {style: 'thin'}};
    let totaalWeerVerlet = worksheet2.addRow(['WEERVERLETDAGEN TOTAAL :']);
    worksheet2.mergeCells('A27:B27');
    worksheet2.mergeCells('F27:H27');
    totaalWeerVerlet.getCell('A').font = this.fontSize(8);
    totaalWeerVerlet.getCell('E').font = this.fontSize(14);
    totaalWeerVerlet.alignment = {vertical: 'middle', horizontal: 'left'};
    worksheet2.getCell('E27').value = { formula: "=E25+E26", sharedFormula: "=E25+E26", date1904: false };
    totaalWeerVerlet.getCell('I').border = {left: {style: 'thin'}};


    //ontoegankelijk
    let vorigeToegankelijk = worksheet2.addRow(['ONTOEGANKELIJKHEID TERREIN VORIG  :']);
    worksheet2.mergeCells('A28:D28');
    worksheet2.mergeCells('F28:H28');
    vorigeToegankelijk.getCell('A').font = this.fontSize(8);
    vorigeToegankelijk.alignment = {vertical: 'middle', horizontal: 'left'};
    vorigeToegankelijk.getCell('E').font = this.fontSize(14);
    worksheet2.getCell('E28').value = { formula: "='Overzicht maand'!G4", sharedFormula: "='Overzicht maand'!G4", date1904: false };
    vorigeToegankelijk.getCell('I').border = {left: {style: 'thin'}};
    let huidigeToegankelijk = worksheet2.addRow(['ONTOEGANKELIJKHEID TERREIN HUIDIG :']);
    worksheet2.mergeCells('A29:D29');
    worksheet2.mergeCells('F29:H29');
    huidigeToegankelijk.getCell('A').font = this.fontSize(8);
    huidigeToegankelijk.getCell('E').font = this.fontSize(14);
    huidigeToegankelijk.alignment = {vertical: 'middle', horizontal: 'left'};
    worksheet2.getCell('E29').value = { formula: "=K22", sharedFormula: "=K22", date1904: false };
    huidigeToegankelijk.getCell('I').border = {left: {style: 'thin'}};

    let totaalToegankelijke = worksheet2.addRow(['TOTAAL ONTOEGANKELIJKHEID TERREIN :']);
    worksheet2.mergeCells('A30:D30');
    worksheet2.mergeCells('F30:H30');
    totaalToegankelijke.getCell('A').font = this.fontSize(8);
    totaalToegankelijke.getCell('E').font = this.fontSize(14);
    totaalToegankelijke.alignment = {vertical: 'middle', horizontal: 'left'};
    worksheet2.getCell('E30').value = { formula: "=E28+E29", sharedFormula: "=E28+E29", date1904: false };
    totaalToegankelijke.getCell('I').border = {left: {style: 'thin'}};

    //schorsingsdagen
    let vorigeSchorsing = worksheet2.addRow(['SCHORSINGSDAGEN VORIGE PERIODES :']);
    worksheet2.mergeCells('A31:D31');
    worksheet2.mergeCells('F31:H31');
    vorigeSchorsing.getCell('A').font = this.fontSize(8);
    vorigeSchorsing.alignment = {vertical: 'middle', horizontal: 'left'};
    vorigeSchorsing.getCell('E').font = this.fontSize(14);
    worksheet2.getCell('E31').value = { formula: "='Overzicht maand'!H4", sharedFormula: "='Overzicht maand'!H4", date1904: false };
    vorigeSchorsing.getCell('I').border = {left: {style: 'thin'}};
    let huidigeSchorsing = worksheet2.addRow(['SCHORSINGSDAGEN HUIDIGE PERIODES :']);
    worksheet2.mergeCells('A32:D32');
    worksheet2.mergeCells('F32:H32');
    huidigeSchorsing.getCell('A').font = this.fontSize(8);
    huidigeSchorsing.alignment = {vertical: 'middle', horizontal: 'left'};
    huidigeSchorsing.getCell('E').font = this.fontSize(14);
    worksheet2.getCell('E32').value = { formula: "=W22", sharedFormula: "=W22", date1904: false };
    huidigeSchorsing.getCell('I').border = {left: {style: 'thin'}};
    let totaalSchorsing = worksheet2.addRow(['SCHORSINGSDAGEN TOTAAL :']);
    worksheet2.mergeCells('A33:B33');
    worksheet2.mergeCells('F33:H33');
    totaalSchorsing.getCell('A').font = this.fontSize(8);
    totaalSchorsing.alignment = {vertical: 'middle', horizontal: 'left'};
    totaalSchorsing.getCell('E').font = this.fontSize(14);
    worksheet2.getCell('E33').value = { formula: "=E31+E32", sharedFormula: "=E31+E32", date1904: false };
    totaalSchorsing.getCell('I').border = {left: {style: 'thin'}};

    //werkdagen
    let vorigeWerkdagen = worksheet2.addRow(['WERKDAGEN VORIGE PERIODES :']);
    worksheet2.mergeCells('A34:D34');
    worksheet2.mergeCells('F34:H34');
    vorigeWerkdagen.getCell('A').font = this.fontSize(8);
    vorigeWerkdagen.alignment = {vertical: 'middle', horizontal: 'left'};
    vorigeWerkdagen.getCell('E').font = this.fontSize(14);
    worksheet2.getCell('E34').value = { formula: "='Overzicht maand'!D4", sharedFormula: "='Overzicht maand'!D4", date1904: false };
    vorigeWerkdagen.getCell('I').border = {left: {style: 'thin'}};
    let huidigeWerkdagen = worksheet2.addRow(['WERKDAGEN HUIDIGE PERIODES :']);
    worksheet2.mergeCells('A35:D35');
    worksheet2.mergeCells('F35:H35');
    huidigeWerkdagen.getCell('A').font = this.fontSize(8);
    huidigeWerkdagen.getCell('E').font = this.fontSize(14);
    huidigeWerkdagen.alignment = {vertical: 'middle', horizontal: 'left'};
    worksheet2.getCell('E35').value = { formula: "=C22", sharedFormula: "=C22", date1904: false };
    huidigeWerkdagen.getCell('I').border = {left: {style: 'thin'}};
    let totaalWerkdagen = worksheet2.addRow(['WERKDAGEN TOTAAL :']);
    worksheet2.mergeCells('A36:B36');
    worksheet2.mergeCells('F36:H36');
    totaalWerkdagen.getCell('A').font = this.fontSize(8);
    totaalWerkdagen.getCell('E').font = this.fontSize(14);
    totaalWerkdagen.alignment = {vertical: 'middle', horizontal: 'left'};
    worksheet2.getCell('E36').value = { formula: "=E34+E35", sharedFormula: "=E34+E35", date1904: false };
    totaalWerkdagen.getCell('I').border = {left: {style: 'thin'}};



    let totaleResterendeUitvoeringsTermijn = worksheet2.addRow(['BLIJFT ALS UITVOERINGSTERMIJN ']);
    worksheet2.mergeCells('A37:C37');
    worksheet2.mergeCells('F37:H37');
    totaleResterendeUitvoeringsTermijn.getCell('A').font = this.fontSizeBold(8);

    totaleResterendeUitvoeringsTermijn.getCell('E').font = this.fontSize(14);
    totaleResterendeUitvoeringsTermijn.getCell('F').font = this.fontSize(14);
    worksheet2.getCell('E37').value = { formula: "=E24-E36", sharedFormula: "=E24-E36", date1904: false };
    worksheet2.getCell('F37').value = 'werkdagen';
    totaleResterendeUitvoeringsTermijn.getCell('H').border = {right: {style: 'thin'}, top: {style: 'thin'}};
    totaleResterendeUitvoeringsTermijn.alignment = {vertical: 'middle', horizontal: 'left'};

    worksheet2.mergeCells('U25:W28');
    worksheet2.mergeCells('X25:AF28');
    worksheet2.mergeCells('U29:W32');
    worksheet2.mergeCells('X29:AF32');
    worksheet2.mergeCells('U33:W37');
    worksheet2.mergeCells('X33:AF37');

    vorigeWeerVerlet.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}, top: {style: 'thin'}, bottom: {style: 'thin'}};
    vorigeWeerVerlet.getCell('X').border = {left: {style: 'thin'}, right: {style: 'double'}, top: {style: 'thin'}, bottom: {style: 'thin'}};
    vorigeWeerVerlet.getCell('U').value = 'WERFTOEZICHTER:'
    vorigeWeerVerlet.getCell('U').font = this.fontSize(10);
    vorigeWeerVerlet.getCell('U').alignment = {vertical: 'top', horizontal: 'left'};
    huidigeToegankelijk.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}, top: {style: 'thin'}, bottom: {style: 'thin'}};
    huidigeToegankelijk.getCell('X').border = {left: {style: 'thin'}, right: {style: 'double'}, top: {style: 'thin'}, bottom: {style: 'thin'}};
    huidigeToegankelijk.getCell('U').value = 'AANNEMER:'
    huidigeToegankelijk.getCell('U').font = this.fontSize(10);
    huidigeToegankelijk.getCell('U').alignment = {vertical: 'top', horizontal: 'left'};
    totaalSchorsing.getCell('U').border = {left: {style: 'thin'}, right: {style: 'double'}, top: {style: 'thin'}, bottom: {style: 'thin'}};
    totaalSchorsing.getCell('X').border = {left: {style: 'thin'}, right: {style: 'double'}, top: {style: 'thin'}, bottom: {style: 'thin'}};
    totaalSchorsing.getCell('U').value = 'LEIDEND INGENIEUR:'
    totaalSchorsing.getCell('U').font = this.fontSize(10);
    totaalSchorsing.getCell('U').alignment = {vertical: 'top', horizontal: 'left'};

    let signingUser = chosenWerf.werfleiders.find(x => x._id.toString() === userId);
    if(signingUser?.handtekening != null && signingUser?.handtekening != ''){
      await this.addImageToWorksheetMetSize(workbook, worksheet2, signingUser?.handtekening, 'X29:X29', 85,170);
    }

    let newEmptyRow = worksheet2.addRow(['']);
    for (let col = 1; col <= 32; col++) {
      newEmptyRow.getCell(col).border = {top: {style: 'double'}};
    }

    worksheet2.columns.forEach(function (column, i) {
      let maxLength = 0;
      column['eachCell']({includeEmpty: true}, function (cell) {
        let columnLength = cell.value?.toString().length;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = 7;
      if(i === 0){
        column.width = 17;
      }
    });

    worksheet2.pageSetup = {
      fitToPage: true,
      fitToHeight: 1, // 0 is auto
      fitToWidth: 1,  // 0 is auto
    };

    for(let i = 1; i <= daysInMonth; i++){
      let dagboek;
      if(dagboeken.find(x => x.date.getDate() === i)){
        dagboek = dagboeken.find(x => x.date.getDate() === i);
      } else {
        dagboek = new DagboekDerWerken();
        dagboek.date = new Date(year, month, i);
        dagboek.werkzaamheden = [];
        dagboek.onderaannemerFirmas = [];
        dagboek.monsternameProeven = '';
        dagboek.opmerkingen = '';
        dagboek.bezoekenAllerlei = '';
        dagboek.getroffenBeslissingen = '';
        dagboek.materialen = [];
        dagboek.bladNummer = 0;
        dagboek.verletDagNummer = 0;
        dagboek.werkDagNummmer = 0;
      }
      if(dagboek.date.getMonth() === month){
        //DAGBOEKEN WORDEN NIET MEER TOEGEVOEGD
        //await this.addDagboekToWorkbook(workbook, companyName, logoURL, chosenWerf, dagboek);
      }
    }
    worksheet.properties.tabColor = { argb: '7030A0' };
    worksheet2.properties.tabColor = { argb: '00B0F0' };
    let fileName = 'Staat der verlet-' + date.getFullYear().toString() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' +  chosenWerf.naam.replace('/', '-') + '.xlsx';
    this.handleImageAndSave(workbook, worksheet2,'', '', fileName);
  }

  async addImageToWorksheet(workbook, worksheet, imageUrl: string, coords: string) {
    try {
      const imageBase64 = await this.getBase64ImageFromUrl(imageUrl);
      const image = workbook.addImage({
        base64: imageBase64,
        extension: 'png',
      });
      worksheet.addImage(image, coords);
    } catch (err) {
      console.error(err);
    }
  }
  async addImageToWorksheetMetSize(workbook, worksheet, imageUrl, coordString, height, width) {
    try {
      const imageBase64 = await this.getBase64ImageFromUrl(imageUrl);
      const imageId = workbook.addImage({
        base64: imageBase64,
        extension: 'png',
      });

      // Parse the coordinate string
      const [startCell, endCell] = coordString.split(':');
      const start = this.cellToCoord(startCell);
      const end = this.cellToCoord(endCell || startCell); // Use startCell if endCell is not provided

      // Add the image with specific dimensions
      worksheet.addImage(imageId, {
        tl: { col: start.col - 1 + 1, row: start.row - 1 + 0.32 },
        ext: { width: width, height: height }
      });
    } catch (err) {
      console.error(err);
    }
  }



  async saveWorkbook(workbook, fileName: string) {
    try {
      const data = await workbook.xlsx.writeBuffer();
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      this.formService.isGeneratingStaatDerVerlet = false;
      fs.saveAs(blob, fileName);
    } catch (err) {
      console.error(err);
    }
  }

  async handleImageAndSave(workbook, worksheet, logoURL: string, coords: string, fileName: string) {
    if (logoURL != null && logoURL != '') {
      try {
        await this.addImageToWorksheet(workbook, worksheet, logoURL, coords);
        await this.saveWorkbook(workbook, fileName);
      } catch (err) {
        console.error(err);
      }
    } else {
      await this.saveWorkbook(workbook, fileName);
    }
  }
  async handleImageAndSaveDagboek(workbook, worksheet, rowCount: number, logoURL: string, fileName: string, werf: Werf) {
      try {
        if(werf.logoOpdrachtgever1 != null && werf.logoOpdrachtgever1 != ''){
          await this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever1, 'A3:B5');
          await this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever1, `A${rowCount - 4}:B${rowCount - 2}`);
        }
        if(werf.logoOpdrachtgever2 != null && werf.logoOpdrachtgever2 != ''){
          await this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever2, 'C3:D5');
          await this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever2, `C${rowCount - 4}:E${rowCount - 2}`);
        }
        if(werf.logoOpdrachtgever3 != null && werf.logoOpdrachtgever3 != ''){
          await this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever3, 'E3:F5');
          await this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever3, `F${rowCount - 4}:G${rowCount - 2}`);
        }
        if( logoURL != ''){
          await this.addImageToWorksheet(workbook, worksheet, logoURL, 'E10:F11');
          await this.addImageToWorksheet(workbook, worksheet, logoURL, `H${rowCount - 5}:J${rowCount - 2}`);
        }

        await this.saveWorkbook(workbook, fileName);
      } catch (err) {
        console.error(err);
      }
  }

  async handleOpdrachtgeverImages(workbook, worksheet, rowCount: number, logoURL: string, werf: Werf) {
      try {
        const imagePromises = [];


        if (werf.logoOpdrachtgever1 != null && werf.logoOpdrachtgever1 != '') {
          imagePromises.push(this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever1, 'A3:B5'));
          imagePromises.push(this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever1, `A${rowCount - 4}:B${rowCount - 2}`));
        }

        if (werf.logoOpdrachtgever2 != null && werf.logoOpdrachtgever2 != '') {
          imagePromises.push(this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever2, 'C3:D5'));
          imagePromises.push(this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever2, `C${rowCount - 4}:E${rowCount - 2}`));
        }

        if (werf.logoOpdrachtgever3 != null && werf.logoOpdrachtgever3 != '') {
          imagePromises.push(this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever3, 'E3:F5'));
          imagePromises.push(this.addImageToWorksheet(workbook, worksheet, werf.logoOpdrachtgever3, `F${rowCount - 4}:G${rowCount - 2}`));
        }

        if(logoURL != ''){
          imagePromises.push(this.addImageToWorksheet(workbook, worksheet, logoURL, 'E10:F11'));
          imagePromises.push(this.addImageToWorksheet(workbook, worksheet, logoURL, `H${rowCount - 5}:J${rowCount - 2}`));
        }
        await Promise.all(imagePromises);

      } catch (err) {
        console.error(err);
      }
  }

  toastBadForm(werfNaam: string) {
    this.toastrService.warning( 'Op ' + werfNaam + ' is geen uurloon ingesteld voor de ploegbaas of arbeider', 'Vul het uurloon in op de werf.');
  }
  toastBadFormMachineKost(werfNaam: string) {
    this.toastrService.warning( 'Op machine ' + werfNaam + ' is geen kostprijs ingesteld voor de machine(inclusief machinist)', 'Vul de kostprijs in');
  }
  thinBorder(): Partial<Borders> {
    return {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'}
    };
  }
  fillColor(color: string): FillPattern {
    return {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: color},
      bgColor: {argb: color}
    }
  }
  fontSizeBold(size: number, textColor?: string){
    if(textColor == null){
      return  {
        name: 'Arial',
        family: 4,
        size: size,
        bold: true
      };
    } else {
      return  {
        name: 'Arial',
        family: 4,
        size: size,
        bold: true,
        color: {
          argb: textColor,
          theme: 1,
        },
      };
    }
  }
  fontSize(size: number, textColor?: string){
    if(textColor == null){
      return  {
        name: 'Arial',
        family: 4,
        size: size
      };
    } else {
      return  {
        name: 'Arial',
        family: 4,
        size: size,
        color: {
          argb: textColor,
          theme: 1,
        },
      };
    }
  }
  nullToEmptyStringWerkzaamheid(value: any){
    if(value == null){
      return '';
    } else {
      return ' - ' + value;
    }
  }
  nullToEmptyString(value: any){
    if(value == null){
      return '';
    } else {
      return value;
    }
  }
  nullNumberToEmptyString(value: any){
    if(value == null){
      return '';
    } else {
      return +value;
    }
  }


  async addDagboekToWorkbook(workbook, companyName: string, logoURL: string, chosenWerf, totalArrayByDate) {
    let days: string[] = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];

    let worksheet = workbook.addWorksheet(totalArrayByDate.date.getDate().toString());

    if (totalArrayByDate.date.getDay() === 0 || totalArrayByDate.date.getDay() === 6) {
      worksheet.properties.tabColor = {argb: 'FF0000'};
    } else {
      worksheet.properties.tabColor = {argb: '00B050'};
    }
    let currrentCount = 0;
    // Add new row
    let titleRow = worksheet.addRow(['MINISTERIE VAN DE VLAAMSE GEMEENSCHAP']);
    worksheet.mergeCells('A1:F1');
    worksheet.mergeCells('G1:H1');
    titleRow.getCell('A').font = this.fontSizeBold(10)
    titleRow.getCell('G').value = 'bladnummer:';
    titleRow.getCell('G').border = {left: {style: 'medium'}};
    titleRow.getCell('I').value = totalArrayByDate.bladNummer;
    titleRow.getCell('I').font = this.fontSizeBold(10, '#0070C0');
    titleRow.getCell('K').border = {right: {style: 'medium'}};

    let bestuurRow = worksheet.addRow(['Bestuur:', chosenWerf.leidendAmbtenaar]);
    worksheet.mergeCells('B2:F2');
    bestuurRow.getCell('A').font = this.fontSizeBold(10)
    bestuurRow.getCell('B').font = this.fontSizeBold(10)
    bestuurRow.getCell('G').value = 'datum:';
    bestuurRow.getCell('G').border = {left: {style: 'medium'}};
    bestuurRow.getCell('H').value = days[totalArrayByDate.date.getDay()];
    bestuurRow.getCell('H').font = this.fontSize(10, '0070C0');
    bestuurRow.getCell('I').value = ("0" + totalArrayByDate.date.getDate()).slice(-2) + '/' + ("0" + (totalArrayByDate.date.getMonth() + 1)).slice(-2) + '/' + totalArrayByDate.date.getFullYear();
    bestuurRow.getCell('I').font = this.fontSize(10, '0070C0');
    bestuurRow.getCell('J').value = 'werkuren:';
    bestuurRow.getCell('K').value = totalArrayByDate.werkuren;
    bestuurRow.getCell('K').font = this.fontSize(10, '0070C0');
    bestuurRow.getCell('K').border = {right: {style: 'medium'}};

    let temperatuurRow = worksheet.addRow(['']);
    temperatuurRow.getCell('G').value = 'temperatuur:';
    temperatuurRow.getCell('G').border = {left: {style: 'medium'}};
    temperatuurRow.getCell('H').value = '8h';
    temperatuurRow.getCell('I').value = totalArrayByDate.temp8h ? totalArrayByDate.temp8h + '°C' : '';
    temperatuurRow.getCell('I').font = this.fontSize(10, '0070C0');
    temperatuurRow.getCell('J').value = '13h';
    temperatuurRow.getCell('K').value = totalArrayByDate.temp13h ? totalArrayByDate.temp13h + '°C' : '';
    temperatuurRow.getCell('K').font = this.fontSize(10, '0070C0');
    temperatuurRow.getCell('K').border = {right: {style: 'medium'}};

    let weergesteldheidRow = worksheet.addRow(['']);
    weergesteldheidRow.getCell('G').value = 'weergesteldheid:';
    worksheet.mergeCells('G4:H4');
    worksheet.mergeCells('I4:J4');
    weergesteldheidRow.getCell('G').border = {left: {style: 'medium'}};
    weergesteldheidRow.getCell('I').value = totalArrayByDate.weergesteldheid;
    weergesteldheidRow.getCell('I').font = this.fontSize(10, '0070C0');
    weergesteldheidRow.getCell('K').border = {right: {style: 'medium'}};

    let regenGedurendeUurRow = worksheet.addRow(['']);
    worksheet.mergeCells('G5:H5');
    regenGedurendeUurRow.getCell('G').border = {left: {style: 'medium'}};

    regenGedurendeUurRow.getCell('G').value = "regen gedurende u:"
    regenGedurendeUurRow.getCell('I').value = totalArrayByDate.regenGedurendeUren ? totalArrayByDate.regenGedurendeUren + ' uur' : '';
    regenGedurendeUurRow.getCell('K').border = {right: {style: 'medium'}};

    let projectRow = worksheet.addRow(['Project:', chosenWerf.naam]);
    worksheet.mergeCells('B6:F6');
    projectRow.getCell('A').font = this.fontSizeBold(10)
    projectRow.getCell('B').font = this.fontSizeBold(10)
    worksheet.mergeCells('G6:H6');
    projectRow.getCell('G').value = 'vorige nacht/weekend:';
    projectRow.getCell('G').border = {left: {style: 'medium'}};
    projectRow.getCell('I').value = totalArrayByDate.toestandVorigeNacht;
    projectRow.getCell('I').font = this.fontSize(10, '0070C0');
    projectRow.getCell('K').border = {right: {style: 'medium'}};

    let projectNrRow = worksheet.addRow(['Projectnr:', chosenWerf.nummer]);
    worksheet.mergeCells('B7:C7');
    projectNrRow.getCell('A').font = this.fontSizeBold(10)
    projectNrRow.getCell('B').font = this.fontSizeBold(10);
    worksheet.mergeCells('G7:H7');
    projectNrRow.getCell('G').value = 'voormiddag:';
    projectNrRow.getCell('G').border = {left: {style: 'medium'}};
    projectNrRow.getCell('I').value = totalArrayByDate.toestandVoormiddag;
    projectNrRow.getCell('I').font = this.fontSize(10, '0070C0');
    projectNrRow.getCell('K').border = {right: {style: 'medium'}};


    let ontwerperRow = worksheet.addRow(['Ontwerper:', chosenWerf.studieBureau]);
    ontwerperRow.getCell('A').font = this.fontSizeBold(10);
    worksheet.mergeCells('B8:D8');
    ontwerperRow.getCell('B').font = this.fontSizeBold(10);
    worksheet.mergeCells('G8:H8');
    ontwerperRow.getCell('G').value = 'namiddag:';
    ontwerperRow.getCell('G').border = {left: {style: 'medium'}};
    ontwerperRow.getCell('I').value = totalArrayByDate.toestandNamiddag;
    ontwerperRow.getCell('I').font = this.fontSize(10, '0070C0');
    ontwerperRow.getCell('K').border = {right: {style: 'medium'}};

    let toegangkelijkRow = worksheet.addRow(['']);
    worksheet.mergeCells('G9:H9');
    toegangkelijkRow.getCell('G').value = 'toegankelijkheid terrein:';
    toegangkelijkRow.getCell('G').border = {left: {style: 'medium'}};
    worksheet.mergeCells('I9:K9');
    toegangkelijkRow.getCell('I').value = totalArrayByDate.soortVerlet === 'ontoegankelijk' ? 'Ontoegankelijk' : 'Toegankelijk';
    toegangkelijkRow.getCell('K').border = {right: {style: 'medium'}};


    let aannemerRow = worksheet.addRow(['Aannemer:', companyName]);
    worksheet.mergeCells('B10:D10');
    aannemerRow.getCell('A').font = this.fontSizeBold(10)
    aannemerRow.getCell('B').font = this.fontSizeBold(10)
    worksheet.mergeCells('G10:H10');

    aannemerRow.getCell('G').value = 'werkdag nr:';
    aannemerRow.getCell('G').border = {left: {style: 'medium'}};
    aannemerRow.getCell('I').value = totalArrayByDate.werkDagNummer;
    aannemerRow.getCell('I').font = this.fontSize(10, '0070C0');
    aannemerRow.getCell('K').border = {right: {style: 'medium'}};

    let laagsteRow = worksheet.addRow(['']);
    worksheet.mergeCells('G11:H11');
    laagsteRow.getCell('G').value = 'verletdag:';
    laagsteRow.getCell('G').border = {left: {style: 'medium'}, bottom: {style: 'medium'}};
    laagsteRow.getCell('I').value = totalArrayByDate.verletDagNummer;
    laagsteRow.getCell('I').font = this.fontSize(10, '0070C0');
    laagsteRow.getCell('K').border = {right: {style: 'medium'}, bottom: {style: 'medium'}};

    //einde header


    let firstRow = worksheet.addRow(['ARBEIDERS', '', '', 'UITGEVOERDE WERKEN - (situering)']);
    worksheet.mergeCells('A12:C12');
    worksheet.mergeCells('D12:K12');
    for (let col = 1; col <= 11; col++) { // 11 corresponds to column K
      firstRow.getCell(col).border = {
        top: {style: 'medium'},
        left: {style: 'medium'},
        bottom: {style: 'medium'},
        right: {style: 'medium'}
      };
    }
    firstRow.alignment = {vertical: 'middle', horizontal: 'center'};

    let beroepRow = worksheet.addRow(['BEROEPEN', '', 'AANTAL']);
    beroepRow.getCell('A').alignment = {vertical: 'middle', horizontal: 'center'};
    beroepRow.getCell('C').alignment = {vertical: 'middle', horizontal: 'center'};
    worksheet.mergeCells('A13:B13');
    beroepRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
    if(totalArrayByDate.werkzaamheden){
      beroepRow.getCell('D').value = this.nullToEmptyStringWerkzaamheid(totalArrayByDate?.werkzaamheden[0]);
      beroepRow.getCell('D').font = this.fontSize(10, '0070C0');
    }

    beroepRow.getCell('K').border = {right: {style: 'medium'}};

    let rowCount = 14;

    if (totalArrayByDate.arbeiderCount > 0) {
      let arbeiderRow = worksheet.addRow(['Arbeiders', '', totalArrayByDate.arbeiderCount,
        (totalArrayByDate.werkzaamheden ? this.nullToEmptyStringWerkzaamheid(totalArrayByDate.werkzaamheden[1]): '')]);
      arbeiderRow.font = this.fontSize(10, '0070C0');
      worksheet.mergeCells('A' + rowCount + ':B' + rowCount);
      arbeiderRow.getCell('C').alignment = {vertical: 'middle', horizontal: 'center'};
      arbeiderRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
      arbeiderRow.getCell('K').border = {right: {style: 'medium'}};
      rowCount++;
    }

    if (totalArrayByDate.machinistenCount > 0) {
      let machinistRow = worksheet.addRow(['Machinisten', '', totalArrayByDate.machinistenCount,
        (totalArrayByDate.werkzaamheden ? this.nullToEmptyStringWerkzaamheid(totalArrayByDate.werkzaamheden[2]): '')]);
      machinistRow.font = this.fontSize(10, '0070C0');
      worksheet.mergeCells('A' + rowCount + ':B' + rowCount);
      machinistRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
      machinistRow.getCell('C').alignment = {vertical: 'middle', horizontal: 'center'};
      machinistRow.getCell('K').border = {right: {style: 'medium'}};
      rowCount++;
    }

    if (totalArrayByDate.chauffeursCount > 0) {
      let chauffeurRow = worksheet.addRow(['Chauffeurs', '', totalArrayByDate.chauffeursCount,
        (totalArrayByDate.werkzaamheden ? this.nullToEmptyStringWerkzaamheid(totalArrayByDate.werkzaamheden[3]): '')]);
      chauffeurRow.font = this.fontSize(10, '0070C0');
      worksheet.mergeCells('A' + rowCount + ':B' + rowCount);
      chauffeurRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
      chauffeurRow.getCell('C').alignment = {vertical: 'middle', horizontal: 'center'};
      chauffeurRow.getCell('K').border = {right: {style: 'medium'}};
      rowCount++;
    }

    let fifthWerkRow = worksheet.addRow(['', '', '',
      (totalArrayByDate.werkzaamheden ? this.nullToEmptyStringWerkzaamheid(totalArrayByDate.werkzaamheden[4]): '')]);
    fifthWerkRow.font = this.fontSize(10, '0070C0');
    fifthWerkRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
    fifthWerkRow.getCell('K').border = {right: {style: 'medium'}};
    rowCount++;

    let werkzaamheidCount = 5;
    if(totalArrayByDate.onderaannemerFirmas != null && totalArrayByDate.onderaannemerFirmas.length > 0){
      for (let firma of totalArrayByDate.onderaannemerFirmas) {
        let onderaannemerFirmaRow = worksheet.addRow(['OA ' + firma.firmaNaam, '', '', this.nullToEmptyStringWerkzaamheid(totalArrayByDate.werkzaamheden[werkzaamheidCount])]);
        onderaannemerFirmaRow.font = this.fontSize(10, '0070C0');
        worksheet.mergeCells('A' + rowCount + ':B' + rowCount);
        onderaannemerFirmaRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
        onderaannemerFirmaRow.getCell('C').alignment = {vertical: 'middle', horizontal: 'center'};
        onderaannemerFirmaRow.getCell('K').border = {right: {style: 'medium'}};
        rowCount++;
        werkzaamheidCount++;
        let onderaannemersCount = worksheet.addRow(['arbeiders', '', totalArrayByDate.onderaannemerFirmas[0].arbeiderCount, this.nullToEmptyStringWerkzaamheid(totalArrayByDate.werkzaamheden[werkzaamheidCount])]);
        onderaannemersCount.font = this.fontSize(10, '0070C0');
        worksheet.mergeCells('A' + rowCount + ':B' + rowCount);
        onderaannemersCount.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
        onderaannemersCount.getCell('C').alignment = {vertical: 'middle', horizontal: 'center'};
        onderaannemersCount.getCell('K').border = {right: {style: 'medium'}};
        rowCount++;
        werkzaamheidCount++;
      }
    }

    if (totalArrayByDate.werkzaamheden && totalArrayByDate.werkzaamheden[werkzaamheidCount] != null) {
      for (werkzaamheidCount; werkzaamheidCount < totalArrayByDate.werkzaamheden.length; werkzaamheidCount++) {
        let extraWerkzaamheidRow = worksheet.addRow(['', '', '', this.nullToEmptyStringWerkzaamheid(totalArrayByDate.werkzaamheden[werkzaamheidCount])]);
        extraWerkzaamheidRow.font = this.fontSize(10, '0070C0');
        extraWerkzaamheidRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
        extraWerkzaamheidRow.getCell('K').border = {right: {style: 'medium'}};
        rowCount++;
      }
    }

    let materieelRow = worksheet.addRow(['MATERIEEL', '', '']);
    materieelRow.getCell('A').border = {top: {style: 'medium'}};
    worksheet.mergeCells('A' + rowCount + ':B' + rowCount);
    materieelRow.getCell('C').border = {top: {style: 'medium'}, right: {style: 'medium'}, left: {style: 'thin'}};
    materieelRow.getCell('K').border = {right: {style: 'medium'}};
    rowCount++;
    if(totalArrayByDate.soortVerlet === 'werkdag' && totalArrayByDate.vastMaterieelOpWerf != null && totalArrayByDate.vastMaterieelOpWerf.length > 0){
      if(totalArrayByDate.machines == null)totalArrayByDate.machines = [];
      totalArrayByDate.machines = [...totalArrayByDate.machines, ...totalArrayByDate.vastMaterieelOpWerf];
    }

    for (let i = 0; i < totalArrayByDate.machines?.length; i++) {
      let machineRow = worksheet.addRow([totalArrayByDate.machines[i].naam, '', totalArrayByDate.machines[i].aantal, this.nullToEmptyStringWerkzaamheid(totalArrayByDate.werkzaamheden[werkzaamheidCount])]);
      machineRow.font = this.fontSize(10, '0070C0');
      worksheet.mergeCells('A' + rowCount + ':B' + rowCount);
      machineRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
      machineRow.getCell('C').alignment = {vertical: 'middle', horizontal: 'center'};
      machineRow.getCell('K').border = {right: {style: 'medium'}};
      rowCount++;
    }
    for (let i = 0; i < 3; i++) {
      let emptyRow = worksheet.addRow(['']);
      emptyRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
      emptyRow.getCell('K').border = {right: {style: 'medium'}};
      rowCount++;
    }

    let aanvoerRow = worksheet.addRow(['MATERIALEN']);
    for (let col = 1; col <= 11; col++) { // 11 corresponds to column K
      aanvoerRow.getCell(col).border = {top: {style: 'medium'}};
    }
    worksheet.mergeCells('A' + rowCount + ':C' + rowCount);
    worksheet.mergeCells('I' + rowCount + ':K' + rowCount);
    aanvoerRow.getCell('I').value = 'MONSTERNAME - PROEVEN';
    aanvoerRow.getCell('K').border = {top: {style: 'medium'}, right: {style: 'medium'}};
    rowCount++;

    let firstMateriaalRow;
    let normal;
    for(let i = 0; i < 6; i++){
      if(i === 0) {
        firstMateriaalRow = worksheet.addRow([totalArrayByDate.materialen[i]?.naam != null ? totalArrayByDate.materialen[i]?.naam : '']);
        worksheet.mergeCells('A' + rowCount + ':C' + rowCount);
        firstMateriaalRow.font =  this.fontSize(10,  '0070C0');
        let monsterNameProeven = totalArrayByDate.monsternameProeven;
        firstMateriaalRow.getCell('I').value = monsterNameProeven;
        firstMateriaalRow.getCell('I').alignment = {wrapText: true,vertical: 'top', horizontal: 'start'};
      } else {
        normal = worksheet.addRow([totalArrayByDate.materialen[i]?.naam != null ? totalArrayByDate.materialen[i]?.naam : '']);
        worksheet.mergeCells('A' + rowCount + ':C' + rowCount);
        normal.font =  this.fontSize(10,  '0070C0');
        normal.getCell('K').border = { right: {style: 'medium'}};
      }
      rowCount++;
    }

    worksheet.mergeCells('I' + (rowCount - 6) + ':K' + (rowCount-1));
    firstMateriaalRow.getCell('K').border = { right: {style: 'medium'}};


    if (totalArrayByDate.photos != null && totalArrayByDate.photos.length > 0 && totalArrayByDate.photos.filter(photo => photo.addedFoto === true) != null && totalArrayByDate.photos.filter(photo => photo.addedFoto === true).length > 0) {
      let extraFotosTitleRow = worksheet.addRow(["EXTRA FOTO'S"]);
      worksheet.mergeCells('A' + rowCount + ':K' + rowCount);
      extraFotosTitleRow.getCell('A').border = {
        right: {style: 'medium'},
        left: {style: 'medium'},
        top: {style: 'medium'},
        bottom: {style: 'medium'}
      };
      extraFotosTitleRow.alignment = {vertical: 'middle', horizontal: 'center'};
      rowCount++;

      let extraFotosRow = worksheet.addRow([totalArrayByDate.photos[0]?.fotoNaam]);
      worksheet.mergeCells('A' + rowCount + ':C' + rowCount);
      extraFotosRow.getCell('A').border = {
        right: {style: 'medium'},
        left: {style: 'medium'},
        top: {style: 'medium'},
        bottom: {style: 'medium'}
      };
      if (totalArrayByDate.photos[1] != null && totalArrayByDate.photos[1].addedFoto === true){
        extraFotosRow.getCell('D').value = totalArrayByDate.photos[1]?.fotoNaam;
        worksheet.mergeCells('D' + rowCount + ':F' + rowCount);
        extraFotosRow.getCell('D').border = {
          right: {style: 'medium'},
          left: {style: 'medium'},
          top: {style: 'medium'},
          bottom: {style: 'medium'}
        };
      }
      if (totalArrayByDate.photos[2] != null && totalArrayByDate.photos[2].addedFoto === true) {
        extraFotosRow.getCell('G').value = totalArrayByDate.photos[2]?.fotoNaam;
        worksheet.mergeCells('G' + rowCount + ':I' + rowCount);
        extraFotosRow.getCell('G').border = {
          right: {style: 'medium'},
          left: {style: 'medium'},
          top: {style: 'medium'},
          bottom: {style: 'medium'}
        };
      }
      extraFotosRow.getCell('K').border = {right: {style: 'medium'}};
      extraFotosRow.alignment = {vertical: 'middle', horizontal: 'center'};
      rowCount++;

      let previousRowCount = rowCount;
      let photos = [];
      let totalCount = totalArrayByDate.photos.length;
      let runningCount = 0;

      await this.addImages(rowCount, totalArrayByDate, workbook, worksheet);
      if (totalArrayByDate.photos != null && totalArrayByDate.photos.length !== 0) {
        for (let i = 0; i < 13; i++) {
          let emptyRow = worksheet.addRow(['']);
          emptyRow.getCell('C').border = {right: {style: 'medium'}, left: {style: 'thin'}};
          emptyRow.getCell('K').border = {right: {style: 'medium'}};
          rowCount++;
        }
      }
    }

    let opmerkingenRow = worksheet.addRow(['OPMERKINGEN']);
    for (let col = 1; col <= 11; col++) { // 11 corresponds to column K
      opmerkingenRow.getCell(col).border = {top: {style: 'thin'}};
    }
    worksheet.mergeCells('A' + rowCount + ':C' + rowCount);
    worksheet.mergeCells('E' + rowCount + ':G' + rowCount);
    worksheet.mergeCells('I' + rowCount + ':K' + rowCount);
    opmerkingenRow.getCell('E').value = 'GETROFFEN BESLISSINGEN';
    opmerkingenRow.getCell('I').value = 'BEZOEKEN - ALLERLEI';
    opmerkingenRow.getCell('K').border = {top: {style: 'thin'}, right: {style: 'medium'}};
    rowCount++;



    let opmerkingen = totalArrayByDate.opmerkingen;
    let getroffenBeslissingen = totalArrayByDate.getroffenBeslissingen;
    let bezoekenAllerlei = totalArrayByDate.bezoekenAllerlei;
    let allerleiRow = worksheet.addRow([opmerkingen]);

    allerleiRow.font = this.fontSize(10, '0070C0');
      worksheet.mergeCells('A' + rowCount + ':C' + (rowCount + 5));
      worksheet.mergeCells('E' + rowCount + ':G' + (rowCount + 5));
      worksheet.mergeCells('I' + rowCount + ':K' + (rowCount + 5));
    allerleiRow.getCell('E').value = getroffenBeslissingen;
    allerleiRow.getCell('I').value = bezoekenAllerlei;
    allerleiRow.getCell('K').border = {right: {style: 'medium'}};
      rowCount+= 6;
    allerleiRow.getCell('A').alignment = {wrapText: true,vertical: 'top', horizontal: 'start'};
    allerleiRow.getCell('E').alignment = {wrapText: true,vertical: 'top', horizontal: 'start'};
    allerleiRow.getCell('I').alignment = {wrapText: true,vertical: 'top', horizontal: 'start'};

    let toezichtRow = worksheet.addRow(['', '', '', 'DE TOEZICHTHEBBENDEN']);

    for (let col = 1; col <= 11; col++) { // 11 corresponds to column K
      toezichtRow.getCell(col).border = {top: {style: 'medium'}};
    }
    worksheet.mergeCells('D' + rowCount + ':F' + rowCount);
    worksheet.mergeCells('H' + rowCount + ':K' + rowCount);
    toezichtRow.getCell('H').value = 'DE AANNEMER OF ZIJN GEMACHTIGDE';
    toezichtRow.getCell('D').alignment = {vertical: 'middle', horizontal: 'center'};
    toezichtRow.getCell('H').alignment = {vertical: 'middle', horizontal: 'center'};
    toezichtRow.getCell('G').border = {top: {style: 'medium'}, right: {style: 'medium'}};
    toezichtRow.getCell('K').border = {top: {style: 'medium'}, right: {style: 'medium'}};
    rowCount++;

    for (let i = 0; i < 5; i++) {
      let emptyRow = worksheet.addRow(['']);
      emptyRow.getCell('K').border = {right: {style: 'medium'}};
      emptyRow.getCell('G').border = {right: {style: 'medium'}};
      rowCount++;
    }

    if(totalArrayByDate.bladNummer !== 0){
      await this.handleOpdrachtgeverImages(workbook, worksheet, rowCount,logoURL,chosenWerf);
    }
    let lastRow = worksheet.addRow(['']);
    for (let col = 1; col <= 11; col++) { // 11 corresponds to column K
      lastRow.getCell(col).border = {top: {style: 'medium'}};
    }
    rowCount++;
    worksheet.columns.forEach(function (column, i) {
      let maxLength = 0;
      column['eachCell']({includeEmpty: true}, function (cell) {
        let columnLength = cell.value?.toString().length;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = 9;
      if (i === 6) {
        column.width = 15;
      } else if (i === 0 || i === 8) {
        column.width = 11;
      }
    });
    worksheet.pageSetup = {
      fitToPage: true,
      fitToHeight: 1, // 0 is auto
      fitToWidth: 1,  // 0 is auto
    };
  }
  async addImages(rowCount,totalArrayByDate, workbook, worksheet){
    let previousRowCount = rowCount;
    let photos = [];

    for(let i = 0; i < totalArrayByDate.photos.length; i++) {
      let result = await this.getBase64ImageFromUrl(totalArrayByDate.photos[i].foto);
      let base64 = result as string;
      let logo = workbook.addImage({
        base64: base64,
        extension: 'png',
      });
      photos.push(logo);
      if(i === 0 && totalArrayByDate.photos[i].addedFoto === true){
        worksheet.addImage(logo, 'A' + previousRowCount + ':C' + +(previousRowCount + 12));
      } else  if(i === 1 && totalArrayByDate.photos[i].addedFoto === true){
        worksheet.addImage(logo, 'D' + previousRowCount + ':F' + +(previousRowCount + 12));
      } else  if(i === 2 && totalArrayByDate.photos[i].addedFoto === true){
        worksheet.addImage(logo, 'G' + previousRowCount + ':H' + +(previousRowCount + 12));
      }
    }
  }


// Helper function to convert a cell reference (e.g., 'A1') to row and column
  cellToCoord(cellRef) {
    const match = cellRef.match(/^([A-Z]+)(\d+)$/);
    return {
      col: this.letterToCol(match[1]),
      row: parseInt(match[2]),
    };
  }

// Convert column letter to number (e.g., 'A' -> 1, 'B' -> 2, ...)
  letterToCol(letter) {
    let column = 0, length = letter.length;
    for (let i = 0; i < length; i++) {
      column += (letter.charCodeAt(i) - 64) * Math.pow(26, length - i - 1);
    }
    return column;
  }
}
