import { Company } from "./company";
import {Werkzaamheid} from "./werkzaamheid";
import {Machine} from "./machine";
import {Haalplaats} from "./haalplaats";
import {Werf} from "./werf";
import {Materiaal} from "./materiaal";
import {User} from "./user";

export class CompanyAdmin {
  public company: Company;
  public dagrapportCount: number;
  public gebruikers: User[];
  public totaalPrijs: number;
  transportDagrapportenCount: number;
  dagboekenCount: number;
  machinesCount: number;
  activiteitenCount: number;
  wervenCount: number;
  grondwerkerCount: number;
  chauffeurCount: number;
  onderhoudsTechniekerCount: number;
  magazijnierCount: number;
  edigdsOfMonthCount: number;
  maandenInContract: number;

  public _id: string;
  public id: string;
  public name: string;
  public email: string;
  public straat: string;
  public adres: string;
  public werkzaamheden: Werkzaamheid[];
  public machines: Machine[];
  public haalplaatsen: Haalplaats[];
  public werven: Werf[];
  public materialen: Materiaal[];
  adminCode: string;
  code: string;
  codeChauffeur: string;
  public startUur: string;
  public eindUur: string;
  standaardWerkDuurUur: number;
  standaardWerkDuurMinuten: number;
  heeftKostenBerekening: boolean;
  eenheden: string[];
  companyId: string;
  loginName: string;
  ovamId: string;


  constructor(companyid: string, loginName: string) {
    this.companyId = companyid;
    this.loginName = loginName;
  }
}
