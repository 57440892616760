import {Werf} from "./werf";
import {OnderaannemerFirma} from "./onderaannemerFirma";
import {Machine} from "./machine";
import {Materiaal} from "./materiaal";
import {User} from "./user";
import {DayWeatherData} from "./day-weather-data";
interface Photo {
  fotoNaam: string;
  foto: string;
  addedFoto: boolean;
}

export class HourlyWeatherData {
  time: string;
  temperature: number;
  wind_speed: number;
  precip: number;
  hour: string;
}
export class DagboekDerWerken {
  _id: string;
  werf: Werf;
  company_id: string;
  companyName: string;
  date: Date;
  werkzaamheden: string[];
  bladNummer: number;
  werkDagNummer: number;
  verletDagNummer: number;
  weerverletNummer: number;
  ontoegankelijkheidNummer: number;
  schorsingNummer: number;
  verlofDagNummer: number;
  feestDagNummer: number;

  werkuren: string;
  temp8h: string;
  temp13h: string;
  weergesteldheid: string;
  regenGedurendeUren: string;
  toestandVorigeNacht: string;
  toestandVoormiddag: string;
  toestandNamiddag: string;
  arbeiderCount: number;
  machinistenCount: number;
  chauffeursCount: number;
  onderaannemersCount: number;
  machinesCount: number;
  onderaannemerFirmas: OnderaannemerFirma[];
  machines: {naam: string, aantal: number}[];
  materialen:{naam: string}[];
  monsternameProeven: string;
  opmerkingen: string;
  getroffenBeslissingen: string;
  bezoekenAllerlei: string;
  photos: [Photo];
  opmerkingenDoorArbeiders: {arbeiderNaam: string, opmerking: string}[];
  isoDate: string;
  arbeiders: User[];
  machinisten: User[];
  chauffeurs: User[];
  hourlyWeatherData: HourlyWeatherData[];
  dateOfWeatherData: Date;

  //soort is temp variabele voor niet opgeslagen dagboeken
  soort: string;
  //soortverlet echte soort verlet
  soortVerlet: string;
  toegankelijkheid: string;
  startDate: Date;
  endDate: Date;
  vastMaterieelOpWerf: {naam: string, aantal: number}[];
  //voor cals in angular
  personeelRowCount: number;
  totaalPersoneel: number;
  totaalMachines: number;
  created: Date;
  updated: Date;
  extraInfoSoortVerlet: string;
  startUur: string;
  eindUur: string;
  totaleHuidigeWerkdagen: number = 0;
  totaalHuidigeWeerverletDagen: number = 0;
  totaalHuidigeOntoegankelijkDagen: number = 0;
  totaalHuidigeSchorsingsdagen: number = 0;
  totaalHuidigeVerlofdagen: number = 0;
  totaalHuidigeFeestdagen: number = 0;
  totaalHuidigeWeekendDagen: number = 0;
  totaalDagboekDays: number = 0;
  latestWerkDagNummer: number = 0;
  //toezichtervelden
  goedgekeurdByToezichter: string;
  updatedByWerfleider: string;
  interneNotitieToezichter: string;
  beoordelingToezichter: BeoordelingToezichter;
  followUpToezichter: boolean;
  opmerkingenChangedByToezichter: Date;
  monsternameProevenChangedByToezichter: Date;
  getroffenBeslissingenChangedByToezichter: Date;
  bezoekenAllerleiChangedByToezichter: Date;
  redenAfkeuring: string;
  opmerkingenToezichter: string;


  constructor() {
  }
}
export enum BeoordelingToezichter {
  GOEDGEKEURD = 'goedgekeurd',
  AFGEKEURD = 'afgekeurd',
  WEINIG_INFO = 'weiniginfo',
  NOG_NIET_BEOORDEELD = 'nognietbeoordeeld'
}
