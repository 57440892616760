
<div class="topRow">
  <h2 class="custom-title">Aanwezigheidslijst</h2>
  <div  class="outerDivFlexRow">
    <div class="colFlex">
      <mat-label class="sortText">Ga naar gebruiker</mat-label>
      <div class="rowFlex5">
        <input #autoInput
               nbInput
               type="text"
               (input)="onChange()"
               placeholder="Zoek op naam.."
               [nbAutocomplete]="auto" class="autoComplete" />
        <nb-autocomplete (selectedChange)="clickAutoCompleteUser($event)" #auto>
          <nb-option *ngFor="let option of observableFilteredItems$ | async" [value]="option">
            {{ option }}
          </nb-option>
        </nb-autocomplete>
        <button  class="closeButton" [ngClass]="autoInput.value == null || autoInput.value === '' ? 'hideButton' : ''"
                 nbButton status="warning" (click)=" clearAutocomplete()">
          <nb-icon style="font-size: 22px" pack="fa" icon="xmark"></nb-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div>
  <div *ngIf="isLoaded"  class="week-navigation">
    <nb-tabset (changeTab)="choseSoort($event.tabTitle)" #tabset>
      <nb-tab tabTitle="Arbeiders" [active]="formService.aanwezigheidsLijstChosenSoort === 'Arbeiders'"  tabIcon="user">
      </nb-tab>
      <nb-tab tabTitle="Chauffeurs" [active]="formService.aanwezigheidsLijstChosenSoort === 'Chauffeurs'"  tabIcon="truck">
      </nb-tab>
      <nb-tab tabTitle="Onderaannemers" [active]="formService.aanwezigheidsLijstChosenSoort === 'Onderaannemers'"  tabIcon="handshake">
      </nb-tab>
    </nb-tabset>
    <mat-card-title style="font-size:18px !important;margin:0px !important;">
      <span style="padding:6px;border:1px solid grey;color:black !important;border-radius:5px;margin-right: 25px;background-color: lightyellow">{{huidigeWeekIndex === currentWeekIndex? 'Huidige w' : 'W'}}eek {{ currentWeekIndex + 1}}/52</span>
      <button mat-raised-button class="nav-button" (click)="prevWeek()">
        <i class="fa-solid fa-left-long navIconBig"></i>
      </button>
      <span style="color:black !important;padding:0 10px;">{{convertDate(currentWeek.start)}} tot {{convertDate(currentWeek.end)}}</span>
      <button mat-raised-button class="nav-button" (click)="nextWeek()">
        <i class="fa-solid fa-right-long navIconBig"></i>
      </button>
    </mat-card-title>
  </div>
  <div   class="calendar-header">
    <div class="day-header">Maandag</div>
    <div class="day-header">Dinsdag</div>
    <div class="day-header">Woensdag</div>
    <div class="day-header">Donderdag</div>
    <div class="day-header">Vrijdag</div>
  </div>
    <div #scrollContainer  *ngIf="isLoaded" class="week">
      <div class="day" *ngFor="let user of filteredArray" #userElement>
        <h3 style="margin-bottom:0px !important; width:750px !important; font-weight:bold;color: blue;padding-left: 5px;font-size: 17px">{{  formService.aanwezigheidsLijstChosenSoort  === 'Onderaannemers' && user.firmaNaam != ''? user.firmaNaam + ' - ': ''}}{{  user.name }} <span *ngIf="user.totaleWerkMinuten !== 0">- Totale uren : {{convertMinutenToUren(user.totaleWerkMinuten)}}</span></h3>

        <div class="days-wrapper">
          <!-- Iterate through days and only render data for each specific weekday -->
          <div [ngClass]="day.werkMinuten !== 0? 'ingevuldBlock': 'nietIngevuldBlock'" *ngFor="let day of user.days" class="day-block" [class]="day.day">
            <div>
              <div *ngIf="day.werkMinuten !== 0" style="display:flex;flex-direction:row;justify-content: space-between;align-items: center;padding:0 10px;gap:0 15px; margin-bottom: 6px;border-bottom: 1px solid gray;">
                <p style="font-size: 17px" class="urenText"><i style="font-size: 15px;margin-right: 7px" class="fa-solid fa-clock faIcon"></i><b>{{ convertMinutenToUren(day.werkMinuten)}}</b></p>
                <div style="display: flex;flex-direction: column">
                  <p style="font-size: 15px" class="urenText"><b>{{convertHours(day.startDate)}} - {{convertHours(day.endDate)}}</b></p>
                  <p class="urenText"><i style="font-size: 11px" class="fa-solid fa-pause faIcon"></i>{{ day.schaft }} min</p>
                </div>
              </div>

              <div style="display: flex;flex-direction: column;gap:2px 0;">
                <ng-container  *ngFor="let activiteit of day.activiteitenPerWerf" >
                  <p class="werfText">{{functionService.displayWerf(activiteit.werf)}}</p>
                  <div *ngIf="day.transportDagrapportId" class="totalWerfRow">
                    <p class="totalWerfText"><b>{{activiteit.activiteiten.length}}</b> rit{{activiteit.activiteiten.length !== 0 ? 'ten': ''}}</p>
                    <p class="totalWerfText"><i style="font-size: 13px;margin-right: 3px" class="fa-solid fa-clock faIcon"></i><b>{{convertMinutenToUren(activiteit.werkMinuten)}}</b></p>
                    <p class="totalWerfText">{{convertHours(activiteit.startDate)}} - {{convertHours(activiteit.endDate)}}</p>
                  </div>
                  <div style="display:flex;flex-direction:row;justify-content: space-between;align-items: center;" *ngFor="let activiteit of activiteit.activiteiten">
                    <p class="activiteitText">{{ activiteit.activiteit }} ({{  convertMinutenToUren(activiteit.werkMinuten) }})</p>
                    <button (click)="goToDagrapport(day, activiteit)" class="arrow-button">
                      <i class="fas fa-arrow-right"></i>
                    </button>
                  </div>

                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
