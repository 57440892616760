
<div class="firstOuterRow">
  <div class="update-container">
    <p class="update-header">Laatste updates</p>
    <p class="update-item"><i style="padding-right: 4px" class="fa fa-cloud-sun"></i>Toezichter uitnodigen op platform voor digitale opvolging dagboeken</p>
    <p class="update-item"><i style="padding-right: 4px" class="fa fa-bell"></i>Handleidingen voor edigid afvalstoffen beschikbaar</p>
    <p class="update-item"><i style="padding-right: 4px" class="fa fa-snowplow"></i>Verwijdering van 'Machinist' → nu 'Ploegbaas' (Machinist wordt automatisch gekozen bij eerdere registratie op de machine)</p>
    <p class="update-item"><i style="padding-right: 4px" class="fa fa-snowplow"></i>Alleen modules zichtbaar die ingeschakeld zijn per bedrijf</p>
  </div>
  <div *ngIf="latestVersion" class="update-container">
    <p class="update-header">Mobile breaking versie</p>
    <div class="version"><span class="versieLatestBreaking">{{ displayVersion(latestVersion) }}</span> 23/02/2025 - Laatste mobiele versie</div>
    <div class="module-box" [ngClass]="'module-box--blue'">
      <p class="versieLatestBreaking">{{displayVersion(breakingVersions.dagrapportenModule)}}</p>
      <i [class]="'fa-solid fa-person-digging'"></i>
      <span>DAGRAPPORTEN</span>
    </div>
    <div class="module-box" [ngClass]="'module-box--orange'">
      <p class="versieLatestBreaking">{{displayVersion(breakingVersions.transportModule)}}</p>
      <i [class]="'fa-solid fa-truck'"></i>
      <span>TRANSP0RT</span>
    </div>
    <div class="module-box" [ngClass]="'module-box--red'">
      <p class="versieLatestBreaking">{{displayVersion(breakingVersions.reparatieModule)}}</p>
      <i [class]="'fa-solid fa-screwdriver-wrench'"></i>
      <span>REPARATIE</span>
    </div>
    <div class="module-box" [ngClass]="'module-box--grey'">
      <p class="versieLatestBreaking">{{displayVersion(breakingVersions.magazijnierModule)}}</p>
      <i [class]="'fa-solid fa-cubes'"></i>
      <span>MAGAZIJNIER</span>
    </div>
  </div>
</div>

<div class="update-container">
  <p class="update-header">Aankomende functies</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-circle-nodes"></i>Koppeling met API's van Grondbank & Grondwijzer in de transportmodule</p>
  <p class="update-item"><i style="padding-right: 3px" class="fa fa-sync-alt"></i><b>Doorlopende optimalisatie van alle modules</b></p>
</div>
