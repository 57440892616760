import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { FormService } from "./form.service";
import {User} from "../models/user";
import {NbAuthJWTToken, NbAuthService, NbTokenStorage} from "@nebular/auth";
import {Company} from "../models/company";
import {Materiaal} from "../models/materiaal";
import {Werf} from "../models/werf";
import {Haalplaats} from "../models/haalplaats";
import {Werkzaamheid} from "../models/werkzaamheid";
import {Machine} from "../models/machine";
import {Materiaaltransport} from "../models/materiaaltransport";
import {Dagrapport} from "../models/dagrapport";
import {Werk} from "../models/werk";
import {Router} from "@angular/router";
import {Reparatie} from "../models/reparatie";
import {Verlof} from "../models/verlof";
import {Transportdagrapport} from "../models/transport-dagrapport";
import {TransportWerk} from "../models/transportwerk";
import {DagboekDerWerken} from "../models/dagboek-der-werken";
import {StaatDerVerlet} from "../models/staatDerVerlet";
import {Magazijntaak} from "../models/magazijntaak";
import {NbToastrService} from "@nebular/theme";
import {Edigid} from "../models/E-digid/E-digid";
import {Bedrijf} from "../models/E-digid/bedrijf";
import {Schademelding} from "../models/schademelding";
import * as Sentry from "@sentry/angular";
import {DagWerfPlanning} from "../models/werfplanning";
import {firstValueFrom} from "rxjs";
import {DayWeatherData} from "../models/day-weather-data";
import {DataSyncService} from "./dataSync.service";
import {AuthDataService} from "../app.module";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private callURL = environment.apiURL;
  private _token = '';
  public userId = '';
  public company_id = '';
  public role = '';
  public userName = '';
  private authUrl = this.callURL + "auth.login";
  public accessToken: string;
  sendPasswordRecoveryURL = this.callURL + 'users.sendPasswordRecovery';
  private createUserUrl = this.callURL + "users.create";
  recoverPasswordURL = this.callURL + "users.recoverPassword";
  public options = { headers: { "Content-Type": "application/json" } };
  private getLatestVersionUrl = this.callURL + "auth.getLatestVersionNumber";
  private createWerkUrl = this.callURL + 'werken.create';
  private createTransportWerkUrl: string =  this.callURL + 'transportwerken.create';
  heeftKostenBerekening: boolean;
  isWerfleider: boolean = false;
  isOnderhoudstechnieker: boolean = false;
  isMagazijnier: boolean = false;
  isAdministrator: boolean = false;
  isNotAllowedToLogin: boolean = false;
  isOvamAdministrator: boolean = false;
  isOvamGebruiker: boolean = false;
  isDagboekToezichter: boolean = false;
  chosenCompanyOVAM: string;
  hasLoaded: boolean = false;
  isAdministratorSelux: boolean;
  constructor(
    private http: HttpClient, private toastService: NbToastrService, private router: Router, private tokenStorage: NbTokenStorage,
    private authService: NbAuthService, private dataSyncService: DataSyncService, private authDataService: AuthDataService
  ) {
    this.authService
      .onTokenChange()
      .subscribe(async (nbToken: NbAuthJWTToken) => {
          this.hasLoaded = false;
          let authData;
          try {
            authData = this.authDataService.getAuthData();
          } catch (error) {
            await this.delay(800);
            console.error('Error parsing token:', error);
            this.toastService.danger('Er is iets misgelopen... Gelieve opnieuw in te loggen.', 'Gelieve opnieuw in te loggen!', {duration: 5000});
            this.onLogoutClick();
            return;
          }
          const { company, role, userId, userName, token } = authData;

          if ((company || role === 'dagboekToezichter') && userId && role && token) {
            this.company_id = company?._id ?? '';
            this.role = role;
            this.userId = userId;
            this.userName = userName;
            this.accessToken = token;
            this._token = 'Bearer ' + token;
            console.log(token)
              this.isAdministratorSelux = false;
              this.isWerfleider = false;
              this.isNotAllowedToLogin = false;
              this.isOnderhoudstechnieker = false;
              this.isMagazijnier = false;
              this.isAdministrator = false;
              this.isDagboekToezichter = false;
              if(this.role === 'admin'){
                this.isAdministratorSelux = true;
              } else {
                this.isAdministratorSelux = false;
                if(this.role === 'werfleider'){
                  this.isWerfleider = true;
                } else if(this.role === 'onderhoudstechnieker'){
                  this.isOnderhoudstechnieker = true;
                } else if(this.role === 'magazijnier'){
                  this.isMagazijnier = true;
                } else if(this.role === 'administrator'){
                  this.isAdministrator = true;
                } else if(this.role === 'ovamAdministrator') {
                  this.isOvamAdministrator = true;
                } else if(this.role === 'ovamGebruiker') {
                  this.isOvamGebruiker = true;
                } else if(this.role === 'dagboekToezichter') {
                  this.isDagboekToezichter = true;
                } else{
                  this.isNotAllowedToLogin = true;
                }
              }
            if(this.isDagboekToezichter){
              Sentry.setUser({
                user_id: this.userId,
                user_name: this.userName,
                company_id: 'dagboekToezichter',
                company_name: 'dagboekToezichter'
              });
              this.hasLoaded = true;
              return;
            }

              this.dataSyncService.setSocketVariables(this.userId, this.role, this.company_id, this.accessToken) ;

              //ON RELOAD OF PAGE AFTER 15 SECONDS OF LOGIN
              if((new Date().getTime() - 15000) > new Date(nbToken.getCreatedAt()).getTime()){
                this.dataSyncService.lastFetchAllDataTimestamp = new Date(nbToken.getCreatedAt());
                await this.dataSyncService.fetchInitialData();
                console.log('renew data after reload after 15 seconds logged in');
              } else {
                //ON FIRST LOGIN
                await this.dataSyncService.resetAllDataWithNewFetchedData(company.users,  company.machines,  company.materialen,
                  company.werven,  company.werkzaamheden,  company);
              }
            let newDataCompany = this.dataSyncService.companySubject.value;
            this.heeftKostenBerekening = newDataCompany.heeftKostenBerekening;
              if(this.heeftKostenBerekening == null) this.heeftKostenBerekening = true;
              Sentry.setUser({
                user_id: this.userId,
                user_name: this.userName,
                company_id: newDataCompany._id,
                company_name: newDataCompany.name
              });
            console.log(this.userName)
            if(this.role === 'Admin'){
              this.isAdministratorSelux = true;
            }
              this.hasLoaded = true;


            }

    });
  }

  onLogoutClick() {
    this.authService.logout('email').subscribe(() => {
      this.tokenStorage.clear();
      this.hasLoaded = false;
      Sentry.setUser(null);
      this.router.navigate(['auth/login']); // Use Angular's router to navigate
    });
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  recoverPassword(wachtwoord: string, code: string, id: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      password: wachtwoord,
      code: code,
      id: id,
    });
    return this.http
      .get(this.callURL + 'users.recoverPassword', {
        headers: header,
      })
      .pipe(tap((x) => {}));
  }
  forgotPassword(email: string) {

    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      email: email,
    });
    return this.http
      .get(this.callURL + 'users.sendPasswordRecovery', {
        headers: header,
      })
      .pipe(tap((x) => {}));
  }
  addUser(user: User, isRegisterPage: boolean) {
    let headers;
    if(isRegisterPage){
      headers = {
        headers: {
          'Content-Type': 'application/json',
        }
      };
      user.isNoHelper = true;
    } else {
      headers = {
        headers: {
          'Content-Type': 'application/json',
          companyid: this.company_id,
        }
      };
    }

    return this.http.post(
      this.callURL + 'users.create',
      JSON.stringify(user),
      headers
      ,
    );
  }
  deleteUser(user: User) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };
    return this.http.post(
      this.callURL + 'users.delete',
      JSON.stringify(user),
      headers
      ,
    );
  }
  getCompany() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: this.company_id,
      },
    };
    return this.http.get(this.callURL + 'companys.id', headers);
  }
  getMaterialen(){
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id
    });
    return this.http
      .get( this.callURL + 'materialen.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }


  createMateriaal(materiaal: Materiaal) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'materialen.create',
      JSON.stringify(materiaal),
      headers
    );
  }

  getMateriaalById(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: _id,
      },
    };
    return this.http.get(this.callURL + 'materiaals.id', headers);
  }

  updateMateriaal(materiaal: Materiaal) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'materialen.update',
      JSON.stringify(materiaal),
      headers
    );
  }

  deleteMateriaal(materiaal: Materiaal) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'materialen.delete',
      JSON.stringify(materiaal),
      headers
    );
  }

  getWerven(withNormalWerven: boolean, withLaadlosplaatsen: boolean, withShared?: boolean, withArchived?: boolean) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      withnormalwerven: withNormalWerven.toString(),
      withlaadlosplaatsen: withLaadlosplaatsen.toString(),
      withshared: withShared == null? 'false' : withShared.toString(),
      witharchived: withArchived == null? 'false' : withArchived.toString()
    });
    return this.http
      .get( this.callURL + 'werven.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }
  createWerf(werf: Werf) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'werven.create',
      JSON.stringify(werf),
      headers
    );
  }
  updateWerf(werf: Werf) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'werven.update',
      JSON.stringify(werf),
      headers
    );
  }
  deleteWerf(werf: Werf) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'werven.delete',
      JSON.stringify(werf),
      headers
    );
  }

  getHaalplaatsen() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id
    });
    return this.http
      .get( this.callURL + 'haalplaatsen.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  createHaalplaats(haalplaats: Haalplaats) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'haalplaatsen.create',
      JSON.stringify(haalplaats),
      headers
    );
  }

  updateHaalplaats(haalplaats: Haalplaats) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'haalplaatsen.update',
      JSON.stringify(haalplaats),
      headers
    );
  }

  deleteHaalplaats(haalplaats: Haalplaats) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'haalplaatsen.delete',
      JSON.stringify(haalplaats),
      headers
    );
  }

  getWerkzaamheden() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id
    });
    return this.http
      .get( this.callURL + 'werkzaamheden.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  createWerkzaamheid(werkzaamheid: Werkzaamheid) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'werkzaamheden.create',
      JSON.stringify(werkzaamheid),
      headers
    );
  }
  updateWerkzaamheid(werkzaamheid: Werkzaamheid) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'werkzaamheden.update',
      JSON.stringify(werkzaamheid),
      headers
    );
  }
  deleteWerkzaamheid(werkzaamheid: Werkzaamheid) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'werkzaamheden.delete',
      JSON.stringify(werkzaamheid),
      headers
    );
  }

  getMachines(isKleineMachine: boolean, alleMachines: boolean) {
    if(isKleineMachine == null) isKleineMachine = false;
    if(alleMachines == null) alleMachines = false;
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      iskleinemachine: isKleineMachine.toString(),
      allemachines: alleMachines.toString()
    });
    return this.http
      .get( this.callURL + 'machines.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  createMachine(machine: Machine) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'machines.create',
      JSON.stringify(machine),
      headers
    );
  }

  updateMachine(machine: Machine) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'machines.update',
      JSON.stringify(machine),
      headers
    );
  }

  deleteMachine(machine: Machine) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'machines.delete',
      JSON.stringify(machine),
      headers
    );
  }

  getMaterialenTransport() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id
    });
    return this.http
      .get( this.callURL + 'materialentransports.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  createMateriaalTransport(materiaal: Materiaaltransport) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'materialentransports.create',
      JSON.stringify(materiaal),
      headers
    );
  }

  updateMateriaalTransport(materiaal: Materiaaltransport) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'materialentransports.update',
      JSON.stringify(materiaal),
      headers
    );
  }

  deleteMateriaalTransport(materiaal: Materiaaltransport) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'materialentransports.delete',
      JSON.stringify(materiaal),
      headers
    );
  }

  getUsers() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id
    });
    return this.http
      .get( this.callURL + 'users.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  updateUser(user: User) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'users.update',
      JSON.stringify(user),
      headers
    );
  }


  getDagrapporten(werfId, page, userId, isReload: boolean, filterDate: string, ploegbaasId: string) {
    if(filterDate == null) filterDate = '';
    if(werfId == null) werfId = '';
    if(userId == null) userId = '';
    if(ploegbaasId == null) ploegbaasId = '';
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      page: page.toString(),
      werfid: werfId,
      userid: userId,
      isreload:isReload.toString(),
      filterdate: filterDate,
      ploegbaasid: ploegbaasId
    });
    if(page < 15){
      return this.http
        .get( this.callURL + 'dagrapporten.list' , {
          headers: header
        })
        .pipe(tap((x) => {}));
    }
  }

  deleteDagrapport(dagrapport: Dagrapport) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'dagrapporten.delete',
      JSON.stringify(dagrapport),
      headers
    );
  }

  updateDagrapport(dagrapport : Dagrapport) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'dagrapporten.update',
      JSON.stringify(dagrapport),
      headers
    );
  }

  getDagrapport(_id: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      _id:  _id
    });
    return this.http
      .get( this.callURL + 'dagrapporten.id' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  updateWerk(werk: Werk, schademelding: boolean) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id,
        schademelding: schademelding.toString()
      },
    };
    return this.http.post(
      this.callURL + 'werken.update',
      JSON.stringify(werk),
      headers
    );
  }

  deleteWerk(werk: Werk) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'werken.delete',
      JSON.stringify(werk),
      headers
    );
  }

  getDagrapportenWerf(_id: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      id: _id
    });
    return this.http
      .get( this.callURL + 'dagrapporten.getDagrapportenWerf' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  getDagrapportenExcel(date1, date2, chosenWerfId, chosenChauffeurId, chosenArbeiderId){
    if(chosenChauffeurId == null ||chosenChauffeurId === '')chosenChauffeurId = 'geen';
    if(chosenArbeiderId == null ||chosenArbeiderId === '')chosenArbeiderId = 'geen';
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      date1: date1,
      date2: date2,
      chosenwerfid: chosenWerfId,
      chosenchauffeurid: chosenChauffeurId,
      chosenarbeiderid: chosenArbeiderId
    });
    return this.http
      .get( this.callURL + 'dagrapporten.getDagrapportenExcel' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }
  updateCompany(myCompany: Company) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'companys.update',
      JSON.stringify(myCompany),
      headers
    );
  }

  getBetweenDatesDagrapporten(startDate: string, endDate: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      fromdate: startDate,
      todate: endDate
    });
    return this.http
      .get( this.callURL + 'dagrapporten.getBetweenDatesDagrapporten' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  createDagrapport(dagrapport: Dagrapport) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };
    return this.http.post(
      this.callURL + 'dagrapporten.create',
      JSON.stringify(dagrapport),
      headers
    );
  }

  createWerk(werk: Werk, dagrapportId: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        dagrapportid: dagrapportId
      },
    };
    return this.http.post(
      this.callURL + 'werken.create',
      JSON.stringify(werk),
      headers
    );
  }

  getDagrapportenByWerkzaamheid(chosenWerkzaamheidId: any, chosenSubActiviteit: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      _id: chosenWerkzaamheidId,
      subactiviteit: chosenSubActiviteit
    });
    return this.http
      .get( this.callURL + 'dagrapporten.getDagrapportenByWerkzaamheid' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  getWerkenByWerkzaamheid(chosenWerkzaamheidId: any, chosenSubActiviteit: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      _id: chosenWerkzaamheidId,
      subactiviteit: chosenSubActiviteit
    });
    return this.http
      .get( this.callURL + 'werken.getWerkenByWerkzaamheid' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  getCompanysAdmin(fromDate: string, toDate: string) {
    if(fromDate == null)fromDate = '';
    if(toDate == null)toDate = '';
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this._token,
        fromdate: fromDate,
        todate: toDate
      });
      return this.http
        .get( this.callURL + 'companys.list' , {
          headers: header
        })
        .pipe(tap((x) => {}));
  }

  getWerkenWithFotos(werfId: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      _id: werfId
    });
    return this.http
      .get( this.callURL + 'werken.getWerkenWithFotos' , {
        headers: header
      })
      .pipe(tap((x) => {}));

  }

  sendComment(text: any) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id,
        username: this.userName
      },
    };

    return this.http.post(
      this.callURL + 'companys.sendComment',
      JSON.stringify({text: text}),
      headers
    );
  }

  getUsersWithPloegen() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id
    });
    return this.http
      .get( this.callURL + 'users.getUsersWithPloegen' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  getDagrapportenByUser(userid: string, page) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      userid: userid,
      page: page.toString()
    });
    if(page < 10){
      return this.http
        .get( this.callURL + 'dagrapporten.getDagrapportenByUser' , {
          headers: header
        })
        .pipe(tap((x) => {}));
    }
  }

  sendInvoice(company: Company, fromDate: string, toDate: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        fromdate: fromDate,
        toDate: toDate
      },
    };

    return this.http.post(
      this.callURL + 'companys.sendInvoice',
      JSON.stringify(company),
      headers
    );
  }

  getWerfleiders() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id
    });
    return this.http
      .get( this.callURL + 'users.getWerfleiders' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  getDagrapportPopulate(_id: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      _id:  _id
    });
    return this.http
      .get( this.callURL + 'dagrapporten.getDagrapportPopulate' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  getAllWerkzaamheden() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid: this.company_id
    });
    return this.http
      .get( this.callURL + 'werkzaamheden.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  addEmptyWerk(dagrapport: Dagrapport, startvaneinduur: boolean) {
    if(startvaneinduur == null)startvaneinduur = false;
    const header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this._token,
      startvaneinduur: startvaneinduur.toString()
    });
    return this.http
      .post( this.callURL + 'dagrapporten.addNewEmptyWerk' ,JSON.stringify(dagrapport) ,{
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  getReparaties() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid: this.company_id,
      isonderhoudstechnieker: this.isOnderhoudstechnieker.toString(),
      userid: this.userId
    });
    return this.http
      .get( this.callURL + 'reparaties.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  updateReparatie(reparatie: Reparatie) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };

    return this.http.post(
      this.callURL + 'reparaties.update',
      JSON.stringify(reparatie),
      headers
    );
  }

  deleteReparatie(reparatie: Reparatie) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'reparaties.delete',
      JSON.stringify(reparatie),
      headers
    );
  }
  getUsersMagazijn() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'users.getMagazijnUsers' , headers)
      .pipe(tap((x) => {}));
  }

  getCommentsByWorkerOnCompany() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'users.getCommentsByWorkers' , headers)
      .pipe(tap((x) => {}));
  }

  getVerlofs() {
	let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'verlofs.list' , headers)
      .pipe(tap((x) => {}));
  }

  updateVerlof(verlof: Verlof) {
       let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'verlofs.update',
      JSON.stringify(verlof),
      headers
    );
  }

    getTransportDagrapporten(page: number, userId: string, isReload: boolean, filterDate: string) {
      if(filterDate == null) filterDate = '';
      if(userId == null) userId = '';
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id,
        page: page.toString(),
        userid: userId,
        isreload:isReload.toString(),
        filterdate: filterDate
      });
      if(page < 10){
        return this.http
            .get( this.callURL + 'transport-dagrapporten.list' , {
              headers: header
            })
            .pipe(tap((x) => {}));
      }
    }

    getChauffeurs(withShared?: boolean) {
    let companyid = this.company_id;
      if(this.isOvamAdministrator && this.chosenCompanyOVAM != null) companyid = this.chosenCompanyOVAM;
      const header = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this._token,
            companyid:  companyid,
            withshared: withShared == null? 'false' : withShared.toString()
        });
        return this.http
            .get( this.callURL + 'users.getChauffeurs' , {
                headers: header
            })
            .pipe(tap((x) => {}));
    }

  updateTransportDagrapport(dagrapport: Transportdagrapport) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'transport-dagrapporten.update',
      JSON.stringify(dagrapport),
      headers
    );
  }

  deleteTransportDagrapport(dagrapport: Transportdagrapport) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };
    return this.http.post(
      this.callURL + 'transport-dagrapporten.delete',
      JSON.stringify(dagrapport),
      headers
    );
  }

  deleteTransportWerk(werk: TransportWerk) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };
    return this.http.post(
      this.callURL + 'transportwerken.delete',
      JSON.stringify(werk),
      headers
    );
  }

  addEmptyTransportWerk(dagrapport: Transportdagrapport) {
    const header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this._token
    });
    return this.http
      .post( this.callURL + 'transport-dagrapporten.addNewEmptyTransportWerk' ,JSON.stringify(dagrapport) ,{
        headers: header
      })
      .pipe(tap((x) => {}));
  }

    getMeerwerken(werfId: string) {
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid:  this.company_id,
        _id: werfId
      });
      return this.http
          .get( this.callURL + 'werken.getMeerwerken' , {
            headers: header
          })
          .pipe(tap((x) => {}));
    }

  getUneditedObjectsCount() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid: this.company_id
    });
    return this.http
      .get( this.callURL + 'companys.getCountUnopenedObjects' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

    updateWerkHasBeenViewed(_id: string) {
        const header = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this._token,
            companyid: this.company_id,
            _id: _id
        });
        return this.http
            .get( this.callURL + 'werken.updateWerkHasBeenViewed' , {
                headers: header
            })
            .pipe(tap((x) => {}));
    }

  getTransportDagrapportenExcel(date1: string, date2: string, chosenWerfId: string, chosenChauffeurId?: string, chosenArbeiderId?: string) {
    if(chosenChauffeurId == null ||chosenChauffeurId === '')chosenChauffeurId = 'geen';
    if(chosenArbeiderId == null ||chosenArbeiderId === '')chosenArbeiderId = 'geen';
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      date1: date1,
      date2: date2,
      chosenwerfid: chosenWerfId,
      chosenchauffeurid: chosenChauffeurId,
      chosenarbeiderid: chosenArbeiderId
    });
    return this.http
      .get( this.callURL + 'transport-dagrapporten.getTransportDagrapportenExcel' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  getWeatherOf2Days(historicalDate: string, latCoord: string, longCoord: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      historicaldate: historicalDate,
      latcoord: latCoord,
      longcoord: longCoord
    });
    return this.http
      .get( this.callURL + 'dagboek-der-werken.getWeatherOf2Days' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  updateDagboekDerWerken(usersArrayByDate: DagboekDerWerken, werfId: string, heeftKalenderDagen: boolean) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        werfid: werfId,
        heeftkalenderdagen: heeftKalenderDagen ? heeftKalenderDagen.toString() : 'false'
      },
    };

    return this.http.post(
      this.callURL + 'dagboek-der-werken.update',
      JSON.stringify(usersArrayByDate),
      headers
    );
  }

  addDagboekDerWerken(usersArrayByDate: DagboekDerWerken) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'dagboek-der-werken.create',
      JSON.stringify(usersArrayByDate),
      headers
    );
  }

  getDagboekDerWerken(dateISO: string, werfId: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      werfid: werfId,
      datum: dateISO
    });
    return this.http
      .get( this.callURL + 'dagboek-der-werken.getDagboekDerWerken' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

    deleteDagboekDerWerken(dagboek) {
        let headers = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: this._token
            },
        };
        return this.http.post(
            this.callURL + 'dagboek-der-werken.delete',
            JSON.stringify(dagboek),
            headers
        );
    }

  getTotalActiviteitsOfDaysOfMonth(_id: string, month: number, fullYear: number) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid: this.company_id,
      werfid: _id,
      month: month.toString(),
      fullyear: fullYear.toString()
    });
    return this.http
      .get( this.callURL + 'dagboek-der-werken.getTotalActivitiesDatesOfMonth' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  getDagboekenOfMonth(_id: string, month: number, fullYear: number) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid: this.company_id,
      werfid: _id,
      month: month.toString(),
      fullyear: fullYear.toString()
    });
    return this.http
      .get( this.callURL + 'dagboek-der-werken.getDagboekDerWerkensOfMonth' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  createTransportDagrapport(transportdagrapport: Transportdagrapport) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'transport-dagrapporten.create',
      JSON.stringify(transportdagrapport),
      headers
    );
  }

    async getLatestDagboekOfWerf(_id: string) {
        const header = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this._token,
            companyid: this.company_id,
            werfid: _id
        });
        return firstValueFrom(this.http
            .get( this.callURL + 'dagboek-der-werken.getLatestDagboekOfWerf' , {
                headers: header
            })
            .pipe(tap((x) => {})));
    }
    async getLatestBeoordeeldDagboekbyToezichter(_id: string) {
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        werfid: _id
      });
      return firstValueFrom(this.http
        .get( this.callURL + 'dagboek-der-werken.getLatestBeoordeeldDagboekbyToezichter' , {
          headers: header
        })
        .pipe(tap((x) => {})));
    }

  createStaatDerVerlet(staatDerVerlet: StaatDerVerlet) {
        let headers = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: this._token
            },
        };
        staatDerVerlet.company_id = this.company_id;
        return this.http.post(
            this.callURL + 'staat-der-verlets.create',
            JSON.stringify(staatDerVerlet),
            headers
        );
    }

  getLaadLosplaatsen() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id
    });
    return this.http
      .get( this.callURL + 'werven.laadLosplaatsenList' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  updateStaatDerVerlet(staatDerVerlet: StaatDerVerlet) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'staat-der-verlets.update',
      JSON.stringify(staatDerVerlet),
      headers
    );
  }

  verwijderDagboeken(dagboekIds: string[]) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'dagboek-der-werken.deleteDagboeken',
      JSON.stringify(dagboekIds),
      headers
    );
  }

  getDagrapportenPDF(date1: string, date2: string, chosenWerfId: string, chosenChauffeurId: string, chosenArbeiderId: string,chosenWerfName: string, fileName: string) {
    if(chosenChauffeurId == null ||chosenChauffeurId === '')chosenChauffeurId = 'geen';
    if(chosenArbeiderId == null ||chosenArbeiderId === '')chosenArbeiderId = 'geen';
     if(chosenWerfName == null ||chosenWerfName === '')chosenWerfName = 'geen';
     if(chosenWerfId == null ||chosenWerfId === '') chosenWerfId = 'geen';
    if(date1 == null ||date1 === '')date1 = 'geen';
    if(date2 == null ||date2 === '')date2 = 'geen';
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid:  this.company_id,
      date1: date1,
      date2: date2,
      chosenwerfid: chosenWerfId,
      chosenchauffeurid: chosenChauffeurId,
      chosenarbeiderid: chosenArbeiderId,
      chosenwerfname: chosenWerfName,
      filename: fileName
    });
    return this.http
      .get( this.callURL + 'dagrapporten.generateDagrapportenPDF' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  generatePidpaPdf(companyId: string, werfId: string, fileName: string, startDate: string, endDate: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid: companyId,
      werfid: werfId,
      filename: fileName,
      startdate: startDate,
      enddate: endDate,
      userid: this.userId
    });
    return this.http
      .get( this.callURL + 'dagboek-der-werken.generatePidpaPdf' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  addReparatie(reparatie: any) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'reparaties.create',
      JSON.stringify(reparatie),
      headers
    );
  }

  getMagazijntaaks() {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this._token,
      companyid: this.company_id,
      ismagazijnier: this.isMagazijnier.toString(),
      userid: this.userId
    });
    return this.http
      .get( this.callURL + 'magazijn-taken.list' , {
        headers: header
      })
      .pipe(tap((x) => {}));
  }

  updateMagazijntaak(magazijnTaak: Magazijntaak) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'magazijn-taken.update',
      JSON.stringify(magazijnTaak),
      headers
    );
  }

  deleteMagazijntaak(magazijnTaak: Magazijntaak) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };
    return this.http.post(
      this.callURL + 'magazijn-taken.delete',
      JSON.stringify(magazijnTaak),
      headers
    );
  }

  addMagazijntaak(magazijnTaak: Magazijntaak) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'magazijn-taken.create',
      JSON.stringify(magazijnTaak),
      headers
    );
  }


  sendAccountDeletionRequest(user: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        userid: user
      },
    };
    return this.http.get(this.callURL + 'users.sendAccountDeletionRequest', headers);
  }

  createEdigid(newEdigid: Edigid) {

    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http.post(
      this.callURL + 'e-digids.create',
      JSON.stringify(newEdigid),
      headers
    );
  }

    getBedrijven() {
    let companyid = this.company_id;
      if(this.isOvamAdministrator && this.chosenCompanyOVAM != null) companyid = this.chosenCompanyOVAM;
        let headers = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: this._token,
                companyid: companyid
            },
        };

        return this.http
            .get( this.callURL + 'bedrijfs.list' , headers)
            .pipe(tap((x) => {}));
    }

  updateBedrijf(bedrijf: Bedrijf) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'bedrijfs.update',
      JSON.stringify(bedrijf),
      headers
    );
  }

  deleteBedrijf(bedrijf: Bedrijf) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };
    return this.http.post(
      this.callURL + 'bedrijfs.delete',
      JSON.stringify(bedrijf),
      headers
    );
  }

  createBedrijf(bedrijf: Bedrijf) {
        let headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: this._token
        },
        };

        return this.http.post(
        this.callURL + 'bedrijfs.create',
        JSON.stringify(bedrijf),
        headers
        );
  }

    checkVATnumber(number: string) {
        let headers = {
            headers: {
                'Content-Type': 'application/json',
                vatnumber: number
            },
        };

        return this.http
            .get( this.callURL + 'bedrijfs.checkVATnumber' , headers)
            .pipe(tap((x) => {}));
    }

  updateEdigid(currentEdigid: Edigid, isOndertekening: boolean) {
      let headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: this._token,
          companyid: this.company_id,
          isondertekening: isOndertekening.toString()
        },
      };

      return this.http.post(
        this.callURL + 'e-digids.update',
        JSON.stringify(currentEdigid),
        headers
      );
  }

  getEdigids(scrollNumber: number, isReload: boolean, edigidsFilter: string, fromDate: Date, toDate: Date, chauffeurid: string, hasViewExterneBedrijven: boolean,
             euralCode: string, verwerkerId: string, ihmId: string, vervoerderId: string, adresVanVerzendingId: string) {
    let companyid = this.company_id;
    if(this.isOvamAdministrator && this.chosenCompanyOVAM != null) companyid = this.chosenCompanyOVAM;

    console.log('is ovam admin' + this.isOvamAdministrator + 'companyid' + companyid);
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: companyid,
        page: scrollNumber != null ? scrollNumber.toString() : '0',
        isreload: isReload != null ? isReload.toString(): 'false',
        edigidsfilter: edigidsFilter != null ? edigidsFilter : '',
        fromdate: fromDate != null ? fromDate.toString() : '',
        todate: toDate != null ? toDate.toString() : '',
        chauffeurid: chauffeurid != null ? chauffeurid : '',
        euralcode: euralCode != null ? euralCode : '',
        verwerkerid: verwerkerId != null ? verwerkerId : '',
        ihmId: ihmId != null ? ihmId : '',
        vervoerderId: vervoerderId != null ? vervoerderId : '',
        adresvanverzendingid: adresVanVerzendingId != null ? adresVanVerzendingId : '',
        hasviewexternebedrijven: hasViewExterneBedrijven != null ? hasViewExterneBedrijven.toString() : 'false',
        isovamadministrator: this.isOvamAdministrator.toString()
      },
    };

    return this.http
      .get( this.callURL + 'e-digids.list' , headers)
      .pipe(tap((x) => {}));
  }

  deleteEdigid(edigid: Edigid) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };
    return this.http.post(
      this.callURL + 'e-digids.delete',
      JSON.stringify(edigid),
      headers
    );
  }

  downloadEdigidPdf(currentEdigid: Edigid) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        _id: currentEdigid._id
      },
    };

    return this.http
      .get( this.callURL + 'e-digids.generateEdigidPdf' , headers)
      .pipe(tap((x) => {}));
  }

  getEdigidAnonymousControle(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        _id: _id
      },
    };

    return this.http
      .get( this.callURL + 'e-digids.getEdigidByIdForAnonymousQrCode' , headers)
      .pipe(tap((x) => {}));
  }

  convertEdigidOVAMFormat(currentEdigid: Edigid) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .post( this.callURL + 'e-digids.convertEdigidOVAMFormat' , JSON.stringify(currentEdigid),headers)
      .pipe(tap((x) => {}));
  }

  getAllOVAMCompanys() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'companys.getOVAMCompanys' , headers)
      .pipe(tap((x) => {}));
  }

  getEdigid(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: _id
      },
    };

    return this.http
      .get( this.callURL + 'e-digids.id' , headers)
      .pipe(tap((x) => {}));
  }

  sendMailToInviteCompany(name: string, email: string, companyId: string, bedrijf: Bedrijf) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        name: name,
        email: email,
        companyid: companyId
      },
    };

    return this.http
      .post( this.callURL + 'bedrijfs.sendMailToInviteCompany' ,JSON.stringify(bedrijf), headers)
      .pipe(tap((x) => {}));
  }

  addNewCompanyAndUserAfvalstoffen(companyUser: {
    company: Company;
    user: User
  }, companyId: string, controleNumber: number) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        companyid: companyId,
        controlenumber: controleNumber.toString()
      },
    };

    return this.http
      .post( this.callURL + 'companys.addNewCompanyAndUserAfvalstoffen' ,JSON.stringify(companyUser), headers)
      .pipe(tap((x) => {}));
  }

  addBedrijfsGegevensToCompany() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'bedrijfs.addBedrijfsGegevensToCompany' , headers)
      .pipe(tap((x) => {}));
  }


  createOrUpdateEigenBedrijf(data) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        isovamgebruiker: this.isOvamGebruiker.toString()
      },
    };
    return this.http
      .post( this.callURL + 'bedrijfs.createOrUpdateEigenBedrijf' , JSON.stringify(data), headers)
      .pipe(tap((x) => {}));
  }

  getBedrijf(id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: id
      },
    };

    return this.http
      .get( this.callURL + 'bedrijfs.id' , headers)
      .pipe(tap((x) => {}));
  }

  getBedrijfAndCheckIfVatExists(companyId: string, btwNummer: string, controleNumber: number) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: companyId,
        btwnummer: btwNummer,
        controlenumber: controleNumber.toString()
      },
    };

    return this.http
      .get( this.callURL + 'bedrijfs.getBedrijfAndCheckIfVatExists' , headers)
      .pipe(tap((x) => {}));
  }

  checkIfVatAlreadyExists(btwNummer: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        vatnumber: btwNummer
      },
    };

    return this.http
      .get( this.callURL + 'bedrijfs.checkIfVatAlreadyExists' , headers)
      .pipe(tap((x) => {}));
  }

  getUserById(userId: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: userId
      },
    };

    return this.http
      .get( this.callURL + 'users.id' , headers)
      .pipe(tap((x) => {}));
  }
  getUserByIdPromise(userId: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: userId
      },
    };

    return this.http
      .get( this.callURL + 'users.id' , headers)
      .toPromise();
  }
  bind2FA() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        id: this.userId,
        username: this.userName
      },
    };

    return this.http
      .get( this.callURL + 'auth.bind2FactorAuth' , headers)
      .pipe(tap((x) => {}));
  }

  verifyTwoFactorAuth(token: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        id: this.userId,
        token: token
      },
    };

    return this.http
      .get( this.callURL + 'auth.verifyTwoFactorAuth' , headers)
      .pipe(tap((x) => {}));
  }

  getConnectedBedrijven() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'bedrijfs.getConnectedBedrijven' , headers)
      .pipe(tap((x) => {}));
  }

  updateSharedObjectsOfCompany(updateObject: {
    addedChauffeurs: string[];
    addedWerven: string[];
    removedWerven: string[];
    companyid: string;
    removedChauffeurs: string[]
  }) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .post( this.callURL + 'bedrijfs.updateSharedObjectsOfCompany' , JSON.stringify(updateObject), headers)
      .pipe(tap((x) => {}));
  }

  async getFirstAndLatestDagboek(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        werfid: _id
      },
    };

    return firstValueFrom(this.http
      .get( this.callURL + 'dagboek-der-werken.getFirstAndLatestDagboek' , headers)
      .pipe(tap((x) => {})));
  }

  reset2FA() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        userid: this.userId
      },
    };

    return this.http
      .get( this.callURL + 'auth.reset2FactorAuth' , headers)
      .pipe(tap((x) => {}));
  }

  sendMailToInviteWerfleider(name: string, email: string, message: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        name: name,
        email: email,
        companyid: this.company_id
      },
    };

    return this.http
      .post( this.callURL + 'users.sendMailToInviteWerfleider' ,JSON.stringify({message:message}), headers)
      .pipe(tap((x) => {}));
  }

  registerWerfleiderByEmail(user: User, companyId: string, controleNumber: number) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        companyid: companyId,
        controlenumber: controleNumber.toString()
      },
    };

    return this.http
      .post( this.callURL + 'auth.registerWerfleiderByEmail' ,JSON.stringify(user), headers)
      .pipe(tap((x) => {}));
  }

  updateReparatieSettings(sendObject: {
    aanvragerReparatiePopupAllowed: boolean;
    aanvragerIsDringendReparatiePopupAllowed: boolean;
    onderhoudsTechniekerPopupAllowed: boolean;
    onderhoudsTechniekerIsDringendPopupAllowed: boolean;
  }) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .post( this.callURL + 'reparaties.updateReparatieSettings' ,JSON.stringify(sendObject), headers)
      .pipe(tap((x) => {}));
  }

  updateMagazijnSettings(sendObject: {
    aanvragerMagazijnierPopupAllowed: boolean;
    aanvragerIsDringendMagazijnierPopupAllowed: boolean;
    magazijnierPopupAllowed: boolean;
    magazijnierIsDringendPopupAllowed: boolean
  }) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .post( this.callURL + 'magazijn-taken.updateMagazijnSettings' ,JSON.stringify(sendObject), headers)
      .pipe(tap((x) => {}));
  }

  getUsersByRoleLight(rol: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        rol: rol
      },
    };

    return this.http
      .get( this.callURL + 'users.getUsersByRoleLight' , headers)
      .pipe(tap((x) => {}));
  }

  getWervenLight() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'werven.getWervenLight' , headers)
      .pipe(tap((x) => {}));
  }


  updateHasOpenedReparatie(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        _id: _id,
        userid: this.userId,
        username: this.userName
      },
    };

    return this.http
      .get( this.callURL + 'reparaties.updateHasOpenedReparatie' , headers)
      .pipe(tap((x) => {}));
  }

  updateHasOpenedMagazijnTaak(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        _id: _id,
        userid: this.userId,
        username: this.userName
      },
    };

    return this.http
      .get( this.callURL + 'magazijn-taken.updateHasOpenedMagazijntaak' , headers)
      .pipe(tap((x) => {}));
  }

  generateNewEdigidS3Url(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: _id
      },
    };

    return this.http
      .get( this.callURL + 'e-digids.generateNewEdigidS3Url' , headers)
      .pipe(tap((x) => {}));
  }

  getSchademeldingen(werfId: string) {
    if(werfId == null) werfId = '';
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        werfid: werfId
      },
    };

    return this.http
      .get( this.callURL + 'schademeldings.list' , headers)
      .pipe(tap((x) => {}));
  }

  deleteSchademelding(schademelding: Schademelding) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };
    return this.http.post(
      this.callURL + 'schademeldings.delete',
      JSON.stringify(schademelding),
      headers
    );
  }

  getSchademelding(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: _id
      },
    };

    return this.http
      .get( this.callURL + 'schademeldings.id' , headers)
      .pipe(tap((x) => {}));
  }

  updateSchademelding(data: any) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'schademeldings.update',
      JSON.stringify(data),
      headers
    );
  }

  getPopulatedSchademelding(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: _id
      },
    };

    return this.http
      .get( this.callURL + 'schademeldings.getPopulatedSchademelding' , headers)
      .pipe(tap((x) => {}));
  }

  updateSchademeldingHasBeenViewed(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        _id: _id
      },
    };

    return this.http
      .get( this.callURL + 'schademeldings.updateSchademeldingHasBeenViewed' , headers)
      .pipe(tap((x) => {}));
  }

  createSchademelding(schademelding: Schademelding) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };
    schademelding.company_id = this.company_id;

    return this.http.post(
      this.callURL + 'schademeldings.create',
      JSON.stringify(schademelding),
      headers
    );
  }

  setIsArchivedWerf(_id: string, isArchived: boolean) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        _id: _id,
        isarchived: isArchived.toString()
      },
    };

    return this.http
      .get( this.callURL + 'werven.setIsArchivedWerf' , headers)
      .pipe(tap((x) => {}));
  }

  addNewCompany(sendObject, metTestObjectenEnAccounts) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        mettestobjecten: metTestObjectenEnAccounts ? 'true' : 'false'
      },
    };

    return this.http.post(
      this.callURL + 'companys.createNewCompany',
      JSON.stringify(sendObject),
      headers
    );
  }

  getHighestOvamId() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http
      .get( this.callURL + 'companys.getHighestOvamId' , headers)
      .pipe(tap((x) => {}));
  }

  editCompany(sendObject) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'companys.editCompany',
      JSON.stringify(sendObject),
      headers
    );
  }

  deleteTestObjectsOfCompany() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'companys.deleteTestObjectsOfCompany' , headers)
      .pipe(tap((x) => {}));
  }

  updateSchademeldingSettings(sendObject: {
    gebruiktJurdischeInfoBijSchademelding: boolean;
    gebruiktHerstellingBijSchademelding: boolean
  }) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .post( this.callURL + 'schademeldings.updateSchademeldingSettings' ,JSON.stringify(sendObject), headers)
      .pipe(tap((x) => {}));

  }

  getGrondverzetToelatingOfGrondwijzer(eGVTnr: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        egvtnr: eGVTnr
      },
    };

    return this.http
      .get( this.callURL + 'transport-dagrapporten.getGrondverzetToelatingOfGrondwijzer' , headers)
      .pipe(tap((x) => {}));
  }

  getMeerwerkPdf(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyname: this.dataSyncService.companySubject.value.name,
        _id: _id,
        logourl: this.dataSyncService.companySubject.value.logoURL ? this.dataSyncService.companySubject.value.logoURL : ''
      },
    };

    return this.http
      .get( this.callURL + 'dagrapporten.makeMeerwerkPdf' , headers)
      .pipe(tap((x) => {}));
  }

  generateGrondbankbon() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http
      .get( this.callURL + 'transport-dagrapporten.generateGrondbankBon' , headers)
      .pipe(tap((x) => {}));
  }

  getCodesGrondbank(documentnumber: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        documentnumber: documentnumber
      },
    };

    return this.http
      .get( this.callURL + 'transport-dagrapporten.getCodesGrondbank' , headers)
      .pipe(tap((x) => {}));
  }

  getAangepasteTransportToelating(documentnumber: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        documentnumber: documentnumber
      },
    };

    return this.http
      .get( this.callURL + 'transport-dagrapporten.getAangepasteTransportToelating' , headers)
      .pipe(tap((x) => {}));
  }

  updateIsWerfActief(_id: string, isActiefOpWerfPlanning: boolean) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        _id: _id,
        isactiefopwerfplanning: isActiefOpWerfPlanning.toString()
      },
    };

    return this.http
      .get( this.callURL + 'werven.setIsWerfActief' , headers)
      .pipe(tap((x) => {}));
  }

  updateUserVoorkeurArbeiders(ploegbaasId: string, voorkeurArbeiders: string[]) {
    console.log('voorkeur arbeiders' + voorkeurArbeiders);
    console.log(ploegbaasId)
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        ploegbaasid: ploegbaasId
      },
    };

    return this.http
      .get( this.callURL + 'werven.dddddddddddddddddddd' , headers)
      .pipe(tap((x) => {}));
  }


  updateVerlofdagen(verlofDagen: [{fromDate: Date, toDate: Date}]) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .post( this.callURL + 'companys.updateVerlofdagen' , JSON.stringify(verlofDagen), headers)
      .pipe(tap((x) => {}));
  }

  updatePloegen(changedPloegen: { ploegbaas: User; voorkeurArbeiders: User[] }[], deletedPloegen: {
    ploegbaas: User;
    voorkeurArbeiders: User[]
  }[], newPloegen: { ploegbaas: User; voorkeurArbeiders: User[] }[]) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };
    return this.http
      .post( this.callURL + 'users.updatePloegen' , JSON.stringify({changedPloegen: changedPloegen, deletedPloegen: deletedPloegen, newPloegen: newPloegen}), headers)
      .pipe(tap((x) => {}));
  }

  generateNewCompanyCodes() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        companyname: this.dataSyncService.companySubject.value.name
      },
    };

    return this.http
      .get( this.callURL + 'companys.generateNewCompanyCodes' , headers)
      .pipe(tap((x) => {}));
  }

  getMaandPlanningOfWerf(month: number, year: number) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        month: month.toString(),
        year: year.toString()
      },
    };

    return this.http
      .get( this.callURL + 'werfplannings.getMaandPlanningOfWerf' , headers)
      .pipe(tap((x) => {}));
  }

  updateDagWerfplanning(dagWerfPlanning: DagWerfPlanning) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http.post(
      this.callURL + 'werfplannings.update',
      JSON.stringify(dagWerfPlanning),
      headers
    );
  }

  creatDagWerfplanning(dagWerfPlanning: DagWerfPlanning, month: number, year: number) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        month : month.toString(),
        year : year.toString(),
        companyid: this.company_id
      },
    };
    return this.http.post(
      this.callURL + 'werfplannings.create',
      JSON.stringify(dagWerfPlanning),
      headers
    );
  }

  async getStaatDerVerletOfMonth(_id: string, month: number, fullYear: number): Promise<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._token,
        'companyid': this.company_id,
        'werfid': _id,
        'month': month.toString(),
        'year': fullYear.toString()
      })
    };

    try {
      const response = await this.http
        .get(this.callURL + 'staat-der-verlets.getLatestStaatDerVerletOfWerf', headers)
        .toPromise();

      return response;
    } catch (error) {
      // Handle any errors that occur during the request
      console.error('Error fetching staat der verlet:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  }

  async getStaatDerVerletOfMonthAndNextMonth(_id: string, month: number, fullYear: number): Promise<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._token,
        'companyid': this.company_id,
        'werfid': _id,
        'month': month.toString(),
        'year': fullYear.toString()
      })
    };

    try {
      const response = await this.http
        .get(this.callURL + 'staat-der-verlets.getStaatDerVerletOfMonthAndNextMonth', headers)
        .toPromise();

      return response;
    } catch (error) {
      // Handle any errors that occur during the request
      console.error('Error fetching staat der verlet:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  }

  deleteStaatDerVerletOfMonth(_id: string) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._token,
        '_id': _id
      })
    };

    return this.http
      .post(this.callURL + 'staat-der-verlets.delete',
        JSON.stringify({}), headers)
      .pipe(tap((x) => {}));
  }

  getWervenWithLatestDagboek() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        istoezichter: this.isDagboekToezichter.toString(),
        userid: this.userId
      },
    };

    return this.http
      .get( this.callURL + 'werven.getWervenWithLatestDagboek' , headers)
      .pipe(tap((x) => {}));
  }

  getWeatherForecastOfAllActiveWerven(werfData: any, todayOrTommorow: string): Promise<any> {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        todayortommorow: todayOrTommorow
      },
    };

    return firstValueFrom(this.http
      .post( this.callURL + 'dagboek-der-werken.getWeatherForecastOfAllActiveWerven' ,
        JSON.stringify(werfData), headers));
  }

  sendClientsToOVAM() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'e-digids.sendCurrentClientsToOVAM' , headers)
      .pipe(tap((x) => {}));
  }

  deleteDagWerfPlanning(_id: string, maandPlanningId: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: _id,
        maandplanningid: maandPlanningId
      },
    };

    return this.http
      .get( this.callURL + 'werfplannings.deleteDagPlanning' , headers)
      .pipe(tap((x) => {}));
  }

  getAlleDagboekenOfWerf(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        werfid: _id,
        companyid: this.company_id
      },
    };

    return this.http
      .get( this.callURL + 'dagboek-der-werken.getAlleDagboekenOfWerf' , headers)
      .pipe(tap((x) => {}));
  }

  getPrevious10Werfplanningen(isoDate: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        beforedate: isoDate
      },
    };

    return this.http
      .get( this.callURL + 'werfplannings.getPrevious10Werfplanningen' , headers)
      .toPromise();
  }

  sendPushNotificationsToEmployees(usersWithFcmToken: User[], arbeidersWithoutFcmToken: User[], werfplanningId: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        werfplanningid: werfplanningId
      },
    };
    let sendObject = {
      usersWithFcmToken: usersWithFcmToken,
      arbeidersWithoutFcmToken: arbeidersWithoutFcmToken
    }
    return this.http
      .post( this.callURL + 'werfplannings.sendPushNotificationsToEmployees' , JSON.stringify(sendObject), headers)
      .pipe(tap((x) => {}));
  }

  async getApprovedVerlofDagenOfDate(isoDate: string) {

    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id,
        isodate: isoDate
      },
    };

    try {
      const response = await this.http
        .get(this.callURL + 'verlofs.getApprovedVerlofDagenOfDate', headers)
        .toPromise();

      return response;
    } catch (error) {
      // Handle any errors that occur during the request
      console.error('Error fetching verlof dagen:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  }

  inviteToezichter(werfId: string, message: string, email: string, name: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        werfid: werfId,
        toezichteremail: email,
        companyid: this.company_id,
        toezichternaam: name
      },
    };

    return this.http
      .post( this.callURL + 'dagboek-der-werken.inviteToezichterOpWerf' , JSON.stringify({message: message}), headers)
      .pipe(tap((x) => {}));
  }

  registerToezichterDagboek(user: User, controleNumber: string, companyIdToConnect: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        controlenumber: controleNumber,
        companyidtoconnect: companyIdToConnect
      },
    };

    return this.http
      .post( this.callURL + 'auth.registerToezichterDagboek' ,JSON.stringify(user), headers)
      .pipe(tap((x) => {}));
  }

  getConnectedToezichters() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return firstValueFrom(this.http
      .get( this.callURL + 'dagboek-der-werken.getConnectedToezichters' , headers)
      .pipe(tap((x) => {})));
  }

  updateToezichtersOfWerf(toezichthouders: User[], werfId: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        werfid: werfId
      },
    };

    return this.http
      .post( this.callURL + 'dagboek-der-werken.updateToezichtersOfWerf' ,JSON.stringify(toezichthouders), headers)
      .pipe(tap((x) => {}));
  }

  updateUitgenodigdeToezichtersOfWerf(toezichthouders: { name: string; email: string }[], werfId: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        werfid: werfId
      },
    };

    return this.http
      .post( this.callURL + 'dagboek-der-werken.updateUitgenodigdeToezichtersOfWerf' ,JSON.stringify(toezichthouders), headers)
      .pipe(tap((x) => {}));
  }

  async getDagboekOnBladnummerAndWerf(bladnummer: number, werfId: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        werfid: werfId,
        bladnummer: bladnummer.toString()
      },
    };

    return firstValueFrom(this.http.get(this.callURL + 'dagboek-der-werken.getDagboekOnBladnummerAndWerf', headers));
  }

  processPosten(posten: any[]) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        companyid: this.company_id
      },
    };

    return this.http
      .post( this.callURL + 'werven.processPosten' ,JSON.stringify(posten), headers)
      .pipe(tap((x) => {}));

  }

  updateDagboekAsToezichter(dagboek: DagboekDerWerken) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http
      .post( this.callURL + 'dagboek-der-werken.updateDagboekAsToezichter' ,JSON.stringify(dagboek), headers)
      .pipe(tap((x) => {}));
  }

  getLatestBreakingVersions() {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token
      },
    };

    return this.http
      .get( this.callURL + 'auth.getLatestBreakingVersions' , headers)
      .pipe(tap((x) => {}));
  }

  getWerfById(_id: string) {
    let headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this._token,
        _id: _id
      },
    };

    return this.http
      .get( this.callURL + 'werven.id' , headers)
      .pipe(tap((x) => {}));
  }

}
