import {Component, OnInit, Optional} from '@angular/core';
import { Router } from '@angular/router';
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'has-changed-popup',
  templateUrl: './has-changed-popup.component.html',
  styleUrls: ['./has-changed-popup.component.scss']
})
export class HasChangedPopupComponent implements OnInit {
  text: any;

  constructor(
    private dialog: MatDialog,
    private formService: FormService,
    private router: Router,
    public dialogRef?: MatDialogRef<HasChangedPopupComponent>,
  ) { }

  ngOnInit(): void {

  }



  closeDialog(){
    this.formService.previousRoute = null;
    this.dialogRef.close()
  }
  doorgaan() {
    if(this.formService.previousRoute != null){
      this.router.navigate([this.formService.previousRoute]);
    }
    this.formService.previousRoute = 'doorgaan';
    this.dialog.closeAll();
  }

}
