import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NbToastrService} from "@nebular/theme";
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'update-popup-dialog',
  templateUrl: './update-popup-dialog.component.html',
  styleUrls: ['./update-popup-dialog.component.scss']
})
export class UpdatePopupDialogComponent implements OnInit {
  text: any;

  breakingVersions: {
    dagrapportenModule: string,
    transportModule:  string,
    reparatieModule: string,
    magazijnierModule: string
  };
  latestVersion: string;
  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private formService: FormService,
    private router: Router,
    private toastrService: NbToastrService
  ) { }

  ngOnInit(): void {
    this.apiService.getLatestBreakingVersions().subscribe(x => {
        this.breakingVersions = x as  {
          dagrapportenModule: string,
          transportModule:  string,
          reparatieModule: string,
          magazijnierModule: string
        };
       let latestVersion = this.breakingVersions.dagrapportenModule;
       if(+this.breakingVersions.transportModule > +latestVersion) latestVersion = this.breakingVersions.transportModule;
        if(+this.breakingVersions.reparatieModule > +latestVersion) latestVersion = this.breakingVersions.reparatieModule;
        if(+this.breakingVersions.magazijnierModule > +latestVersion) latestVersion = this.breakingVersions.magazijnierModule;
        this.latestVersion = latestVersion;
    });
  }

  displayVersion(version: string) {
    if(+version){
      return 'V' + (+version/10).toString();
    } else {
      return 'Geen versie beschikbaar';
    }
  }


  closeDialog(){
    this.dialog.closeAll();
  }
  submit() {
    let text = this.text;
    this.apiService.sendComment(text).subscribe(x => {
      this.toastrService.success('Uw opmerking is verstuurd! U krijgt eventueel een antwoord terug via email', 'Succes!');
      this.dialog.closeAll();
    })
  }
}
