<nb-spinner *ngIf="isSaving" class="opslaan-spinner" size="giant" [message]="'Opslaan..'" status="info"></nb-spinner>
<div *ngIf="formService.isEigenBedrijf == null || formService.isEigenBedrijf"  class="topRow">
  <button mat-raised-button class="nav-button-black" (click)="onClose()">
    <div class="divInsideButton"><fa-icon class="normalIcon"  [icon]="faArrowLeftFromBracket"></fa-icon> Terug</div></button>
  <button [disabled]="isSaving || !hasChangedValue"  (click)="onSubmit(addForm.value)" mat-raised-button class="form-button-blue" color="primary">
    <div class="divInsideButton">
      <fa-icon class="normalIcon" [icon]="faFloppyDisk"></fa-icon>
      <span class="button-text">Opslaan</span></div>
  </button>
  <button *ngIf="isLoaded && (formService.isEigenBedrijf == null || formService.isEigenBedrijf) && !isOvamGebruiker" mat-raised-button
          class="deel-button" color="primary"
          (click)="toggleObjectenDelenVisibility()"><fa-icon *ngIf="isObjectenDelenVisible" class="normalIcon"  [icon]="faArrowLeftFromBracket"></fa-icon>
    {{isObjectenDelenVisible? 'Delen sluiten': 'Chauffeurs en werven delen'}}</button>
</div>
<mat-card *ngIf="isLoaded && (formService.isEigenBedrijf == null || formService.isEigenBedrijf) && isObjectenDelenVisible && !isOvamGebruiker" class="share-card">
  <mat-card-title style="margin-left: 20px;margin-bottom: 20px;">Werven en chauffeurs delen met bedrijven voor aanmaak Digi ID-formulieren</mat-card-title>
  <div class="share-item">
    <label for="shareChauffeur">Kies een bedrijf waar u iets mee wilt delen:</label>
    <nb-select (selectedChange)="changeShareCompany()" id="sharCompany" style="width:450px !important;" [(ngModel)]="selectedBedrijf" name="selectedChauffeur">
      <!-- Populate this dropdown with chauffeur data -->
      <nb-option *ngFor="let connectBedrijf of connectedBedrijven" [value]="connectBedrijf.realBedrijf._id">{{ connectBedrijf.realBedrijf.naam }}</nb-option>
    </nb-select>
    <button mat-raised-button color="primary" (click)="updateSharedObjectsOfCompany()">Delen</button>
  </div>
  <div *ngIf="selectedBedrijf != null" class="share-content">
    <div *ngIf="chauffeurs != null" class="share-item">
      <label for="shareChauffeur">Gedeelde chauffeurs:</label>
      <nb-select  style="width:450px !important;" id="shareChauffeur" [(ngModel)]="selectedChauffeurs" [multiple]="true" name="selectedChauffeur">
        <!-- Populate this dropdown with chauffeur data -->
        <nb-option *ngFor="let chauffeur of chauffeurs" [value]="chauffeur._id">{{ chauffeur.name }}</nb-option>
      </nb-select>
    </div>
    <div *ngIf="werven != null" class="share-item">
      <label for="shareWerf">Gedeelde werven:</label>
      <nb-select style="width:450px !important;" id="shareWerf" [(ngModel)]="selectedWerven" [multiple]="true"  name="selectedWerf">
        <!-- Populate this dropdown with werf data -->
        <nb-option *ngFor="let werf of werven" [value]="werf._id">{{ functionService.displayWerf(werf) }}</nb-option>
      </nb-select>
    </div>
  </div>
</mat-card>
<mat-card *ngIf="isLoaded" class="edit-card-klant">
    <mat-card-title style="margin-left: 20px;font-size: 22px">{{formService.isEigenBedrijf == null || formService.isEigenBedrijf ? 'Eigen bedrijfsinstellingen': 'Klant toevoegen'}}</mat-card-title>
  <form [formGroup]="addForm"  class="outerRowDiv">
      <table class="tableKlanten">
          <tbody>
          <tr>
              <td style="min-width:170px !important;" [ngClass]="(formService.isEigenBedrijf == null || formService.isEigenBedrijf) ? '': 'extraPaddingBot72px'" >BTW-nummer<br><small>(bv. BE045...)</small></td>
              <td [ngClass]="(formService.isEigenBedrijf == null || formService.isEigenBedrijf) ? '': 'extraPaddingBot72px'" style="">
                  <input [readonly]="koppelBedrijf || ((formService.isEigenBedrijf == null || formService.isEigenBedrijf) && company.bedrijfsGegevens != null)" [ngClass]="koppelBedrijf || ((formService.isEigenBedrijf == null || formService.isEigenBedrijf) && company.bedrijfsGegevens != null)? 'lightGrey' : ''" nbInput id="btwNummer" type="text" formControlName="btwNummer">
                  <!-- Voeg hier een validatiebericht toe indien nodig -->
              </td>
              <td  *ngIf="(formService.isEigenBedrijf != null && !formService.isEigenBedrijf) || !(addForm.get('naam')?.value || addForm.get('straat')?.value)" colspan="2"><ng-container *ngIf="isGettingVATnumber; else showButton">
                  <mat-spinner  diameter="20"></mat-spinner>
              </ng-container>
                  <ng-template #showButton>
                      <button class="vatButton" (click)="checkVATnumber()" nbButton><i style="margin-right: 8px;font-size: 20px" class="fa-solid fa-magnifying-glass"></i> Haal gegevens op<br>met BTW-nummer</button>
                  </ng-template>
                <p style="margin-left: 40px;font-size: 12px;margin-bottom: 10px">
                  Wij controleren het ondernemingsnummer KBO en halen de bedrijfsgegevens op.
                  Daarnaast controleren we of dit bedrijf alreeds klant bij ons is.
                </p>
          </tr>
          <tr *ngIf="koppelBedrijf != null">
            <td colspan="3"><p  style="font-size: 14px; padding:5px; border:1px dashed black;border-radius:10px;margin-left: 60px;" >Het bedrijf is gevonden in ons systeem.<br><b>Gelieve op te slaan om de koppeling af te ronden met: {{koppelBedrijf.naam}}</b></p></td>
            <td></td>
          </tr>
          <tr>
              <td>Bedrijfsnaam</td>
              <td colspan="3">
                  <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  class="longInput" nbInput id="name" type="text" formControlName="naam">
                  <p style="color:red" *ngIf="isNameInvalid">Gelieve een naam in te geven</p>
              </td>
          </tr>
          <tr>
              <td >Straat</td>
              <td>
                  <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  nbInput id="street" type="text" formControlName="straat">
                  <!-- Voeg hier een validatiebericht toe indien nodig -->
              </td>
            <td class="secondRowText">Huisnummer</td>
            <td>
              <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  nbInput id="houseNumber" type="text" formControlName="huisNr">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
          </tr>
          <tr>
            <td>Gemeente</td>
            <td>
              <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  nbInput id="gemeente" type="text" formControlName="gemeente">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
            <td class="secondRowText">Postcode</td>
            <td>
              <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  nbInput id="postcode" type="number" formControlName="postcode">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
          </tr>
          <tr>
            <td >Telefoonnummer</td>
            <td>
              <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  nbInput id="phoneNumber" type="text" formControlName="telefoonNummer">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
            <td class="secondRowText">Land</td>
            <td>
              <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  nbInput id="country" type="text" formControlName="land">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
          </tr>
          </tbody>
      </table>
    <table>
      <tbody>
      <tr style="margin-top: 30px;">
        <td style="font-weight: bold;padding-right: 10px"  colspan="2" ><i class="fa-solid fa-square-envelope bigFaIcon"></i> Email onvangst ID-formulieren</td>
        <td>
          <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  nbInput id="email" type="email" formControlName="email">
          <!-- Voeg hier een validatiebericht toe indien nodig -->
        </td>
      </tr>
      <tr *ngIf="formService.isEigenBedrijf == null || formService.isEigenBedrijf" >
        <td style="max-width: 420px" colspan="2" class="secondRowText"><i class="fa-solid fa-signature bigFaIcon"></i><span style="font-weight: bold;font-size: 17px">Indien verwerker:</span><br>Om ID-formulieren via QR-code te ondertekenen, selecteer een gebruikersaccount met een gekoppelde handtekening.</td>
        <td>
          <nb-select style="width:250px" formControlName="werfleider">
            <nb-option *ngFor="let user of users" value="{{ user._id}}">{{ user.name }}
            </nb-option>
          </nb-select>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="vestigingenOuterDiv" formArrayName="vestigingen">
      <div *ngFor="let vestiging of getVestigingen().controls; let i=index" [formGroupName]="i" class="vestigingenInnerDiv">
        <div class="innerTopRowVestiging">
          <mat-card-title style="width:90%"><span style="text-decoration: underline;">Vestiging {{i+1}}</span><small style="padding-left: 8px">*Verplicht indien {{formService.isEigenBedrijf == null || formService.isEigenBedrijf ? 'U' : 'klant'}} mogelijks afvalproducent of verwerker {{formService.isEigenBedrijf == null || formService.isEigenBedrijf ? 'bent' : 'is'}}</small></mat-card-title>
          <button mat-raised-button class="delete-button" color="warn" type="button"
                  (click)="verwijderVestiging(i)"><i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></button>
        </div>
        <table class="tableKlanten">
          <tbody>
          <tr>
            <td style="min-width: 170px !important;">Vestigingsnaam</td>
            <td colspan="3">
              <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  class="longInput" nbInput id="name" type="text" formControlName="naam">
              <p style="color:red" *ngIf="isNameInvalid">Gelieve een naam in te geven</p>
            </td>
          </tr>
          <tr>
            <td style="min-width: 170px !important;">Vestigingsnummer</td>
            <td>
              <input [readonly]="koppelBedrijf" [ngClass]="koppelBedrijf? 'lightGrey' : ''"  nbInput id="email" type="email" formControlName="vestigingsNummer">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
            <td></td>
            <td> <button [disabled]="eigenBedrijf?.werfleider == null" *ngIf="formService.isEigenBedrijf == null || formService.isEigenBedrijf" style="background-color: #035cff !important;color: white;"  nbButton (click)="downloadQRCode(i)">Download QR Code voor<br> verwerker OVAM</button></td>
          </tr>
          <tr>
            <td>Aard van verwerking <small>*Uitsluitend bij <b>verwerker gevaarlijke</b> afvalstoffen</small></td>
            <td>
              <nb-select style="width:100px" formControlName="aardVanVerwerking">
                <nb-option *ngFor="let code of D_en_R_codes" value="{{code}}">{{code}}
                </nb-option>
              </nb-select>
            </td>
          </tr>
          <tr >
            <td >Straat</td>
            <td>
              <input nbInput id="street" type="text" formControlName="straat">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
            <td class="secondRowText">Huisnummer</td>
            <td>
              <input nbInput id="houseNumber" type="text" formControlName="huisNr">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
          </tr>
          <tr>
            <td>Gemeente</td>
            <td>
              <input nbInput id="gemeente" type="text" formControlName="gemeente">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
            <td class="secondRowText">Postcode</td>
            <td>
              <input nbInput id="postcode" type="number" formControlName="postcode">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
          </tr>
          <tr>
            <td >Land</td>
            <td>
              <input nbInput id="country" type="text" formControlName="land">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
     </div>
    </div>
    <div style="display: flex;flex-direction: row;justify-content: flex-start; align-items:center;gap:0 100px; margin:20px 0;">
      <mat-card-actions  style="margin-bottom: 0px">
        <button *ngIf="formService.isEigenBedrijf == null || formService.isEigenBedrijf"  [disabled]="isSaving || !hasChangedValue"  (click)="onSubmit(addForm.value)" mat-raised-button class="form-button-blue" color="primary">
          <div class="divInsideButton">
            <fa-icon class="normalIcon" [icon]="faFloppyDisk"></fa-icon>
            <span class="button-text">Opslaan</span></div>
        </button>
        <button *ngIf="!(formService.isEigenBedrijf == null || formService.isEigenBedrijf)"  [disabled]="isSaving"  (click)="onSubmit(addForm.value)" mat-raised-button class="form-button-blue" color="primary">
          <div class="divInsideButton">
              <fa-icon class="normalIcon" [icon]="faPlusCircle"></fa-icon>
              <span class="button-text">Aanmaken</span></div>
        </button>
      </mat-card-actions>
      <button (click)="addNewVestiging()"    type="button"  mat-raised-button color="primary" class="btn btn-primary addVestigingButton"> <i class="fas fa-plus"></i>Voeg extra vestiging toe</button>
    </div>
  </form>
</mat-card>

<div *ngIf="isPrinting" style="display: flex;flex-direction:column; justify-content: flex-start;align-items: center; gap: 10px 0;margin-top:30px;"  #qrCodeElement>
  <h1><b>Qrcode Selux werklogger afvalstoffen</b></h1>
  <h2 style="padding:8px; border:1px solid black"><b>{{getVestigingen().value[chosenVestigingsIndex].naam}}</b></h2>
  <h5>{{getVestigingen().value[chosenVestigingsIndex].straat}} {{getVestigingen().value[chosenVestigingsIndex].huisNr}} <br> {{getVestigingen().value[chosenVestigingsIndex].gemeente}} {{getVestigingen().value[chosenVestigingsIndex].postcode}} <br> {{getVestigingen().value[chosenVestigingsIndex].land}}</h5>
  <div style="display: flex;flex-direction: row;justify-content: flex-start;align-items: center; margin-left:-165px;">
    <p style="font-size: 20px;width:fit-content; margin-right: 50px;font-weight:bold">Scan hier -></p>
    <qrcode [qrdata]="getVestigingen().value[chosenVestigingsIndex].vestigingsNummer" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</div>

