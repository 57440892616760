import { Component, Input, OnInit } from '@angular/core';
import {Werk} from "../../../models/werk";
import {Dagrapport} from "../../../models/dagrapport";
import {Company} from "../../../models/company";
import {Schademelding} from "../../../models/schademelding";
import {User} from "@sentry/angular";
import {DagWerfPlanning} from "../../../models/werfplanning";
import {SharedFunctionService} from "../../../services/shareFunctions.service";

@Component({
  selector: 'ngx-dagplanning-pdf',
  templateUrl: './dagplanning-pdf.component.html',
  styleUrls: ['./dagplanning-pdf.component.scss'],
})
export class DagplanningPdfComponent implements OnInit {
  @Input() public dagWerfPlanning: DagWerfPlanning;
  @Input() public currentDate: Date;
  isLoaded: boolean = false;
  @Input() company!: Company;
  constructor(public functionService: SharedFunctionService) {
  }

  ngOnInit(): void {

    this.isLoaded = true;

  }
  convertUsersToUserNames(users: User[]): string {
    return users.map(user => user.name).join(', ');
  }

}
