import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import {map, tap} from 'rxjs/operators';
import { ApiService } from './api.service';
import {Company} from "../models/company";
import {AuthDataService} from "../app.module";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  public thisCompany: Company;
  constructor(
    private authService: NbAuthService,
    private router: Router,
    private authDataService: AuthDataService,
  )
  {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (authenticated && state.url === '/pages') {
            // User is authenticated and trying to access the default /pages route
            // Handle role-based redirects here
            try {
              const authData = this.authDataService.getAuthData();
              const { company, role } = authData;

              // Role-based navigation logic
              if (role === 'onderhoudstechnieker') {
                this.router.navigate(['/pages/reparaties']);
              } else if (role === 'magazijnier') {
                this.router.navigate(['/pages/magazijn-taken']);
              } else if (role === 'ovamAdministrator' || company?.hasOnlyAfvalstoffenView) {
                this.router.navigate(['/pages/e-digidlist']);
              } else if (role === 'dagboekToezichter') {
                this.router.navigate(['/pages/staat-der-verletdagen']);
              } else if (role === 'admin') {
                this.router.navigate(['/pages/dagrapporten']);
              } else if (role === 'werfleider' || role === 'administrator') {
                if (company?.heeftDagboekModule) {
                  this.router.navigate(['/pages/staat-der-verletdagen']);
                } else if (company?.heeftDagraportenModule) {
                  this.router.navigate(['/pages/dagrapporten']);
                } else if (company?.heeftTransportModule) {
                  this.router.navigate(['/pages/transport-dagrapporten']);
                } else if (company?.heeftAfvalstoffenModule) {
                  this.router.navigate(['/pages/e-digidlist']);
                } else if (company?.heeftWerfplanningModule) {
                  this.router.navigate(['/pages/werfplanning']);
                }
              }
            } catch (error) {
              console.error('Error in auth guard redirect:', error);
            }
            return false;
          }
          return authenticated;
        }),
        map(authenticated => {
          if (!authenticated) {
            this.router.navigate(['/auth/login']);
            return false;
          }
          return true;
        })
      );
  }
}
