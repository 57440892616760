<mat-dialog-content style="padding-bottom: 0px !important;">
  <h2 mat-dialog-title>Nodig bedrijf uit op platform</h2>
  <div style="display:flex;flex-direction: row">
    <mat-form-field  appearance="fill" style="width:210px !important;">
      <mat-label>Naam</mat-label>
      <input firstLetterCapitalize matInput placeholder="Naam" [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:270px !important;margin-left: 50px">
      <mat-label>E-mailadres</mat-label>
      <input matInput placeholder="E-mailadres" [(ngModel)]="data.email" >
    </mat-form-field>
  </div>
    <mat-form-field appearance="fill" style="width:100% !important; ">
      <mat-label>Bericht</mat-label>
      <textarea style="height:240px !important;" matInput placeholder="Bericht" [(ngModel)]="data.message" name="message"></textarea>
    </mat-form-field>

  <mat-dialog-actions style="padding: 0 40px">
    <button mat-raised-button (click)="closeDialog()" color="warn">Annuleren</button>
    <button mat-raised-button [mat-dialog-close]="data" color="primary"><i class="fa fa-envelope"></i> Verzenden</button>
  </mat-dialog-actions>
</mat-dialog-content>

