// werf-selector.component.ts
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Observable, map, startWith, debounceTime, combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Werf } from "../../models/werf";
import {MatAutocomplete, MatAutocompleteModule, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { takeUntil } from "rxjs/operators";
import {NbButtonModule, NbIconModule, NbInputModule} from "@nebular/theme";
import {DataSyncService} from "../../services/dataSync.service";

@Pipe({
  standalone: true,
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, searchTerm: string): SafeHtml {
    if (!text || !searchTerm) return text;

    try {
      const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedSearchTerm, 'gi');
      const newText = text.replace(regex, match => `<mark>${match}</mark>`);
      return this.sanitizer.bypassSecurityTrustHtml(newText);
    } catch (e) {
      return text;
    }
  }
}

@Component({
  selector: 'app-werf-selector',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    HighlightPipe,
    NbButtonModule,
    NbIconModule,
    NbInputModule
  ],
  template: `
    <div [ngStyle]="{'width': width + 30 + 'px'}" class="colFlex">
      <mat-label *ngIf="withLabelOnTop" class="sortTextNormal">
        <i class="fa-solid fa-map-location-dot smallIcon"></i> Werf
      </mat-label>
      <div class="werf-selector-container"  [ngStyle]="{'width': width + 'px'}">
        <div class="flexColumn">
          <div class="divSelectedWerf" (click)="openAutoComplete()" [ngStyle]="{'width': width + 'px'}" *ngIf="selectedWerf" matTextPrefix>
            <ng-container *ngTemplateOutlet="werfTemplate; context: { $implicit: selectedWerf, searchTerm: '' }">
            </ng-container>
          </div>
          <input nbInput  [ngStyle]="{'width': width + 'px'}"
                 [class.invisible-input]="selectedWerf"
                 type="text" #werfInput
                 [formControl]="searchControl" class="werf-input"
                 [matAutocomplete]="auto"
                 [placeholder]="selectedWerf ? '' : 'Kies een werf..'"
                 [style.opacity]="selectedWerf ? '0' : '1'"
                 autocomplete="off">
        </div>

          <mat-autocomplete #auto="matAutocomplete"  class="custom-autocomplete-panel"
                            [displayWith]="displayFn.bind(this)"
                            [panelWidth]="width + 300"
                            (optionSelected)="onWerfSelected($event.option.value)">
            <mat-option class="matOption" *ngFor="let werf of filteredWerven$ | async" [value]="werf">
              <ng-container *ngTemplateOutlet="werfTemplate; context: { $implicit: werf, searchTerm: searchTerm$ | async }">
              </ng-container>
            </mat-option>
          </mat-autocomplete>

        <ng-template #werfTemplate let-werf let-searchTerm="searchTerm">
          <div class="werf-item">
            <div class="werf-header">
              <p class="werf-name" *ngIf="werf.naam">
                <span [innerHTML]="werf.naam | highlight:searchTerm"></span>
              </p>
              <p *ngIf="werf.gemeente" class="werf-address">
                <span [innerHTML]="(werf.gemeente + ' ' + werf.straat + ' ' + (werf.huisNr? werf.huisNr: '')) | highlight:searchTerm"></span>
              </p>
            </div>
          </div>
        </ng-template>

        <button #clearBtn
                nbButton status="warning"
                (mousedown)="$event.preventDefault()"
                class="closeButton"
                [ngClass]="_selectedWerfId ? '' : 'hideButton'"
                (click)="clearSelection()">
          <nb-icon style="font-size: 22px" pack="fa" icon="xmark"></nb-icon>
        </button>
      </div>
    </div>


  `,
  styles: [`
    ::ng-deep .custom-autocomplete-panel {
      max-height: 500px !important;  /* Adjust this value to your needs */
    }
    .matOption{
      border-bottom: 1px solid #ddd;
    }
    .smallIcon {
      color:#6e6e6e;
    }
    .flexColumn{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    .divSelectedWerf {
      border:1px solid lightblue;
      background-color: white !important;
      border-radius: 5px;
      padding:3px 6px 3px 9px;
    }
    .werf-input {
      padding: 4px 9px !important;
      height:40px;
    }
    .invisible-input {
      opacity: 0;
      height:0px !important;
      width: 0px !important;
      padding:0px !important;
      pointer-events: none;
    }
    .werf-selector-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
    }
    .hideButton {
      visibility: hidden;
    }
    .closeButton {
      width: 28px;
      height: 28px;
      margin: 0 0 0 6px;
    }
    .sortTextNormal {
      min-width: 100px !important;
      margin-bottom: 0px !important;
      margin-left: 7px;
      font-size: 17px;
    }
    .werf-item {
      padding: 2px 0;
    }
    .werf-header {
      display: flex;
      flex-direction: column;
      gap: 0px;
    }
    .werf-address {
      color: #666;
    }
    .colFlex {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .werf-name {
      font-size: 15px;
      font-weight: bold;
    }
    .werf-address{
      font-size: 12px;
    }
    .werf-name, .werf-address {
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 0;
    }
  `]
})
export class WerfSelectorComponent implements  OnDestroy {
  @ViewChild('werfInput') werfInput: ElementRef;

  @Input() withLabelOnTop!: boolean;
  @Input() werven!: Observable<Werf[]>;
  @Input() set selectedWerfId(value: string | null) {
    this._selectedWerfId = value;
    this.updateSelectedWerf();
  }
  protected _selectedWerfId: string | null = null;

  @Output() werfSelected = new EventEmitter<string | null>();

  protected searchTerm$ = new BehaviorSubject<string>('');
  searchControl = new FormControl<Werf | string | null>(null);
  filteredWerven$: Observable<Werf[]>;
  private destroy$ = new Subject<void>();
  @Input() width!: number;
  @Input() isLoaded: boolean;
  @ViewChild('clearBtn') clearBtn: ElementRef;
  selectedWerf: Werf;
  @Input() filterOutNoStartDateWerven?: boolean;

  constructor(private dataSyncService: DataSyncService) {
    const search$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ?
        value.toLowerCase() :
        (value ? this.displayFn(value).toLowerCase() : ''))
    );

    search$.subscribe(term => this.searchTerm$.next(term));

    const debouncedSearch$ = search$.pipe(
      debounceTime(100)
    );

    this.filteredWerven$ = combineLatest([
      this.dataSyncService.wervenSubject,
      debouncedSearch$
    ]).pipe(
      map(([werven, searchTerm]) => {
        if(this.filterOutNoStartDateWerven){
          werven = werven.filter(werf => werf.startDatum != null );
        }
        if (!searchTerm) return werven;
        return werven.filter(werf => {
          const searchString = `${werf.gemeente} ${werf.straat} ${werf.huisNr} ${werf.naam || ''}`.toLowerCase();
          return searchString.includes(searchTerm);
        });
      })
    );
  }

  updateSelectedWerf() {
    console.log('updateSelectedWerf');
    if (this._selectedWerfId) {
      const currentWerven = this.dataSyncService.wervenSubject.value;
      if (currentWerven.length > 0) {
        const findWerf = currentWerven.find(werf => werf._id === this._selectedWerfId);
        if (findWerf) {
          this.searchControl.setValue(findWerf, { emitEvent: false });
          this.selectedWerf = findWerf;
        }
      }
    } else {
      this.selectedWerf = null;
      this.searchControl.setValue(null);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.searchTerm$.complete();
  }

  displayFn(werf: Werf | null): string {
    if (!werf) return '';
    return `${werf.naam ? werf.naam + ' \n': ''}${werf.gemeente || ''} ${werf.straat || ''} ${werf.huisNr || ''}`;
  }

  onWerfSelected(werf: Werf): void {
    if (this.isLoaded && werf?._id) {
      this.werfSelected.emit(werf._id);
      this.selectedWerf = werf;
      this._selectedWerfId = werf?._id;
    }
  }

  clearSelection(): void {
    this.searchControl.setValue('');
    this.searchTerm$.next('');
    this.selectedWerf = null;
    this.werfSelected.emit(null);
    setTimeout(() => {
      this.werfInput.nativeElement.focus();
    }, 100);
  }

  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  openAutoComplete() {
    if (!this.filterOutNoStartDateWerven && this.selectedWerf) {
      this.searchControl.setValue('');
      setTimeout(() => {
        this.werfInput.nativeElement.focus();
        this.werfInput.nativeElement.click();
      });
    }
  }
}
